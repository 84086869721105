/** @jsxImportSource @emotion/react */

import React, { useState, useRef, Fragment } from 'react';
import { css } from '@emotion/react';

import { input as inputCss } from 'components/common/styles';
import InputLabel from 'components/common/form-elements/input-label';
import {
  getPWTemplateHeader,
  FeedId,
  MessageType,
  formatMessageDoc,
  LONG_GTFS_TEXT_REPLACEMENTS,
} from '@mta-live-media-manager/shared';
import {
  IScreenSelector,
  LANDSCAPE_TARGET_TYPES,
  PORTRAIT_TARGET_TYPES,
} from 'components/common/form-elements/multi-route-stop-screen-selector';
import {
  SCREEN_SIZE_LANDSCAPE,
  SCREEN_SIZE_PORTRAIT,
  SCREEN_SIZE_DUP,
} from 'constants/planned-work';
import TargetingAccordionLabel from 'components/common/screen-targeting-accordion-label';
import Accordion from 'components/common/accordion';
import PlannedWorkTemplatePreview from 'components/common/planned-work-template-screen-preview';
import Checkbox, { CheckboxSize } from 'components/common/Checkbox';
import NumberInput from 'components/common/NumberInput';
import DisplayFrequencySelector, {
  DispFreqMessageType,
  getDispFreqOption,
} from 'components/common/form-elements/display-frequency-selector';
import theme, { ThemeType } from 'theme';
import RadioButton from 'components/common/RadioButton';
import {
  TargetType,
  TransitDestination,
  MessageTimeOfDay,
} from 'generated/global-types';
import DisplayDaysOfWeekSelector, {
  getDispWeekOption,
} from 'components/common/form-elements/display-days-selector';
import { EditableTextPreview } from 'ui-kit/editable-text-preview/editable-text-preview';
import Editor, {
  getMentionsByType,
} from 'components/common/form-elements/editor';
import StatusBanner from 'components/common/status-banner';
import { ScreenUploader } from 'components/common/campaign-form/screen-uploader';
import { PLANNED_MULTIPLE_SCREEN_ORIENTATIONS } from 'constants/error-messages';
import { useFeedId } from 'contexts/FeedId';
import {
  EditorState,
  schemaToJSON,
  prosemirrorNodeToHtml as toHTML,
} from 'remirror';
import { CommonUploadFragment } from 'generated/CommonUpload.fragment';
import { useRoutes } from 'contexts/Routes';
import TargetingSelectionButton from 'components/common/screen-targeting-selection-button';
import {
  getScreenTargetingFieldKey,
  sanitizeHTML,
} from 'utils/compose-helpers';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import { ScreenPreviewDisplayTypes } from 'utils/screen-preview';
import { getGroupedTargetingTypes } from 'utils/screen-targeting';
import { isOutfront } from 'utils/feed-switches';
import ScreensSelector from './screens-selector';
import { getLabelFromTargetTypes } from '../Screen/in-screens-preview/helpers';
import ScreenPublishOffset from './screen-publish-offset';
import { IScreenTargetingError, ScreenTargeting, Status } from './types';
import * as styles from './styles';
import { ScreenTargetingErrorKeys } from './constants';

type Duration = { end: Date; start: Date };
const FEEDS_WITH_DUAL_ORIENTATIONS = new Set([FeedId.LIRR, FeedId.MNR]);

const getTargetTypeOrientations = (
  targetTypes: TargetType[],
): {
  targetsLandscapeScreens: boolean;
  targetsPortraitScreens: boolean;
} => ({
  targetsLandscapeScreens: !!targetTypes.find((tt) =>
    LANDSCAPE_TARGET_TYPES.includes(tt),
  ),
  targetsPortraitScreens: !!targetTypes.find((tt) =>
    PORTRAIT_TARGET_TYPES.includes(tt),
  ),
});

export const checkIfDup = (screens: IScreenSelector[]) => {
  return screens[0]?.targetTypes.includes(TargetType.DUP);
};

const getScreenTargetInfo = (
  feedId: FeedId,
  screens: IScreenSelector[],
): {
  shouldDisplayUpload: boolean;
  isLandscape?: boolean;
  screenSize?: {
    height: number;
    width: number;
  };
  isInvalidSelection?: boolean;
} => {
  if (FEEDS_WITH_DUAL_ORIENTATIONS.has(feedId)) {
    const selectedSreenTargets = screens?.flatMap((s) => s.targetTypes) ?? [];
    if (selectedSreenTargets.length) {
      const { targetsLandscapeScreens, targetsPortraitScreens } =
        getTargetTypeOrientations(selectedSreenTargets);

      if (targetsLandscapeScreens && targetsPortraitScreens) {
        return {
          shouldDisplayUpload: false,
          isInvalidSelection: true,
        };
      }

      return {
        shouldDisplayUpload: true,
        isLandscape: targetsLandscapeScreens,
        screenSize: targetsLandscapeScreens
          ? SCREEN_SIZE_LANDSCAPE
          : SCREEN_SIZE_PORTRAIT,
      };
    }

    return {
      shouldDisplayUpload: false,
    };
  }

  if (checkIfDup(screens)) {
    return {
      shouldDisplayUpload: true,
      isLandscape: false,
      screenSize: SCREEN_SIZE_DUP,
    };
  }

  return {
    shouldDisplayUpload: true,
    isLandscape: false,
    screenSize: SCREEN_SIZE_PORTRAIT,
  };
};

const getScreenContentHelperMessages = (
  shouldDisplayScreenUpload: boolean,
  screenSize: any,
  isInvalidScreenSelection: boolean,
): React.ReactNode[] => {
  const helperMessages: React.ReactNode[] = [];
  const keyPrefix = 'pw-screen-helper-message';

  helperMessages.push(
    <React.Fragment key={`${keyPrefix}-supported-formats`}>
      Supported File Formats: PNG, JPEG (10mb max) or MP4, MOV (50mb max)
    </React.Fragment>,
    <br key={`${keyPrefix}-br`} />,
  );

  if (shouldDisplayScreenUpload && screenSize) {
    helperMessages.push(
      <React.Fragment key={`${keyPrefix}-dimensions`}>
        Dimensions: {screenSize.width} x {screenSize.height}px
      </React.Fragment>,
    );
  } else if (!isInvalidScreenSelection) {
    helperMessages.push(
      <React.Fragment key={`${keyPrefix}-missing-screen-type`}>
        Dimensions: Select Screen Type to View
      </React.Fragment>,
    );
  }

  return helperMessages;
};

export const wrapProseMirrorContent = (content: EditorState) => ({
  doc: content.doc.toJSON(),
  schema: schemaToJSON(content.schema),
  html: sanitizeHTML(toHTML(content.doc)),
  text: formatMessageDoc(content.doc, {
    bracketRoutes: false,
    enforceSpacesBetweenEntities: true,
    replacements: LONG_GTFS_TEXT_REPLACEMENTS,
  }),
  mentions: JSON.stringify(getMentionsByType(content)),
});

const PlannedWorkScreenCompose: React.FC<{
  updateTargeting: (
    index: number,
    key: keyof ScreenTargeting,
    value: any,
  ) => void;
  updateTargetingScreenLayout: (index: number, isDup?: boolean) => void;
  removeTargeting: (index: number) => void;
  targeting: ScreenTargeting;
  index: number;
  durations: Duration[] | null;
  weightingEnabled: boolean;
  sidewalkScreenEnabled: boolean;
  canDelete: boolean;
  hrdOverrideMessage: string | null;
  webHeadline: EditorState | undefined;
  webBody: EditorState | undefined;
  impacts: Status[];
  error: IScreenTargetingError;
}> = ({
  updateTargeting,
  updateTargetingScreenLayout,
  removeTargeting,
  targeting,
  webHeadline,
  webBody,
  index,
  durations,
  weightingEnabled,
  hrdOverrideMessage,
  sidewalkScreenEnabled,
  canDelete,
  impacts,
  error,
}) => {
  const feedId = useFeedId();
  const routes = useRoutes();
  const { id: paramId } = useParams<{ id?: string }>();
  const queryParams = useQueryParams();
  const duplicate = queryParams.get('duplicate');

  const [localMessageHeadline, setLocalMessageHeadline] = useState<
    EditorState | undefined
  >();
  const [
    localMessageAdditionalInformation,
    setLocalMessageAdditionalInformation,
  ] = useState<EditorState | undefined>();

  const uploadRef = useRef<CommonUploadFragment>();

  const {
    isLandscape: isScreenLandscape,
    shouldDisplayUpload: shouldDisplayScreenUpload,
    screenSize,
    isInvalidSelection: isInvalidScreenSelection,
  } = getScreenTargetInfo(feedId, targeting.screens);

  const editorRelatedGtfsIds = impacts.flatMap((s) => s.routeIds ?? []);

  const matrixHeader = durations
    ? getPWTemplateHeader({
        activeWorkPeriods: durations,
        daysOfWeek: {
          group: targeting.screenWeekParams.daysOfWeek,
          days: targeting.screenWeekParams.day,
        },
        timeOfDay: targeting.screenWeekParams.timeOfDay,
        override: targeting.durationForTheWeekMessage,
        daysBeforeWorkPeriod: targeting.screenPublishOffset,
      })
    : '';

  const commonProps = {
    accordion: {
      label: (
        <TargetingAccordionLabel
          routeIds={targeting?.screens
            .flatMap((s) => s.selector.routes)
            .map((s) => s.id)}
          stopIds={targeting?.screens
            .flatMap((s) => s.selector.stops)
            .map((s) => s.id)}
          screenNumber={index + 1}
        />
      ),
      subtitle: getLabelFromTargetTypes(
        targeting.screens
          .flatMap((s) => s.targetTypes)
          .filter((value, index, self) => self.indexOf(value) === index),
        feedId,
      ),
      onDelete: canDelete
        ? () => {
            removeTargeting(index);
          }
        : undefined,
    },
  };

  const affectedRoutes = (() => {
    const currentRoutes = new Set(impacts.flatMap((i) => i.routeIds ?? []));

    const allAffectedRoutes = routes.filter((route) =>
      currentRoutes.has(route.gtfsId),
    );

    return allAffectedRoutes.map((r) => {
      return {
        gtfsRouteId: r.gtfsId,
        longName: r.longName,
        shortName: r.shortName,
        color: r.color,
        textColor: r.textColor,
      };
    });
  })();

  const allTargetingTypes = targeting.screens.flatMap((s) => s.targetTypes);

  const getTemplateDisplayType = (
    targetTypes: TargetType[],
    feedId: FeedId,
  ): ScreenPreviewDisplayTypes | null => {
    if (feedId === FeedId.NYCTSubway || isOutfront(feedId)) {
      return ScreenPreviewDisplayTypes.PORTRAIT;
    }

    if (feedId === FeedId.LIRR && !targeting.screens.length) {
      return ScreenPreviewDisplayTypes.LANDSCAPE;
    }

    const { targetsLandscapeScreens, targetsPortraitScreens } =
      getTargetTypeOrientations(targetTypes);

    if (targetsLandscapeScreens && targetsPortraitScreens) {
      return ScreenPreviewDisplayTypes.DUAL;
    }

    if (targetsLandscapeScreens) {
      return ScreenPreviewDisplayTypes.LANDSCAPE;
    }

    if (targetsPortraitScreens) {
      return ScreenPreviewDisplayTypes.PORTRAIT;
    }

    return null;
  };

  const targetingSelectionTypeKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.targetingTypeSelection,
    index,
  );
  const screenWeekParamsKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.screenWeekParams,
    index,
  );
  const screenHeadlineKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.messageHeadline,
    index,
  );
  const screenTargetingKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.screens,
    index,
  );
  const screenTargetingUploadKey = getScreenTargetingFieldKey(
    ScreenTargetingErrorKeys.uploadId,
    index,
  );

  return (
    <div>
      <Accordion {...commonProps.accordion}>
        {!targeting.showForm && sidewalkScreenEnabled ? (
          <Fragment>
            <div id={targetingSelectionTypeKey} css={styles.TargetingType}>
              <TargetingSelectionButton
                label="Station Screen"
                onClick={() => {
                  updateTargetingScreenLayout(index);
                }}
              />
              <TargetingSelectionButton
                label="Sidewalk Screen"
                onClick={() => {
                  updateTargetingScreenLayout(index, true);
                }}
              />
            </div>
            <StatusBanner
              as="label"
              status="error"
              text={error?.targetingTypeSelection || '\u00a0'}
              isVisible={!!error?.targetingTypeSelection?.length}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${spacing.small};
              `}
            />
          </Fragment>
        ) : (
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                flex: 1 1 auto;
                margin-right: 1rem;
              `}
            >
              {weightingEnabled ? (
                <div css={styles.inputWrapper}>
                  <span
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Duration & Frequency
                  </span>
                  <div>
                    <div
                      css={css`
                        margin-bottom: 16px;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <Checkbox
                          css={css`
                            margin-right: 8px;
                          `}
                          size={CheckboxSize.small}
                          checked={targeting.displayBeforeActivePeriod}
                          onChange={() => {
                            updateTargeting(
                              index,
                              'displayBeforeActivePeriod',
                              !targeting.displayBeforeActivePeriod,
                            );
                          }}
                        />
                        <span>Display</span>
                        <NumberInput
                          css={css`
                            margin: 0 8px;
                          `}
                          disabled={!targeting.displayBeforeActivePeriod}
                          value={targeting.screenPublishOffset}
                          minValue={0}
                          maxValue={7}
                          onChange={(value) => {
                            updateTargeting(
                              index,
                              'screenPublishOffset',
                              value,
                            );
                          }}
                        />
                        <span>
                          {pluralize('day', targeting.screenPublishOffset)}{' '}
                          before each work period
                        </span>
                      </div>
                      {!checkIfDup(targeting.screens) && (
                        <div
                          css={css`
                            padding-left: 24px;
                            > span {
                              font-weight: bold;
                              font-size: 14px;
                            }
                          `}
                        >
                          <span>Frequency</span>
                          <DisplayFrequencySelector
                            id="before-period-frequency"
                            disabled={!targeting.displayBeforeActivePeriod}
                            messageType={DispFreqMessageType.plannedwork}
                            styles={{
                              color: '#000',
                              singleValue: (
                                provided: {},
                                state: {
                                  selectProps: {
                                    menuIsOpen: boolean;
                                  };
                                },
                              ) => {
                                return {
                                  ...provided,
                                  color: state.selectProps.menuIsOpen
                                    ? '#000'
                                    : theme.colors.black,
                                  fontSize:
                                    theme.typography.sizes.medium.fontSize,
                                  lineHeight:
                                    theme.typography.sizes.medium.lineHeight,
                                  fontFamily: theme.typography.families.primary,
                                  fontWeight: theme.typography.weights.normal,
                                };
                              },
                            }}
                            onChange={(option) => {
                              updateTargeting(
                                index,
                                'beforePeriodWeightFrequency',
                                option,
                              );
                            }}
                            value={getDispFreqOption({
                              messageType: DispFreqMessageType.plannedwork,
                              weight:
                                targeting.beforePeriodWeightFrequency.weight,
                              priority:
                                targeting.beforePeriodWeightFrequency.priority,
                            })}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      css={css`
                        margin-bottom: 24px;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <Checkbox
                          css={css`
                            margin-right: 8px;
                          `}
                          size={CheckboxSize.small}
                          checked={targeting.displayDuringActivePeriod}
                          onChange={() => {
                            updateTargeting(
                              index,
                              'displayDuringActivePeriod',
                              !targeting.displayDuringActivePeriod,
                            );
                          }}
                        />
                        <span>Display during active work period</span>
                      </div>
                      {!checkIfDup(targeting.screens) && (
                        <div
                          css={css`
                            padding-left: 24px;
                            > span {
                              font-weight: bold;
                              font-size: 14px;
                            }
                          `}
                        >
                          <span>Frequency</span>
                          <DisplayFrequencySelector
                            id="active-period-frequency"
                            messageType={DispFreqMessageType.plannedwork}
                            disabled={!targeting.displayDuringActivePeriod}
                            styles={{
                              color: '#000',
                              singleValue: (
                                provided: {},
                                state: {
                                  selectProps: {
                                    menuIsOpen: boolean;
                                  };
                                },
                              ) => {
                                return {
                                  ...provided,
                                  color: state.selectProps.menuIsOpen
                                    ? '#000'
                                    : theme.colors.black,
                                  fontSize:
                                    theme.typography.sizes.medium.fontSize,
                                  lineHeight:
                                    theme.typography.sizes.medium.lineHeight,
                                  fontFamily: theme.typography.families.primary,
                                  fontWeight: theme.typography.weights.normal,
                                };
                              },
                            }}
                            onChange={(option) => {
                              updateTargeting(index, 'weightFrequency', option);
                            }}
                            value={getDispFreqOption({
                              messageType: DispFreqMessageType.plannedwork,
                              weight: targeting.weightFrequency.weight,
                              priority: targeting.weightFrequency.priority,
                            })}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <ScreenPublishOffset
                  onChange={(dayOffset) => {
                    updateTargeting(index, 'screenPublishOffset', dayOffset);
                  }}
                  value={targeting.screenPublishOffset || 0}
                />
              )}
              <div
                css={css`
                  margin-bottom: 15px;
                  justify-content: space-between;
                  max-width: 276px;
                  display: flex;
                `}
              >
                <div
                  css={css`
                    display: inline-flex;
                    align-items: center;
                  `}
                >
                  <RadioButton
                    id={`updateMethod-template-${index}`}
                    name={`updateMethod-${index}`}
                    onChange={(isChecked) =>
                      isChecked && updateTargeting(index, 'isAsset', false)
                    }
                    size={20}
                    checked={!targeting.isAsset}
                  />
                  <label
                    css={(theme: ThemeType) => css`
                      margin-left: 8px;
                      font-weight: ${theme.typography.weights.bold};
                    `}
                    htmlFor={`updateMethod-template-${index}`}
                  >
                    Live Screen
                  </label>
                </div>
                <div
                  css={css`
                    display: inline-flex;
                    align-items: center;
                  `}
                >
                  <RadioButton
                    id={`updateMethod-asset-${index}`}
                    name={`updateMethod-${index}}`}
                    onChange={(isChecked) =>
                      isChecked && updateTargeting(index, 'isAsset', true)
                    }
                    size={20}
                    checked={targeting.isAsset}
                  />
                  <label
                    css={(theme: ThemeType) => css`
                      margin-left: 8px;
                      font-weight: ${theme.typography.weights.bold};
                    `}
                    htmlFor={`updateMethod-asset-${index}`}
                  >
                    Upload asset
                  </label>
                </div>
              </div>
              {!targeting.isAsset && (
                <div>
                  {!checkIfDup(targeting.screens) && (
                    <div>
                      <div id={screenWeekParamsKey}>
                        <DisplayDaysOfWeekSelector
                          onChange={(
                            type: any,
                            option: any,
                            resetDays = false,
                          ) => {
                            let currentScreenWeekParams;
                            if (Array.isArray(option)) {
                              currentScreenWeekParams = {
                                ...targeting.screenWeekParams,
                                [type]: option.map((item) => item.value),
                              };
                            } else {
                              currentScreenWeekParams = {
                                ...targeting.screenWeekParams,
                                [type]: option.value,
                              };
                            }
                            updateTargeting(index, 'screenWeekParams', {
                              ...currentScreenWeekParams,
                              ...(resetDays ? { day: [] } : {}),
                            });
                          }}
                          weekParams={getDispWeekOption({
                            daysOfWeek: targeting.screenWeekParams.daysOfWeek,
                            timeOfDay: targeting.screenWeekParams.timeOfDay,
                            day: targeting.screenWeekParams.day,
                          })}
                        />
                        <StatusBanner
                          as="label"
                          status="error"
                          text={error?.screenWeekParams || '\u00a0'}
                          isVisible={!!error?.screenWeekParams?.length}
                          css={({ spacing }: ThemeType) => css`
                            margin-top: ${spacing.small};
                          `}
                        />
                        <EditableTextPreview
                          id="hrd-override-input"
                          isLocked={targeting.isDurationMessageLocked}
                          value={
                            targeting.durationForTheWeekMessage || matrixHeader
                          }
                          onUnlock={() => {
                            updateTargeting(
                              index,
                              'isDurationMessageLocked',
                              false,
                            );
                          }}
                          onChange={(duration) => {
                            updateTargeting(
                              index,
                              'durationForTheWeekMessage',
                              duration.target.value,
                            );
                          }}
                          actionPosition="bottom"
                          helperMessage=""
                          editText="Edit headline"
                          isLoading={false}
                          onRevert={() => {
                            updateTargeting(
                              index,
                              'isDurationMessageLocked',
                              true,
                            );
                            updateTargeting(
                              index,
                              'durationForTheWeekMessage',
                              '',
                            );
                          }}
                          isCollapsible
                          additionalStyles={`
                            box-shadow: none;
                            display: flex;

                            textarea {
                              padding: 10px 20px;
                              border-radius: 4px;
                              box-shadow: ${
                                !targeting.isDurationMessageLocked
                                  ? `inset 0 0 4px ${theme.colors['border-dark']}`
                                  : ''
                              };
                              border: ${
                                !targeting.isDurationMessageLocked
                                  ? `1px solid ${theme.colors['border-dark']}`
                                  : ''
                              };
                              &:focus {
                                border: 1px solid ${theme.colors.accent3};
                              }
                            }
                          `}
                        />
                        <div
                          css={css`
                            margin-top: 24px;
                            margin-bottom: 16px;
                          `}
                        >
                          <label
                            css={(theme: ThemeType) => css`
                              font-weight: ${theme.typography.weights.bold};
                            `}
                            htmlFor="updateMethod-replace"
                          >
                            Human Readable Duration
                          </label>
                          <EditableTextPreview
                            id="hrd-override-input"
                            isCollapsible
                            isLocked={targeting.isHumanReadableLocked}
                            value={
                              targeting.hrdOverrideMessage ||
                              (hrdOverrideMessage ?? '')
                            }
                            onUnlock={() => {
                              updateTargeting(
                                index,
                                'isHumanReadableLocked',
                                false,
                              );
                            }}
                            onChange={(hrdText) => {
                              updateTargeting(
                                index,
                                'hrdOverrideMessage',
                                hrdText.target.value,
                              );
                            }}
                            actionPosition="bottom"
                            helperMessage=""
                            editText="Edit Duration"
                            isLoading={false}
                            onRevert={() => {
                              updateTargeting(
                                index,
                                'isHumanReadableLocked',
                                true,
                              );
                              updateTargeting(index, 'hrdOverrideMessage', '');
                            }}
                            additionalStyles={`
                              textarea {
                                padding: 10px 20px;
                                border-radius: 4px;
                                box-shadow: ${
                                  !targeting.isHumanReadableLocked
                                    ? `inset 0 0 4px ${theme.colors['border-dark']}`
                                    : ''
                                };
                                border: ${
                                  !targeting.isHumanReadableLocked
                                    ? `1px solid ${theme.colors['border-dark']}`
                                    : ''
                                };
                                &:focus {
                                  border: 1px solid ${theme.colors.accent3};
                                }
                              }
                              box-shadow: none;
                              display: flex;
                            `}
                          />
                        </div>
                      </div>

                      <div
                        css={css`
                          margin-bottom: 24px;
                        `}
                      >
                        <InputLabel htmlFor="title-dd" label="Title">
                          <input
                            id="title-dd"
                            css={inputCss}
                            disabled={false}
                            value={targeting.messageTitle}
                            onChange={(e) => {
                              updateTargeting(
                                index,
                                'messageTitle',
                                e.target.value,
                              );
                            }}
                          />
                        </InputLabel>
                        <StatusBanner
                          as="label"
                          status="error"
                          text={error?.messageTitle || '\u00a0'}
                          isVisible={!!error?.messageTitle?.length}
                          css={({ spacing }: ThemeType) => css`
                            margin-top: ${spacing.small};
                          `}
                        />
                      </div>
                    </div>
                  )}
                  <div id={screenHeadlineKey} css={styles.inputWrapper}>
                    <Editor
                      id={`pw-screen-headline-${index}`}
                      labelText={
                        !checkIfDup(targeting.screens)
                          ? 'Headline'
                          : 'Description'
                      }
                      shouldFormatOnPaste
                      initialContent={targeting.wrappedMessageHeadline?.html}
                      value={localMessageHeadline || undefined}
                      mirrorState={webHeadline ? [webHeadline] : undefined}
                      shouldMirror={!targeting.updated && !!webHeadline?.doc}
                      relatedGtfsIds={editorRelatedGtfsIds}
                      onChange={({ state }) => {
                        updateTargeting(index, 'messageHeadline', state);
                        updateTargeting(
                          index,
                          'wrappedMessageHeadline',
                          wrapProseMirrorContent(state),
                        );
                      }}
                      onFocus={() => {
                        updateTargeting(index, 'updated', true);
                      }}
                      onStateChange={({ state }) => {
                        setLocalMessageHeadline(state);
                      }}
                      richEditing
                      richEditingMenu
                      showLinkButton={false}
                      enableLinkExtension={false}
                      showListButton={false}
                      showSelectionControls={false}
                    />
                    <StatusBanner
                      as="label"
                      status="error"
                      text={error?.messageHeadline || '\u00a0'}
                      isVisible={!!error?.messageHeadline?.length}
                      css={({ spacing }: ThemeType) => css`
                        margin-top: ${spacing.small};
                      `}
                    />
                  </div>
                  {!checkIfDup(targeting.screens) && (
                    <div>
                      <div
                        css={css`
                          ${styles.inputWrapper}
                        `}
                      >
                        <Editor
                          id={`pw-screen-body-${index}`}
                          className="optional-editor"
                          labelText="Additional information"
                          initialContent={
                            targeting.wrappedAdditionalInformation?.html
                          }
                          value={localMessageAdditionalInformation || undefined}
                          mirrorState={webBody ? [webBody] : undefined}
                          shouldMirror={!targeting.updated && !!webBody?.doc}
                          relatedGtfsIds={editorRelatedGtfsIds}
                          richEditing
                          richEditingMenu
                          onChange={({ state }) => {
                            updateTargeting(
                              index,
                              'messageAdditionalInformation',
                              state,
                            );
                            updateTargeting(
                              index,
                              'wrappedAdditionalInformation',
                              wrapProseMirrorContent(state),
                            );
                          }}
                          onFocus={() => {
                            updateTargeting(index, 'updated', true);
                          }}
                          onStateChange={({ state }) => {
                            setLocalMessageAdditionalInformation(state);
                          }}
                          shouldFormatOnPaste
                        />
                      </div>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          margin-top: 16px;
                          margin-bottom: 24px;
                        `}
                      >
                        <Checkbox
                          css={css`
                            margin-right: 8px;
                          `}
                          size={CheckboxSize.small}
                          checked={targeting.isAda}
                          onChange={(checked) => {
                            updateTargeting(index, 'isAda', checked);
                          }}
                        />
                        <span>Append ADA service message</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div id={screenTargetingKey}>
                <ScreensSelector
                  value={targeting.screens}
                  onChange={(updated) => {
                    if (FEEDS_WITH_DUAL_ORIENTATIONS.has(feedId)) {
                      const newSelectedSreenTypes =
                        updated?.flatMap((s) => s.targetTypes) ?? [];

                      const {
                        targetsLandscapeScreens,
                        targetsPortraitScreens,
                      } = getTargetTypeOrientations(newSelectedSreenTypes);

                      if (targetsLandscapeScreens && targetsPortraitScreens) {
                        updateTargeting(index, 'uploadId', null);
                      }
                    }

                    updateTargeting(
                      index,
                      'screens',
                      updated.map((u) => ({
                        ...u,
                        targetTypes: getGroupedTargetingTypes({
                          updated: u.targetTypes,
                          feedId,
                        }),
                      })),
                    );
                    updateTargeting(index, 'updated', true);
                  }}
                  autofillScreenTargeting={feedId !== FeedId.MNR}
                  isEditing={(!!paramId || !!duplicate) && targeting.updated}
                />
                <StatusBanner
                  hasIcon
                  as="label"
                  status="error"
                  text={error?.screens || '\u00a0'}
                  isVisible={!!error?.screens?.length}
                  css={({ spacing }: ThemeType) => css`
                    margin-top: ${spacing.small};
                  `}
                />
              </div>
            </div>
            <div
              css={css`
                flex: 0 0 ${isScreenLandscape ? 380 : 320}px;
                margin-left: 2rem;
              `}
            >
              <p
                css={(theme: ThemeType) => css`
                  ${theme.typography.sizes.small};
                  line-height: 16px;
                  margin-top: 4px;
                `}
              >
                {targeting.isAsset &&
                  getScreenContentHelperMessages(
                    shouldDisplayScreenUpload,
                    screenSize,
                    isInvalidScreenSelection ?? false,
                  )}

                {!targeting.isAsset &&
                  !allTargetingTypes.length &&
                  feedId !== FeedId.NYCTSubway &&
                  !!targeting.screens.length && (
                    <React.Fragment>
                      Select Screen Type to View Template
                    </React.Fragment>
                  )}
              </p>
              {shouldDisplayScreenUpload && screenSize && targeting.isAsset && (
                <div id={screenTargetingUploadKey}>
                  <ScreenUploader
                    id={targeting.upload?.id}
                    defaultUpload={
                      targeting.upload?.mimeType !== 'application/zip'
                        ? (targeting.upload ?? undefined)
                        : undefined
                    }
                    onChange={(_id, upload) => {
                      if (upload) {
                        uploadRef.current = upload;
                      }

                      if (
                        !upload ||
                        upload.id === targeting.uploadId ||
                        uploadRef?.current?.id === targeting.uploadId
                      ) {
                        return;
                      }
                      updateTargeting(index, 'uploadId', upload.id);
                      updateTargeting(index, 'upload', upload);
                    }}
                    height={screenSize.height}
                    width={screenSize.width}
                    scale={checkIfDup(targeting.screens) ? 0.17 : undefined}
                    multiplier={isScreenLandscape ? 0.2 : 0.3}
                    css={css`
                      margin-right: 0;
                    `}
                    transitSettings={{
                      transitDestination: TransitDestination.VLE,
                      shouldZip: checkIfDup(targeting.screens),
                    }}
                  />
                  <StatusBanner
                    hasIcon
                    as="label"
                    status="error"
                    text={
                      isInvalidScreenSelection
                        ? PLANNED_MULTIPLE_SCREEN_ORIENTATIONS
                        : error?.uploadId || '\u00a0'
                    }
                    isVisible={
                      isInvalidScreenSelection || !!error?.uploadId?.length
                    }
                    css={({ spacing }: ThemeType) => css`
                      margin-top: ${spacing.small};
                    `}
                  />
                </div>
              )}

              {!targeting.isAsset &&
                (!!allTargetingTypes?.length ||
                  feedId === FeedId.NYCTSubway ||
                  isOutfront(feedId) ||
                  (!targeting.screens.length && feedId === FeedId.LIRR)) && (
                  <PlannedWorkTemplatePreview
                    description={targeting.wrappedMessageHeadline?.html ?? ''}
                    headline={
                      targeting.durationForTheWeekMessage || matrixHeader
                    }
                    humanReadableDurations={
                      targeting.hrdOverrideMessage || hrdOverrideMessage
                    }
                    isAtNight={
                      targeting.screenWeekParams.timeOfDay ===
                      MessageTimeOfDay.NIGHTS
                    }
                    messageType={
                      impacts.length === 1
                        ? impacts[0].status
                        : MessageType.MULTIPLE_CHANGES
                    }
                    additionalInfo={
                      targeting.wrappedAdditionalInformation?.html ?? ''
                    }
                    affectedRoutes={affectedRoutes}
                    affectsADA={targeting.isAda}
                    title={targeting.messageTitle}
                    isDup={checkIfDup(targeting.screens)}
                    overrideDisplayType={getTemplateDisplayType(
                      allTargetingTypes,
                      feedId,
                    )}
                  />
                )}
            </div>
          </div>
        )}
      </Accordion>
    </div>
  );
};

export default PlannedWorkScreenCompose;
