import { FeedId, getFlattenedGtfsKey } from '@mta-live-media-manager/shared';
import { MentionAtomNodeAttributes } from '@remirror/react';
import { MentionSuggestions_mentionSuggestions_MentionsConnection_nodes_Mention as MentionSuggestions_mentionSuggestions_nodes } from 'generated/MentionSuggestions';
import { Borough, MentionType } from 'generated/global-types';
import { isSubway, isTrain } from 'utils/feed-switches';

export interface ISuggestion extends MentionAtomNodeAttributes {
  feedId: FeedId;
  name: string;
  active?: boolean;
  trainNum?: string;
  relatedRouteIds?: string[];
  'data-bullet-type'?: MentionType;
  'data-bullet-boroughs'?: Borough[] | null;
  'data-bullet-route-id'?: string;
  'data-bullet-meta'?: any;
  'data-bullet-title'?: string;
  'data-bullet-id'?: string;
  'data-bullet-trip-id'?: string;
  'data-bullet-stop-id'?: string;
  'data-bullet-route-names'?: string[] | null;
  'data-bullet-display'?: string;
}

export const getSuggestionProps = (
  suggestion: MentionSuggestions_mentionSuggestions_nodes,
): ISuggestion => {
  const suggestionFeedId = suggestion.feedId as FeedId;
  const isRoute = suggestion.type === MentionType.ROUTE;
  const isTrip = suggestion.type === MentionType.TRIP;
  const isStop = suggestion.type === MentionType.STOP;
  let backgroundColor = '';
  let textColor = '';

  if (!(isSubway(suggestionFeedId) || suggestion.type !== MentionType.ROUTE)) {
    if (!isTrain(suggestionFeedId)) {
      backgroundColor = 'CCCCCC';
      textColor = '001840';
    } else {
      backgroundColor = (suggestion.color as string) ?? 'FFFFFF';
      textColor = (suggestion.textColor as string) ?? 'FFFFFF';
    }
  }

  const { firstStop, lastStop } = suggestion.meta ?? ({} as any);
  const label =
    suggestion.type === MentionType.TRIP && firstStop && lastStop
      ? `${firstStop.time} train from ${firstStop.name} to ${lastStop.name}`
      : suggestion.name;
  const flattenedKey = getFlattenedGtfsKey(suggestion.id);

  return {
    feedId: suggestionFeedId,
    id: suggestion.id,
    relatedRouteIds: suggestion.relatedRouteIds ?? undefined,
    label: `\u200c${label}\u200c`,
    style:
      suggestion.type === MentionType.ROUTE && textColor && backgroundColor
        ? `color: #${textColor}; background-color: #${backgroundColor};`
        : '',
    name: 'gtfs',
    trainNum: suggestion.name,
    title: label,
    textColor,
    backgroundColor,
    replacementType: 'partial',
    // because our DB expects prosemirror data structures in certain places,
    // we'll have to keep doing it this way despite the flexibility the new
    // remirror version gives us
    'data-bullet-title': label,
    'data-bullet-type': suggestion.type as MentionType,
    'data-bullet-id': suggestion.id,
    'data-bullet-route-id': isRoute ? flattenedKey : '',
    'data-bullet-trip-id': isTrip ? flattenedKey : '',
    'data-bullet-stop-id': isStop ? flattenedKey : '',
    'data-bullet-boroughs': suggestion.boroughs as Borough[] | null,
    'data-bullet-route-names': suggestion.relatedRouteIds,
    'data-bullet-display': isRoute ? '' : (suggestion.type as string),
    'data-bullet-meta': suggestion.meta,
  };
};
