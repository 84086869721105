/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useHistory, useParams } from 'react-router-dom';
import useLiveQuery from 'hooks/use-live-query';

import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';

import { usePrevious } from 'hooks';

import { PotentiallyUnboundedDuration } from '@mta-live-media-manager/shared';
import { useRoutes } from 'contexts/Routes';
import { getValidPlannedWorksScreenMessages } from 'utils/compose-helpers';

import * as styles from './index.styles';
import * as commonStyles from '../../../common/styles';

import PageMeta from '../../../common/PageMeta';
import Loader from '../../../common/skeletons/PageWithContent';
import BackOnlyPageHeading from '../../../scaffolding/back-only-page.heading';
import Button from '../../../common/Button';
import SinglePlannedWork from '../../../common/planned-work/SinglePlannedWork';
import { TStatusPillTypes } from '../../../common/status-pill';

import { useFeedId, FeedId } from '../../../../contexts/FeedId';

import ConfirmModal from '../../../common/confirm-modal';
import Modal from '../../../common/modal';
import { durationToString } from '../../../common/duration-list';
import Checkbox from '../../../common/Checkbox';

import {
  GetPlannedWorkById,
  GetPlannedWorkByIdVariables,
  GetPlannedWorkById_plannedWork_PlannedWork_durations_DatetimeRange as GetPlannedWorkById_plannedWork_durations,
  GetPlannedWorkById_plannedWork_PlannedWork_durationsWithoutExceptions_DatetimeRange as GetPlannedWorkById_plannedWork_durationsWithoutExceptions,
} from '../../../../generated/GetPlannedWorkById';
import { PlannedWorkStatus } from '../../../../generated/global-types';
import {
  ClearPlannedWork,
  ClearPlannedWorkVariables,
} from '../../../../generated/ClearPlannedWork';
import {
  SetPlannedWorkDirectDurations,
  SetPlannedWorkDirectDurationsVariables,
} from '../../../../generated/SetPlannedWorkDirectDurations';

const GetPlannedWorkByIdQuery = loader(
  '../../../../graphql/GetPlannedWorkById.gql',
);
const ClearPlannedWorkMutation = loader(
  '../../../../graphql/ClearPlannedWork.gql',
);
const SetPlannedWorkDirectDurationsMutation = loader(
  '../../../../graphql/SetPlannedWorkDirectDurations.gql',
);

interface ClearDurationProps {
  isOpen?: boolean;
  durations: PotentiallyUnboundedDuration[];
  onDismiss?: () => void;
  onConfirm?: (durations: PotentiallyUnboundedDuration[]) => void;
}

const ClearDurations: React.FC<ClearDurationProps> = ({
  durations,
  onDismiss = () => {},
  onConfirm = () => {},
}) => {
  const [checkedDurations, setCheckedDurations] = useState(
    durations.map(() => false),
  );

  const onSelect = (idx: number, checked: boolean) => {
    setCheckedDurations([
      ...checkedDurations.slice(0, idx),
      checked,
      ...checkedDurations.slice(idx + 1),
    ]);
  };

  const unselectedDurations = durations.filter(
    (_durations, idx) => !checkedDurations[idx],
  );

  return (
    <div>
      <p css={styles.modalP}>
        Please select all the time periods you want to clear. To clear screens,
        you will need to select all.
      </p>
      <ul css={styles.modalUl}>
        {durations.map(({ start, end }, idx) => (
          <li css={styles.modalLi} key={`${[start, end]}`}>
            <Checkbox
              id={`${[start, end]}`}
              onChange={(checked) => onSelect(idx, checked)}
              checked={checkedDurations[idx]}
            />{' '}
            <label css={styles.modalLabel} htmlFor={`${[start, end]}`}>
              {durationToString(start)}
              {end ? `- ${durationToString(end)}` : 'until further notice'}
            </label>
          </li>
        ))}
      </ul>
      <Button
        type="button"
        plain
        onClick={() => setCheckedDurations(durations.map(() => true))}
      >
        Select All
      </Button>
      <div css={styles.modalButtonContainer}>
        <Button type="button" onClick={() => onDismiss()}>
          Cancel
        </Button>
        <Button
          type="button"
          primary
          disabled={unselectedDurations.length === durations.length}
          onClick={() => onConfirm(unselectedDurations)}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

const ClearDurationModal: React.FC<ClearDurationProps> = ({
  isOpen,
  durations,
  onDismiss = () => {},
  onConfirm = () => {},
}) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Are you sure you want to clear this alert?"
      onDismiss={onDismiss}
    >
      {isOpen && (
        <ClearDurations
          durations={durations}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
        />
      )}
    </Modal>
  );
};

const getBackPage = (
  feedId: FeedId,
  status: PlannedWorkStatus | null | undefined,
  filters: string,
): { to: string; title: string } => {
  if (
    status === PlannedWorkStatus.CLEARED ||
    status === PlannedWorkStatus.REMOVED
  ) {
    return {
      to: `/${feedId}/planned-work/cleared${filters}`,
      title: 'Back to Cleared & Cancelled Planned Work',
    };
  }

  if (status === PlannedWorkStatus.DRAFT) {
    return {
      to: `/${feedId}/planned-work/draft${filters}`,
      title: 'Back to Draft Planned Work',
    };
  }

  return {
    to: `/${feedId}/planned-work${filters}`,
    title: 'Back to Planned Work',
  };
};

interface HistoryState {
  prevFilters: string;
}

const PlannedWorkSingle: React.FC = () => {
  const feedId = useFeedId();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const allRoutes = useRoutes();

  const { prevFilters = '' } = (history.location.state ?? {}) as HistoryState;

  if (!id) {
    throw new Error('`id` not a string. Cannot render event');
  }
  const plannedWorkId = parseInt(id, 10);

  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  // TODO: Handle errors
  const { loading, data } = useLiveQuery<
    GetPlannedWorkById,
    GetPlannedWorkByIdVariables
  >(GetPlannedWorkByIdQuery, {
    variables: {
      id: plannedWorkId,
    },
  });

  const [clearPlannedWork] = useMutation<
    ClearPlannedWork,
    ClearPlannedWorkVariables
  >(ClearPlannedWorkMutation, {
    onCompleted: () => {
      setIsClearModalOpen(false);
    },
  });

  const [setPlannedWorkDirectDurations] = useMutation<
    SetPlannedWorkDirectDurations,
    SetPlannedWorkDirectDurationsVariables
  >(SetPlannedWorkDirectDurationsMutation, {
    onCompleted: () => {
      setIsClearModalOpen(false);
    },
  });

  const message = data?.plannedWork;
  const messageFeedId = message?.feedId;
  const messageStatus = message?.status;

  useEffect(() => {
    if (messageFeedId && messageFeedId !== feedId) {
      history.replace(history.location.pathname.replace(feedId, messageFeedId));
    }
  }, [messageFeedId, feedId, history]);

  const durations: PotentiallyUnboundedDuration[] =
    message?.durations
      .filter((d): d is GetPlannedWorkById_plannedWork_durations => d !== null)
      .map(({ start, end }) => ({
        start: new Date(start?.value),
        ...(end?.value ? { end: new Date(end?.value) } : {}),
      })) || [];

  const durationsWithoutExceptions: PotentiallyUnboundedDuration[] =
    message?.durationsWithoutExceptions
      .filter(
        (d): d is GetPlannedWorkById_plannedWork_durationsWithoutExceptions =>
          d !== null,
      )
      .map(({ start, end }) => ({
        start: new Date(start?.value),
        ...(end?.value ? { end: new Date(end?.value) } : {}),
      })) || [];

  const messageTypes =
    message?.plannedWorkImpacts?.nodes.map((impact) => impact?.messageType) ||
    [];

  const entitySelectors = _sortBy(
    _uniqBy(
      message?.plannedWorkImpacts?.nodes?.flatMap(
        (impact) => impact?.entitySelectors,
      ),
      'routeId',
    ),
    'routeId',
  );

  const prevStatus = usePrevious(messageStatus);

  useEffect(() => {
    if (prevStatus && prevStatus !== messageStatus) {
      setIsStatusChanged(true);
    }
  }, [messageStatus, prevStatus]);

  const pathFilters = !isStatusChanged ? prevFilters : '';

  const validScreenMessages = getValidPlannedWorksScreenMessages(
    message?.plannedWorkScreenMessages?.nodes ?? [],
    allRoutes,
  );

  // Instead of fetching 2 statuses, we can just check if any of the durations
  // are live, and if so, the planned work is live, otherwise it's scheduled.
  const baseStatus: TStatusPillTypes | undefined = (() => {
    if (message?.status !== PlannedWorkStatus.CLEARING) {
      return undefined;
    }
    return durations.some(({ start, end }) => {
      const now = new Date();
      return !!start && !!end && new Date(start) <= now && new Date(end) >= now;
    })
      ? TStatusPillTypes.LIVE
      : TStatusPillTypes.SCHEDULED;
  })();

  return (
    <section
      css={[
        css`
          margin-bottom: 50px;
        `,
        commonStyles.container,
      ]}
    >
      <PageMeta title="Planned Work Details" />
      <BackOnlyPageHeading
        title="Planned Work Dashboard"
        back={getBackPage(feedId, message?.status, pathFilters)}
      />
      <Loader loading={loading}>
        {message && (
          <SinglePlannedWork
            plannedWorkId={plannedWorkId}
            entitySelectors={entitySelectors}
            durations={durations}
            durationsWithoutExceptions={durationsWithoutExceptions}
            author={message.author?.name}
            mainHtml={message.headerHtml}
            addlHtml={message.bodyHtml}
            messageType={messageTypes}
            status={message.status as PlannedWorkStatus}
            baseStatus={baseStatus}
            canReclearScreens={message.canReclearScreens}
            publishedAt={new Date(message.publishedAt)}
            clearedAt={
              message.clearedAt ? new Date(message.clearedAt) : undefined
            }
            createdAt={message.createdAt}
            clearedBy={message.userByClearedBy?.name}
            removedAt={message.removedAt}
            updatedBy={message.updater?.name}
            updatedAt={message.updatedAt}
            durationsEnd={message.durationsEnd}
            durationsSource={message.durationsSource}
            servicePlanNumbers={message.servicePlanNumbers}
            internalNotes={message.internalNotes}
            generalOrderNumbers={message.generalOrderNumbers}
            stationAlternatives={message.stationAlternatives}
            screenMessages={validScreenMessages}
            homepagePublishOffset={message.homepagePublishOffset}
            onClear={() => {
              setIsClearModalOpen(true);
            }}
            feedId={feedId}
          />
        )}
      </Loader>
      <ConfirmModal
        isOpen={isClearModalOpen && durations.length <= 1}
        title="Clear Planned Work"
        message="Are you sure you want to clear this advisory?"
        onDismiss={() => setIsClearModalOpen(false)}
        onConfirm={() => {
          clearPlannedWork({ variables: { plannedWorkId } });
        }}
      />
      <ClearDurationModal
        isOpen={isClearModalOpen && durations.length > 1}
        durations={durations}
        onDismiss={() => setIsClearModalOpen(false)}
        onConfirm={(newDurations) => {
          if (newDurations.length === 0) {
            return clearPlannedWork({ variables: { plannedWorkId } });
          }
          return setPlannedWorkDirectDurations({
            variables: {
              id: plannedWorkId,
              durations: newDurations.map(({ start, end }) => ({
                start: { value: start.toJSON(), inclusive: true },
                ...(end
                  ? { end: { value: end.toJSON(), inclusive: false } }
                  : {}),
              })),
            },
          });
        }}
      />
    </section>
  );
};

export default PlannedWorkSingle;
