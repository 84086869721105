/** @jsxImportSource @emotion/react */

import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import { PotentiallyUnboundedDuration } from '@mta-live-media-manager/shared';

import { ThemeType } from '../../theme';
import { TIME_FORMAT } from '../../constants/time';

export const durationToString = (date: Date, includeTime?: boolean): string =>
  `${format(date, 'MM/dd/yyyy')}${
    includeTime ? ` at ${format(date, TIME_FORMAT)}` : ''
  }`;

const DurationList: React.FC<{
  durations: PotentiallyUnboundedDuration[];
  includeTime?: boolean;
  children?: React.ReactNode;
}> = ({ durations, includeTime = true }) => {
  return durations.length ? (
    <ul
      css={({ typography, spacing }: ThemeType) => css`
        ${typography.sizes.small};

        font-family: ${typography.families.primary};
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin-bottom: ${spacing.small};
        }
      `}
    >
      {durations
        .sort((d1, d2) => (d1.start < d2.start ? -1 : 1))
        .map(({ start, end }) => (
          <li key={`${[start, end]}`}>
            {durationToString(start, includeTime)}
            {end
              ? ` - ${durationToString(end, includeTime)}`
              : ' until further notice'}
          </li>
        ))}
    </ul>
  ) : null;
};

export default DurationList;
