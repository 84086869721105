/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import { loader } from 'graphql.macro';
import { useFeedId } from 'contexts/FeedId';
import { useParams, useHistory } from 'react-router-dom';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import formatDate from 'date-fns/format';

import { useQuery, useMutation } from '@apollo/client';
import { DATE_FORMAT } from 'constants/time';
import StatusPill, { TStatusPillTypes } from 'components/common/status-pill';
import { ScreenType } from 'generated/global-types';
import * as Well from '../../common/well';
import Loader from '../../common/skeletons/PageWithContent';
import { LockedAssetScreenPreview } from '../Screen/in-screens-preview/in-screen-preview';

import * as S from './index.styled';

import {
  GetLockableUploadById,
  GetLockableUploadByIdVariables,
} from '../../../generated/GetLockableUploadById';
import {
  DeleteLockableUpload,
  DeleteLockableUploadVariables,
} from '../../../generated/DeleteLockableUpload';
import TargetedScreens from '../../common/asset-form/targeted-screens';
import LockableAssetManagement from './lockable-asset-management';

const GetLockableUploadByIdQuery = loader(
  '../../../graphql/GetLockableUploadById.gql',
);

const DeleteLockableUploadMutation = loader(
  '../../../graphql/DeleteLockableUpload.gql',
);

const LockableAssetView: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const feedId = useFeedId();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [deleteLockableUploadMutation, { loading: isDeleteUploadLoading }] =
    useMutation<DeleteLockableUpload, DeleteLockableUploadVariables>(
      DeleteLockableUploadMutation,
      {
        variables: { uploadId: id },
      },
    );

  const { loading, data } = useQuery<
    GetLockableUploadById,
    GetLockableUploadByIdVariables
  >(GetLockableUploadByIdQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const lockableUpload = data?.lockableUpload;

  const uploadAsset = lockableUpload?.upload;
  const thumbnailAsset = lockableUpload?.thumbnail;
  const tagetedScreens = lockableUpload?.screens?.nodes ?? [];

  const deleteLockableUpload = async () => {
    await deleteLockableUploadMutation();
    history.push(`/${feedId}/assets/locked`);
  };

  const buttons = (() => {
    return (
      <React.Fragment>
        <S.Button
          primary
          onClick={() => {
            history.push(`/${feedId}/assets/${id}/edit`);
          }}
        >
          Edit
        </S.Button>
        <S.Button
          disabled={isDeleteUploadLoading || !!tagetedScreens?.length}
          onClick={async () => {
            if (window.confirm('Are you sure you want to delete this asset?')) {
              deleteLockableUpload();
            }
          }}
        >
          Remove
        </S.Button>
      </React.Fragment>
    );
  })();

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <PageMeta title="View Locked Asset" />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}/assets/locked`, title: 'Back to locked' }}
      />
      <Well.Container>
        <Loader loading={loading}>
          <Well.Section>
            <S.Header>
              <S.Info>
                <S.Title>
                  {uploadAsset?.title ?? 'Asset Upload'}
                  {!!tagetedScreens?.length && (
                    <StatusPill
                      css={css`
                        margin-left: 8px;
                      `}
                      status={TStatusPillTypes.LIVE}
                      size="small"
                    />
                  )}
                </S.Title>
                <S.EditedLast>
                  {uploadAsset?.updatedAt && (
                    <React.Fragment>
                      <strong>Last Updated:</strong>{' '}
                      {formatDate(
                        new Date(uploadAsset?.updatedAt),
                        DATE_FORMAT,
                      )}
                      {lockableUpload?.author &&
                        ` by ${lockableUpload?.author?.name}`}
                    </React.Fragment>
                  )}
                </S.EditedLast>
              </S.Info>
              <S.Buttons>{buttons}</S.Buttons>
            </S.Header>
          </Well.Section>
          <Well.Section>
            <S.Label>Description</S.Label>
            <S.Description>{uploadAsset?.description}</S.Description>
            {tagetedScreens &&
              tagetedScreens.map(
                (screen) =>
                  screen.type &&
                  screen.totalCount && (
                    <TargetedScreens
                      key={screen.type}
                      type={screen.type}
                      totalCount={screen.totalCount}
                      hideDetails
                    />
                  ),
              )}
            <div
              css={css`
                display: grid;
                grid-auto-flow: column;
                grid-gap: 12px;
              `}
            >
              {uploadAsset && (
                <LockedAssetScreenPreview
                  key={uploadAsset?.id}
                  upload={uploadAsset}
                  thumbnail={thumbnailAsset}
                  scale={
                    lockableUpload?.screenType === ScreenType.DUP
                      ? 0.55
                      : undefined
                  }
                  zipContentsScale={
                    lockableUpload?.screenType === ScreenType.DUP
                      ? 0.17
                      : undefined
                  }
                />
              )}
            </div>
          </Well.Section>
          <LockableAssetManagement lockableUpload={lockableUpload} />
        </Loader>
      </Well.Container>
    </div>
  );
};

export default LockableAssetView;
