/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ThemeType } from '../../../theme';
import CommonButton from '../../common/Button';
import { input as inputCSS } from '../../common/styles';

export const Header: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <header
    css={() => css`
      display: flex;
      flex-direction: row;
    `}
    {...props}
  />
);

export const Info: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `}
    {...props}
  />
);

export const Title: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <h1
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.large};
      margin: 0 0 12px;
      display: flex;
      align-items: center;
    `}
    {...props}
  >
    {children}
  </h1>
);

export const EditedLast: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.small};
    `}
    {...props}
  />
);

export const Buttons: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => css`
      display: flex;
      flex: 0;
      flex-direction: row-reverse;
    `}
    {...props}
  />
);

export const Button: React.FC<{
  primary?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: any;
  children?: React.ReactNode;
}> = ({ primary = false, ...props }) => (
  <CommonButton
    type="button"
    primary={primary}
    css={() => css`
      height: 40px;
      width: 120px;
      min-width: 0;
      margin-left: 12px;
      padding: 0 15px;
    `}
    {...props}
  />
);

export const Label: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <h2
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      margin: 0 0 8px;
    `}
    {...props}
  >
    {children}
  </h2>
);

export const Description: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={() => css`
      margin: 0 0 8px;
      padding: 0;
      list-style: none;
    `}
    {...props}
  />
);

export const Input: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { children?: React.ReactNode }
> = (props) => (
  <input
    css={(theme: ThemeType) => css`
      ${inputCSS}
      box-sizing: border-box;
      padding: ${theme.spacing.xsmall} ${theme.spacing.small};
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors['border-dark']};
      border-radius: 4px;
      box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
    `}
    {...props}
  />
);

export const AccordionLabel: React.FC<
  unknown & { children?: React.ReactNode }
> = ({ children, ...props }) => (
  <h1
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.large};
      margin: 0 0 12px;
    `}
    {...props}
  >
    {children}
  </h1>
);

export const ScreenItemsContainer: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> & {
    listItemsCount: number;
  }
> & { children?: React.ReactNode } = ({ listItemsCount, ...props }) => {
  const ROWS_PER_COL = 16;
  const grid_cols = Math.ceil(listItemsCount / ROWS_PER_COL);
  const grid_rows =
    listItemsCount > ROWS_PER_COL ? ROWS_PER_COL : listItemsCount;

  return (
    <ul
      css={css`
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        display: grid;
        grid-template-columns: repeat(${grid_cols}, 1fr);
        grid-template-rows: repeat(${grid_rows}, 1fr);

        li {
          margin: 6px 0;
        }
      `}
      {...props}
    />
  );
};
