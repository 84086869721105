/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';
import formatDate from 'date-fns/format';
import pluralize from 'pluralize';

import {
  ContextualSelectorInput,
  Days,
  DurationsSourceInputMethod,
  GtfsEntitySelectorInput,
  MessageTimeOfDay,
  TargetType,
  ScreenOrientation as DBScreenOrientation,
} from 'generated/global-types';

import StatusPill, { TStatusPillTypes } from 'components/common/status-pill';
import { PLANNED_WORK_STATUSES } from 'constants/planned-work';
import PlannedWorkTemplatePreview, {
  PlannedWorkAffectedRoute,
} from 'components/common/planned-work-template-screen-preview';
import {
  DatetimeRangeInput,
  DurationsType,
  getPWTemplateHeader,
  humanReadableDurations,
  MessageType,
  PotentiallyUnboundedDuration,
} from '@mta-live-media-manager/shared';
import subDays from 'date-fns/subDays';
import { INDEFINITE_END_DATE_MESSAGE } from 'constants/empty-states';
import { useRoutes } from 'contexts/Routes';
import { ContentTypeLabel, WeightPropsContainer } from './shared';
import { PlaybackProvider } from '../../../contexts/Playback';
import Button from '../../common/Button';
import Bullet from '../../common/Bullet';
import AppLink from '../../common/app-link';
import Modal from '../../common/modal';
import PlannedWorkScreenPreview from './in-screens-preview/in-screen-preview';

import { ReactComponent as IconZoom } from '../../../images/icon_zoom.svg';

import useNamedStops from '../../../hooks/useNamedStops';
import useRoutesForStops, {
  Mode as RoutesForStopsMode,
} from '../../../hooks/useRoutesForStops';

import { ScreenPreviewDisplayTypes } from '../../../utils/screen-preview';
import {
  Screen_screen2_Screens2_plannedWorks_PlannedWorksConnection_nodes_PlannedWork as Screen_screen2_plannedWorks_nodes,
  Screen_screen2_Screens2_plannedWorks_PlannedWorksConnection_nodes_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage as Screen_screen2_plannedWorks_nodes_plannedWorkScreenMessages_nodes,
} from '../../../generated/Screen';

import * as S from './CampaignsOnScreen.styled';
import { DATE_FORMAT } from '../../../constants/time';
import {
  getDimensionsFromTargetType,
  getOrientationFromTargetType,
} from './in-screens-preview/helpers';

interface PlannedWorkRowProps {
  plannedWork: Screen_screen2_plannedWorks_nodes;
  screenMessage: Screen_screen2_plannedWorks_nodes_plannedWorkScreenMessages_nodes;
  screenOrientation: ScreenPreviewDisplayTypes;
  includeScreenContentType?: boolean;
  viewContentLabel?: string;
  weightingEnabled?: boolean;
  timeOnScreenPercentage?: number;
}
export const PlannedWorkRow: React.FC<PlannedWorkRowProps> = ({
  plannedWork,
  screenMessage,
  includeScreenContentType = false,
  viewContentLabel = 'View Planned Work',
  weightingEnabled = false,
  timeOnScreenPercentage = 0,
  screenOrientation,
}) => {
  const allRoutes = useRoutes();
  const [showLargePreview, setShowLargePreview] = React.useState(false);

  const durationsToDisplay = plannedWork.durations.slice(0, 2);
  const overflowingDurationCount = Math.max(
    plannedWork.durations.length - 2,
    0,
  );
  const entitySelectors = ([] as GtfsEntitySelectorInput[]) || [];

  const routes = entitySelectors
    .filter((es) => !!es?.routeId && !es?.stopId)
    .map((es) => es?.routeId) as string[];
  const stops = entitySelectors
    .filter((es) => !!es?.stopId)
    .map((es) => es?.stopId) as string[];

  const routesForStops = useRoutesForStops(stops, {
    mode: RoutesForStopsMode.union,
  });

  const namedStops = useNamedStops(stops);

  const statusPill = (
    <StatusPill
      status={
        PLANNED_WORK_STATUSES.find(
          (opt) => opt.data.status === plannedWork.status,
        )?.data.statusPill as TStatusPillTypes
      }
      size="small"
    />
  );

  const previewOrientation =
    screenOrientation === ScreenPreviewDisplayTypes.LANDSCAPE
      ? DBScreenOrientation.LANDSCAPE
      : DBScreenOrientation.PORTRAIT;
  const screenTargetTypes = screenMessage.screenTargeting
    .flatMap((s) => s?.targetTypes)
    .filter(
      (value, index, self) =>
        value &&
        self.indexOf(value) === index &&
        (value === TargetType.DUP ||
          getOrientationFromTargetType(value) === previewOrientation),
    ) as TargetType[];

  const screenDimensions = getDimensionsFromTargetType(screenTargetTypes[0]);

  const renderPWTemplate = (): React.ReactNode => {
    const durations: PotentiallyUnboundedDuration[] =
      plannedWork?.durations
        .filter((d) => d !== null)
        .map(({ start, end }) => ({
          start: new Date(start?.value),
          ...(end?.value ? { end: new Date(end?.value) } : {}),
        })) || [];
    const durationWithoutExceptions: PotentiallyUnboundedDuration[] =
      plannedWork.durationsWithoutExceptions
        .filter((d) => d !== null)
        .map(({ start, end }) => ({
          start: new Date(start?.value),
          ...(end?.value ? { end: new Date(end?.value) } : {}),
        })) || [];
    const offSetDays = screenMessage.screenPublishOffset?.days ?? 0;

    const screensDurations = offSetDays
      ? durations.map((duration) => {
          return {
            start: subDays(duration.start, offSetDays),
            end: duration.end,
          };
        })
      : durations;

    const matrixHeader = screensDurations
      ? getPWTemplateHeader({
          activeWorkPeriods: screensDurations.map((m) => ({
            start: m.start,
            end: m.end,
          })),
          daysOfWeek: {
            group: screenMessage?.durationsSettings?.daysOfWeek ?? undefined,
            days: screenMessage?.durationsSettings?.days?.length
              ? (screenMessage?.durationsSettings?.days as Days[])
              : [],
          },
          timeOfDay: screenMessage?.durationsSettings?.timeOfDay,
          override:
            screenMessage?.durationsSettings?.durationForTheWeekMessage ?? '',
          daysBeforeWorkPeriod: screenMessage?.screenPublishOffset?.days,
        })
      : '';

    const durationsText = durationWithoutExceptions.length
      ? humanReadableDurations(
          durationWithoutExceptions.map((d) => ({
            start: {
              value: d.start,
            },
            ...(d.end
              ? {
                  end: {
                    value: d.end,
                  },
                }
              : {}),
          })),
          {
            isOngoing: !plannedWork.durationsEnd,
            ongoingText:
              plannedWork.durationsSource?.untilFurtherNoticeMessage ??
              INDEFINITE_END_DATE_MESSAGE,
            type:
              plannedWork.durationsSource?.inputMethod ===
              DurationsSourceInputMethod.CADENCE
                ? DurationsType.CADENCE
                : DurationsType.DIRECT,
            cadenceEnd: plannedWork.durationsSource?.cadenceRange?.end,
            overrideText:
              plannedWork.durationsSource
                ?.humanReadableDurationsOverrideMessage,
          },
          plannedWork.durationsSource?.exceptions as DatetimeRangeInput[],
        )
      : '';

    const messageTypes =
      plannedWork?.plannedWorkImpacts?.nodes.map(
        (impact) => impact?.messageType,
      ) ?? [];

    const affectedRoutes = (() => {
      const screenMessageSelectors = screenMessage.screenTargeting.flatMap(
        (m) => m?.selectors ?? null,
      ) as ContextualSelectorInput[];
      const screenMessageRouteIds = screenMessageSelectors.map(
        (s) => s.context.gtfsRouteId as string,
      );
      const impactRouteIds = plannedWork.plannedWorkImpacts.nodes.flatMap((i) =>
        i.entitySelectors.map((es) => es?.routeId),
      );

      const affectedRouteIds = new Set([
        ...screenMessageRouteIds,
        ...impactRouteIds,
      ]);

      const allAffectedRoutes = allRoutes.filter((route) =>
        affectedRouteIds.has(route.gtfsId),
      );

      return allAffectedRoutes.map((r) => {
        return {
          gtfsRouteId: r.gtfsId,
          longName: r.longName,
          shortName: r.shortName,
          color: r.color,
          textColor: r.textColor,
        };
      });
    })();

    return (
      <PlannedWorkTemplatePreview
        description={screenMessage.body.html}
        headline={matrixHeader}
        humanReadableDurations={durationsText}
        isAtNight={
          screenMessage?.durationsSettings?.timeOfDay ===
          MessageTimeOfDay.NIGHTS
        }
        messageType={
          messageTypes.length === 1
            ? messageTypes[0]
            : MessageType.MULTIPLE_CHANGES
        }
        additionalInfo={screenMessage.additionalInfo?.html ?? ''}
        affectedRoutes={affectedRoutes as PlannedWorkAffectedRoute[]}
        affectsADA={screenMessage.isAda}
        title={screenMessage.title ?? ''}
        isDup={screenTargetTypes.includes(TargetType.DUP)}
        overrideDisplayType={screenOrientation}
      />
    );
  };

  return (
    <S.Container>
      <S.Preview>
        <S.ViewDetail onClick={() => setShowLargePreview(true)}>
          <IconZoom
            css={css`
              margin-bottom: 8px;
            `}
          />
          View Detail
        </S.ViewDetail>
        {screenMessage.body ? (
          <section
            css={css`
              width: calc(${screenDimensions[0]}px * ${0.08});
            `}
          >
            {renderPWTemplate()}
          </section>
        ) : (
          <PlannedWorkScreenPreview
            key={screenMessage.upload?.id}
            css={css`
              opacity: 1;
            `}
            targetType={screenTargetTypes[0]}
            upload={screenMessage.upload}
            scale={0.08}
            simple
          />
        )}
      </S.Preview>
      <S.Details>
        <S.Header>
          <S.Title>
            {includeScreenContentType && (
              <ContentTypeLabel>Planned</ContentTypeLabel>
            )}
            {plannedWork.header.text}
          </S.Title>
        </S.Header>
        <S.Durations>
          {durationsToDisplay.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              {formatDate(new Date(d?.start?.value), DATE_FORMAT)}-{' '}
              {formatDate(new Date(d?.end?.value), DATE_FORMAT)}
            </li>
          ))}
          {overflowingDurationCount > 0 && `+${overflowingDurationCount} more`}
        </S.Durations>

        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            css={css`
              margin-right: 6px;
            `}
          >
            {routes.map((routeId) => (
              <Bullet key={routeId} routeId={routeId} />
            ))}
            {routesForStops.map((routeId) => (
              <Bullet key={routeId} routeId={routeId} />
            ))}
          </div>
          {(() => {
            switch (namedStops.length) {
              case 0:
                return 'All Stations';
              case 1:
                return namedStops[0].name || namedStops[0].id;
              default:
                return pluralize('Station', namedStops.length, true);
            }
          })()}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-top: 16px;
          `}
        >
          <Button
            as={AppLink}
            to={`/${plannedWork.feedId}/planned-work/${plannedWork.id}`}
            type="button"
            size="xsmall"
            primary
          >
            {viewContentLabel}
          </Button>
        </div>
      </S.Details>
      {weightingEnabled ? (
        <WeightPropsContainer
          priority={screenMessage.priority}
          weight={screenMessage.weight}
          statusPill={statusPill}
          timeOnScreenPercentage={timeOnScreenPercentage}
        />
      ) : (
        <div>{statusPill}</div>
      )}
      {showLargePreview && (
        <Modal
          title="View Detail"
          onDismiss={() => setShowLargePreview(false)}
          restOnContent
          css={css`
            width: 1078px;
            max-width: unset;
          `}
        >
          <PlaybackProvider>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 24px;

                & > div:first-of-type {
                  width: calc(${screenDimensions[0]}px * ${0.3});
                }
              `}
            >
              {screenMessage.body ? (
                renderPWTemplate()
              ) : (
                <PlannedWorkScreenPreview
                  key={screenMessage.upload?.id}
                  css={css`
                    opacity: 1;
                  `}
                  targetType={screenTargetTypes[0]}
                  setIndex={0}
                  upload={screenMessage.upload}
                  scale={0.3}
                  simple
                />
              )}
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row-reverse;
              `}
            >
              <Button
                as={AppLink}
                primary
                to={`/${plannedWork.feedId}/planned-work/${plannedWork.id}`}
              >
                {viewContentLabel}
              </Button>
              <Button
                css={css`
                  margin-right: 16px;
                `}
                type="button"
                onClick={() => setShowLargePreview(false)}
              >
                Close
              </Button>
            </div>
          </PlaybackProvider>
        </Modal>
      )}
    </S.Container>
  );
};
