/** @jsxImportSource @emotion/react */

import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import TargetingAccordionLabel from 'components/common/screen-targeting-accordion-label';
import { GetPlannedWorkById_plannedWork_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage as GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes } from 'generated/GetPlannedWorkById';
import {
  ContextualSelectorInput,
  TargetType,
  PublishStatus,
  MessageTimeOfDay,
  Days,
  MessageType,
  FeatureFlagName,
} from 'generated/global-types';
import { CampaignTargeting } from 'components/pages/campaign-view/campaign-targeting';
import {
  PotentiallyUnboundedDuration,
  getPWTemplateHeader,
} from '@mta-live-media-manager/shared';
import { getLabelFromTargetTypes } from 'components/pages/Screen/in-screens-preview/helpers';
import { useFeedId } from 'contexts/FeedId';
import { useRoutes } from 'contexts/Routes';
import { getSelectorsFromContextualSelectors } from 'utils/campaign-helpers';
import theme from 'theme';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import pluralize from 'pluralize';
import Accordion from '../accordion';
import * as S from '../../pages/alert-view/index.styled';
import * as screenPreviewStyles from '../../pages/alert-view/ScreenPreview.styled';
import Heading from '../Heading';
import MessageDisplay from '../message/MessageDisplay';
import ScreenPreview from '../../pages/planned-work/screen-preview';
import { flexRow } from '../styles';
import { PublishStatusIcon } from '../status-icon';
import PlannedWorkTemplatePreview, {
  PlannedWorkAffectedRoute,
} from '../planned-work-template-screen-preview';
import PublishTimes from '../publish-times/PublishTimes';
import DurationFrequency from './duration-frequency';
import DurationList from '../duration-list';
import { TagsSelection } from '../../../types';

interface PlannedWorkScreenTargetDisplay {
  id: string;
  selectors: ContextualSelectorInput[];
  targetTypes: TargetType[];
  tags: TagsSelection;
}

const ScreenTargetingPreview: React.FC<{
  screenMessage: GetPlannedWorkById_plannedWork_plannedWorkScreenMessages_nodes;
  index: number;
  screensDurations: PotentiallyUnboundedDuration[];
  messageTypes: MessageType[];
  durationsText: string;
  durationsEnd: Date | null;
  removedAt: Date | null;
  impactRouteIds: string[];
  isClearing: boolean;
}> = ({
  screenMessage,
  index,
  screensDurations,
  messageTypes,
  durationsText,
  durationsEnd,
  removedAt,
  impactRouteIds,
  isClearing,
}) => {
  const feedId = useFeedId();
  const allRoutes = useRoutes();

  const weightingEnabled = useFeatureFlag(FeatureFlagName.WEIGHTING_FREQUENCY);

  const screenTargets: PlannedWorkScreenTargetDisplay[] | null = screenMessage
    ? (() => {
        const screenMessagesByTargetTypes: {
          [key: string]: PlannedWorkScreenTargetDisplay[];
        } = {};

        const id = screenMessage.screenTargeting
          .flatMap((m) => m?.targetTypes)
          .join(',');
        const newTarget = {
          id,
          selectors: screenMessage.screenTargeting.flatMap(
            (m) => m?.selectors,
          ) as ContextualSelectorInput[],
          targetTypes: screenMessage.screenTargeting.flatMap(
            (m) => m?.targetTypes,
          ) as TargetType[],
          tags: screenMessage.screenTargeting.reduce<TagsSelection>(
            (agg, cur) => ({ ...(agg ?? {}), ...(cur?.tags ?? {}) }),
            {},
          ),
        };

        if (screenMessagesByTargetTypes[id]) {
          screenMessagesByTargetTypes[id] = [
            ...screenMessagesByTargetTypes[id],
            newTarget,
          ];
        } else {
          screenMessagesByTargetTypes[id] = [newTarget];
        }

        return Object.keys(screenMessagesByTargetTypes).map((key) => {
          const selectors = screenMessagesByTargetTypes[key].reduce(
            (acc, target) => {
              return [...acc, ...target.selectors];
            },
            [] as ContextualSelectorInput[],
          );

          return {
            selectors,
            id: key,
            targetTypes: screenMessagesByTargetTypes[key][0].targetTypes,
            tags: screenMessagesByTargetTypes[key][0].tags,
          };
        });
      })()
    : null;

  const screenTargetTypes = screenMessage.screenTargeting
    .flatMap((s) => s?.targetTypes)
    .filter(
      (value, index, self) => self.indexOf(value) === index,
    ) as TargetType[];
  const isDup = screenTargetTypes.includes(TargetType.DUP);

  const screenMessageSelectors = screenMessage.screenTargeting.flatMap(
    (m) => m?.selectors ?? null,
  ) as ContextualSelectorInput[];
  const selectors = screenMessage.screenTargeting
    ? getSelectorsFromContextualSelectors(screenMessageSelectors, allRoutes)
    : [];
  const { selectedRouteIds, selectedStopIds } = selectors.reduce<{
    selectedRouteIds: string[];
    selectedStopIds: string[];
  }>(
    (selections, selector) => {
      selections.selectedRouteIds.push(...selector.routes.map((r) => r.id));
      selections.selectedStopIds.push(...selector.stops.map((r) => r.id));
      return selections;
    },
    {
      selectedRouteIds: [],
      selectedStopIds: [],
    },
  );

  const affectedRoutes = (() => {
    const affectedRouteIds = new Set(impactRouteIds);

    const allAffectedRoutes = allRoutes.filter((route) =>
      affectedRouteIds.has(route.gtfsId),
    );

    return allAffectedRoutes.map((r) => {
      return {
        gtfsRouteId: r.gtfsId,
        longName: r.longName,
        shortName: r.shortName,
        color: r.color,
        textColor: r.textColor,
      };
    });
  })();

  const matrixHeader = screensDurations
    ? getPWTemplateHeader({
        activeWorkPeriods: screensDurations.map((m) => ({
          start: m.start,
          end: m.end,
        })),
        daysOfWeek: {
          group: screenMessage?.durationsSettings?.daysOfWeek ?? undefined,
          days: screenMessage?.durationsSettings?.days?.length
            ? (screenMessage?.durationsSettings?.days as Days[])
            : [],
        },
        timeOfDay: screenMessage?.durationsSettings?.timeOfDay,
        override:
          screenMessage?.durationsSettings?.durationForTheWeekMessage ?? '',
        daysBeforeWorkPeriod: screenMessage?.screenPublishOffset?.days,
      })
    : '';

  const humanReadableDuration =
    screenMessage.durationsSettings?.humanReadableDurationsOverrideMessage ??
    durationsText;

  const commonProps = {
    accordion: {
      blurContent: isClearing,
      addClearingStatusPill: isClearing,
      label: (
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              margin-right: 8px;
            `}
          >
            <PublishStatusIcon
              size={24}
              status={
                isClearing ? PublishStatus.WARNING : PublishStatus.SUCCESS
              }
            />
          </div>
          <TargetingAccordionLabel
            routeIds={selectedRouteIds}
            stopIds={selectedStopIds}
            screenNumber={index + 1}
          />
        </div>
      ),
      helperItem: (
        <div
          css={css`
            > div {
              display: flex;
            }
            h6 {
              padding-left: 12px;
            }
            h6,
            time {
              font-weight: ${theme.typography.weights.normal};
            }
          `}
        >
          <PublishTimes
            clearedAt={screenMessage.clearedAt}
            createdAt={screenMessage.createdAt}
            updatedAt={screenMessage.updatedAt}
            endAt={removedAt ?? durationsEnd}
            labelSize="small"
            dateSize="small"
            labelPos="horizontal"
            createdAtLabel="Posted"
            showLiveTime={{
              created: false,
            }}
            showDate={{
              expired: false,
            }}
            showAllActionsDates
          />
        </div>
      ),
      subtitle: getLabelFromTargetTypes(screenTargetTypes, feedId),
    },
  };

  const isLandscape =
    (screenMessage?.upload?.width ?? 0) > (screenMessage?.upload?.height ?? 0);

  return (
    <div>
      <Accordion key={index} {...commonProps.accordion}>
        <div
          css={css`
            margin-bottom: 20px;
          `}
        >
          <div css={flexRow}>
            <screenPreviewStyles.Container>
              {screenMessage?.body && (
                <screenPreviewStyles.InfoBox>
                  <Heading
                    level={4}
                    size="medium"
                    css={css`
                      margin-bottom: 12px;
                    `}
                  >
                    Message
                  </Heading>
                  {!screenTargetTypes.includes(TargetType.DUP) && (
                    <MessageDisplay
                      title={matrixHeader}
                      innerHtml={humanReadableDuration}
                      size="small"
                      css={css`
                        background-color: #f8f8f8;
                        padding: 24px;
                        max-width: 624px;
                      `}
                    />
                  )}
                </screenPreviewStyles.InfoBox>
              )}

              {screenMessage?.body && (
                <screenPreviewStyles.InfoBox>
                  <MessageDisplay
                    title={screenMessage?.title ?? ''}
                    size="medium"
                    innerHtml={screenMessage?.body?.html ?? ''}
                    additionalHtml={screenMessage?.additionalInfo?.html ?? ''}
                    css={css`
                      background-color: #f8f8f8;
                      padding: 24px;
                      max-width: 624px;
                    `}
                  />
                </screenPreviewStyles.InfoBox>
              )}
              <div
                css={css`
                  margin-bottom: 20px;
                `}
              >
                <S.Message.PreviewTitle>Displays At</S.Message.PreviewTitle>
                {(screenTargets ?? []).map((st) => {
                  return (
                    <div key={st.id}>
                      <CampaignTargeting
                        css={css`
                          max-width: 624px;
                        `}
                        selectors={st.selectors}
                        targetTypes={st.targetTypes}
                        tags={st.tags}
                      />
                    </div>
                  );
                })}

                {weightingEnabled ? (
                  <screenPreviewStyles.InfoBox>
                    <Heading
                      level={4}
                      size="medium"
                      css={css`
                        margin: 14px 0 11px 0;
                      `}
                    >
                      Duration & Frequency
                    </Heading>
                    {!!screenMessage.screenPublishOffset?.days ||
                    screenMessage.isActiveDuringPeriod ? (
                      <Fragment>
                        {!!screenMessage.screenPublishOffset?.days && (
                          <DurationFrequency
                            priority={screenMessage.beforeActivePriority}
                            weight={
                              !isDup ? screenMessage.beforeActiveWeight : null
                            }
                            label={`Display ${pluralize(
                              'day',
                              screenMessage.screenPublishOffset?.days ?? 0,
                              true,
                            )} before each work period`}
                          />
                        )}
                        {screenMessage.isActiveDuringPeriod && (
                          <DurationFrequency
                            priority={screenMessage.priority}
                            weight={!isDup ? screenMessage.weight : null}
                            label="Display during work period"
                          />
                        )}
                      </Fragment>
                    ) : (
                      'N/A'
                    )}
                  </screenPreviewStyles.InfoBox>
                ) : (
                  <screenPreviewStyles.InfoBox>
                    <Heading
                      level={4}
                      size="medium"
                      css={css`
                        margin-bottom: 12px;
                      `}
                    >
                      Screen Duration
                    </Heading>
                    <DurationList durations={screensDurations} />
                  </screenPreviewStyles.InfoBox>
                )}
              </div>
            </screenPreviewStyles.Container>

            <div
              css={css`
                flex: 0 0 ${isLandscape ? 380 : 320}px;
                margin-left: 2rem;
              `}
            >
              {screenMessage &&
                (screenMessage.body ? (
                  <PlannedWorkTemplatePreview
                    description={screenMessage.body.html}
                    headline={matrixHeader}
                    humanReadableDurations={humanReadableDuration}
                    isAtNight={
                      screenMessage?.durationsSettings?.timeOfDay ===
                      MessageTimeOfDay.NIGHTS
                    }
                    messageType={
                      messageTypes.length === 1
                        ? messageTypes[0]
                        : MessageType.MULTIPLE_CHANGES
                    }
                    additionalInfo={screenMessage.additionalInfo?.html ?? ''}
                    affectedRoutes={
                      affectedRoutes as PlannedWorkAffectedRoute[]
                    }
                    affectsADA={screenMessage.isAda}
                    title={screenMessage.title ?? ''}
                    isDup={screenTargetTypes.includes(TargetType.DUP)}
                  />
                ) : (
                  <ScreenPreview
                    upload={screenMessage?.upload}
                    isLandscape={isLandscape}
                  />
                ))}
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default ScreenTargetingPreview;
