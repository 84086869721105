/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { HTMLAttributes } from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { useRoutes } from 'contexts/Routes';
import { useFeedId, FeedId } from 'contexts/FeedId';

import Bullet, { BulletSize } from './Bullet';
import Theme, { ThemeType, ThemeSizes } from '../../theme';
import AllStationsTag from './all-stations-tag';

const SortArrowsIcons: React.FC<{
  sortingTitle: string;
  fillUp: string;
  fillDown: string;
  children?: React.ReactNode;
}> = ({ sortingTitle, fillUp, fillDown }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <title>{sortingTitle}</title>
    <g id="Icons/Sort" stroke="none" strokeWidth="1" fillRule="evenodd">
      <path
        css={(theme: ThemeType) => css`
          fill: ${theme.colors[fillUp] ? theme.colors[fillUp] : fillUp};
        `}
        d="M4.90863431,2.00604876 C4.58166598,2.04992587 4.329656,2.32719944 4.329656,2.66346154 L4.32958319,11.735 L3.14346088,10.5600273 C2.88201036,10.3010807 2.45762454,10.3010807 2.19617403,10.5600273 C1.93460866,10.8177574 1.93460866,11.2391816 2.19617403,11.498242 L4.52618076,13.8059343 C4.65354775,13.9320815 4.8239255,14 5.00011544,14 C5.17566068,14 5.34552401,13.9319375 5.47267364,13.8072906 L7.80405685,11.498242 C8.06626446,11.2385455 8.06543216,10.816078 7.80082984,10.5580006 L7.7183913,10.4886343 C7.45819352,10.3036701 7.09091046,10.3269753 6.855605,10.5600273 L5.668973,11.7340625 L5.66940988,2.66346154 C5.66940988,2.29663016 5.36949718,2 4.99953294,2 L4.90863431,2.00604876 Z"
        id="Up-Arrow"
        transform="translate(5.000000, 8.000000) scale(1, -1) translate(-5.000000, -8.000000) "
      />
      <path
        css={(theme: ThemeType) => css`
          fill: ${theme.colors[fillDown] ? theme.colors[fillDown] : fillDown};
        `}
        d="M10.9086343,2.00604876 C10.581666,2.04992587 10.329656,2.32719944 10.329656,2.66346154 L10.3288907,11.7340625 L9.14346088,10.5600273 C8.88201036,10.3010807 8.45762454,10.3010807 8.19617403,10.5600273 C7.93460866,10.8177574 7.93460866,11.2391816 8.19617403,11.498242 L10.5261808,13.8059343 C10.6535477,13.9320815 10.8239255,14 11.0001154,14 C11.1756607,14 11.345524,13.9319375 11.4726736,13.8072906 L13.8040569,11.498242 C14.0662645,11.2385455 14.0654322,10.816078 13.8008298,10.5580006 L13.7183913,10.4886343 C13.4581935,10.3036701 13.0909105,10.3269753 12.855605,10.5600273 L11.6692271,11.7340625 L11.6694099,2.66346154 C11.6694099,2.29663016 11.3694972,2 10.9995329,2 L10.9086343,2.00604876 Z"
        id="Down-Arrow"
      />
    </g>
  </svg>
);

export const SortArrows: React.FC<{
  isSorted?: boolean;
  isSortedDesc?: boolean;
  children?: React.ReactNode;
}> = ({ isSorted, isSortedDesc }) => {
  return (
    <React.Fragment>
      <span
        aria-hidden="true"
        css={css`
          padding-left: 4px;
          vertical-align: text-bottom;
        `}
      >
        <SortArrowsIcons
          sortingTitle={
            isSortedDesc ? 'Descending Sorting' : 'Ascending Sorting'
          }
          fillUp={
            isSorted && isSortedDesc
              ? Theme.colors.accent1
              : Theme.colors.light8
          }
          fillDown={
            isSorted && !isSortedDesc
              ? Theme.colors.accent1
              : Theme.colors.light8
          }
        />
      </span>
      {isSorted && (
        <VisuallyHidden>
          {isSortedDesc ? 'descending' : 'ascending'}
        </VisuallyHidden>
      )}
    </React.Fragment>
  );
};

export const SortButton: React.FC<
  {
    isSorted: boolean;
    isSortedDesc: boolean;
    onClick: () => void;
    title: string;
    className?: string;
    textAlign?: string;
    width?: string;
  } & { children?: React.ReactNode }
> = ({
  className,
  isSorted,
  isSortedDesc,
  onClick,
  title,
  textAlign = 'left',
  width = '100%',
}) => {
  return (
    <div
      css={css`
        width: ${width};
        text-align: ${textAlign};
      `}
    >
      <button
        className={className}
        title="Toggle sort"
        type="button"
        onClick={onClick}
        css={css`
          font-family: inherit;
          font-weight: inherit;
          color: inherit;
        `}
      >
        {title}
        <SortArrows isSorted={isSorted} isSortedDesc={isSortedDesc} />
      </button>
    </div>
  );
};

export const Table: React.FC<
  HTMLAttributes<HTMLElement> & { children?: React.ReactNode }
> = (props) => {
  return (
    <table
      css={(theme: ThemeType) => css`
        border-collapse: separate;
        border-spacing: 0;
        td {
          border-bottom: 1px solid ${theme.colors.border};
        }
        tr:first-of-type td:first-of-type {
          border-top-left-radius: 8px;
        }
        tr:first-of-type td:last-child {
          border-top-right-radius: 8px;
        }
        tr:last-child td:first-of-type {
          border-bottom-left-radius: 8px;
        }
        tr:last-child td:last-child {
          border-bottom-right-radius: 8px;
        }
      `}
      {...props}
    />
  );
};

export const TableHead: React.FC<
  HTMLAttributes<HTMLElement> & { children?: React.ReactNode }
> = (props) => {
  return <thead {...props} />;
};

export const TableBody: React.FC<
  HTMLAttributes<HTMLElement> & { children?: React.ReactNode }
> = (props) => {
  return (
    <tbody
      css={css`
        border-radius: 8px;
        box-shadow:
          0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);
      `}
      {...props}
    />
  );
};

export const TableHeaderCell: React.FC<
  HTMLAttributes<HTMLElement> & { children?: React.ReactNode }
> = (props) => {
  return (
    <th
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.medium};
        font-family: ${theme.typography.families.primary};
        font-weight: ${theme.typography.weights.bold};
        padding: 12px 16px 12px;
        color: ${theme.colors.accent1};
        text-align: left;
      `}
      {...props}
    />
  );
};

export const TableRow: React.FC<
  HTMLAttributes<HTMLElement> & { children?: React.ReactNode }
> = ({ onClick, ...rest }) => {
  const hasOnClick = !!onClick;

  return (
    <tr
      css={css`
        &:hover {
          cursor: ${hasOnClick ? 'pointer' : 'auto'};
        }
      `}
      onClick={onClick}
      {...rest}
    />
  );
};

export const TableData: React.FC<
  {
    hasLeftBorder?: boolean;
    isActive?: boolean;
    width?: string;
  } & HTMLAttributes<HTMLTableDataCellElement>
> & { children?: React.ReactNode } = ({
  hasLeftBorder = true,
  isActive = true,
  width = '100px',
  ...rest
}) => {
  return (
    <td
      {...rest}
      css={(theme: ThemeType) => css`
        position: relative;
        width: ${width};
        background-color: ${isActive
          ? `${theme.colors.white}`
          : `${theme.colors.light1}`};

        ::before {
          content: ' ';
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          width: 0;

          border-left: ${hasLeftBorder
            ? `1px solid ${theme.colors.light1}`
            : 'none'};
        }
      `}
    />
  );
};

export const CellSpacer: React.FC<
  {
    hasLeftPadding?: boolean;
    textAlign?: string;
    maxWidth?: string;
  } & HTMLAttributes<HTMLDivElement>
> & { children?: React.ReactNode } = ({
  hasLeftPadding = true,
  textAlign = 'left',
  maxWidth = '100%',
  ...rest
}) => {
  return (
    <div
      css={css`
        padding: 8px;
        padding-left: ${hasLeftPadding ? '8px' : '0px'};
        text-align: ${textAlign};
        max-width: ${maxWidth};
      `}
      {...rest}
    />
  );
};

export const BulletsCell: React.FC<{
  routeIds: string[];
  limit?: number;
  textAlign?: string;
  size?: BulletSize;
  maxWidth?: string;
  children?: React.ReactNode;
}> = ({
  routeIds,
  limit,
  textAlign = 'left',
  size = BulletSize.small,
  maxWidth,
}) => {
  const allRoutes = useRoutes();
  const feedId = useFeedId();
  const allRoutesSelected = allRoutes.every((r) => routeIds.includes(r.gtfsId));
  const isNYCT = feedId === FeedId.NYCTSubway || feedId === FeedId.NYCTBus;

  if (allRoutesSelected) {
    return (
      <CellSpacer maxWidth={maxWidth} textAlign={textAlign}>
        <AllStationsTag isSmall={!isNYCT} />
      </CellSpacer>
    );
  }

  let bullets = routeIds.map((routeId) => (
    <Bullet
      key={routeId}
      size={size}
      routeId={routeId}
      style={{ margin: '0 3px 3px 0' }}
    />
  ));

  const isOverLimit = limit ? bullets.length > limit : false;
  const amountOverLimit =
    isOverLimit && limit ? Math.max(0, bullets.length - limit) : 0;
  const overLimitNotice = isOverLimit ? (
    <span
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.xsmall};
        position: relative;
        font-family: ${theme.typography.families.primary};
        color: ${theme.colors.black};
      `}
    >
      {' '}
      +{amountOverLimit} more
    </span>
  ) : null;

  bullets = isOverLimit ? bullets.slice(0, limit) : bullets;

  return (
    <CellSpacer maxWidth={maxWidth} textAlign={textAlign}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        `}
      >
        {bullets}
      </div>
      {overLimitNotice}
    </CellSpacer>
  );
};

export const TextCell: React.FC<
  {
    heading: React.ReactNode;
    description?: string;
    descriptionSize?: ThemeSizes;
    hasLeftPadding?: boolean;
    headingSize?: ThemeSizes;
    textAlign?: string;
    textWrap?: boolean;
  } & { children?: React.ReactNode }
> = ({
  children,
  heading,
  description,
  hasLeftPadding = true,
  headingSize = 'medium',
  descriptionSize = 'small',
  textAlign = 'left',
  textWrap = true,
  ...rest
}) => {
  return (
    <CellSpacer textAlign={textAlign} hasLeftPadding={hasLeftPadding} {...rest}>
      <div
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes[headingSize]};
          font-family: ${theme.typography.families.primary};
          font-weight: ${theme.typography.weights.bold};
          color: ${theme.colors.black};
          word-break: break-word;
          white-space: ${textWrap ? 'normal' : 'nowrap'};
        `}
      >
        {heading}
      </div>
      {description && (
        <div
          css={(theme: ThemeType) => css`
            ${theme.typography.sizes[descriptionSize]};
            padding-top: ${theme.spacing.xxsmall};
            font-family: ${theme.typography.families.primary};
            color: ${theme.colors.black};
          `}
        >
          {description}
        </div>
      )}
      {children}
    </CellSpacer>
  );
};

const PaginationButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & { children?: React.ReactNode }
> = (props) => {
  return (
    <button
      type="button"
      css={(theme: ThemeType) => css`
        margin-left: 20px;
        color: ${theme.colors.accent1};
        outline: 2px solid ${theme.colors.accent1};

        :hover {
          color: ${theme.colors.brand};
          outline: 2px solid ${theme.colors.brand};
        }

        :disabled {
          pointer-events: none;
          outline: 2px solid #ddd;
          color: #cacaca;
        }
      `}
      {...props}
    />
  );
};

export const TablePagination: React.FC<
  {
    onNextClick: () => void;
    onPrevClick: () => void;
    page: number;
    perPage: number;
    total: number;
  } & { children?: React.ReactNode }
> = ({ onNextClick, onPrevClick, page, perPage, total }) => {
  if (perPage > total) {
    return null;
  }

  const offset = perPage * page - perPage;
  const isLastPage = offset + perPage >= total;
  const isFirstPage = page === 1;
  const lastPageItemNumber = isLastPage ? total : offset + perPage;

  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
      `}
    >
      <div
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.small};
          font-family: ${theme.typography.families.primary};
          color: ${theme.colors.dark6};
        `}
      >
        {offset + 1} - {lastPageItemNumber} of {total}
      </div>
      <div>
        <PaginationButton
          disabled={isFirstPage}
          title="Previous Page"
          type="button"
          onClick={onPrevClick}
        >
          <span
            css={css`
              display: inline-block;
              position: relative;
              padding: 12px;
            `}
          >
            &lt;
          </span>
        </PaginationButton>
      </div>
      <div>
        <PaginationButton
          disabled={isLastPage}
          title="Next Page"
          type="button"
          onClick={onNextClick}
        >
          <span
            css={css`
              display: inline-block;
              position: relative;
              padding: 12px;
            `}
          >
            &gt;
          </span>
        </PaginationButton>
      </div>
    </div>
  );
};
