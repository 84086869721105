/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import { loader } from 'graphql.macro';
import theme, { ThemeType } from 'theme';

import {
  ASSET_TITLE_REQUIRED,
  ASSET_DESCRIPTION_REQUIRED,
  ASSET_LAYOUTS_UPLOADS_REQUIRED,
  ASSET_UPLOAD_REQUIRED,
} from 'constants/error-messages';
import { UploadType } from 'hooks/useUpload';
import {
  ScreenOrientation,
  ScreenType,
  TransitDestination,
} from 'generated/global-types';
import { getAssetPreviewScale } from 'utils/assets-helpers';
import { Toast, useToast } from 'ui-kit/toast';
import { GetLockableUploadById_lockableUpload_LockableUpload_screens_LockableUploadsScreensConnection_nodes_LockableUploadsScreen as GetLockableUploadById_lockableUpload_screens_nodes } from 'generated/GetLockableUploadById';
import { Folders, FoldersVariables } from 'generated/Folders';
import Label from '../form-elements/input-label';
import Select from '../form-elements/Select';
import Button from '../Button';
import ScreenLayoutButtons from '../screen-layout-type-buttons';
import {
  getLabelFromScreenType,
  getDimensionsFromScreenType,
} from '../../pages/Screen/in-screens-preview/helpers';
import { ScreenUploader } from '../campaign-form/screen-uploader';
import SocialMediaAssetUploader from './social-media-asset-uploader';
import StatusBanner from '../status-banner';
import AssetScreenInfo from './asset-screen-info';

import * as S from './index.styled';
import { FeedId } from '@mta-live-media-manager/shared';

const FoldersQuery = loader('../../../graphql/Folders.gql');

export enum FormTypes {
  SocialMedia = 'social-media',
  Lockable = 'lockable',
}

const formTitleInputLabels = {
  [FormTypes.SocialMedia]: 'Asset Title',
  [FormTypes.Lockable]: 'Internal Title',
};

export interface FormValues {
  feedId: string;
  title: string;
  description: string;
  upload?: UploadType;
  thumbnail?: UploadType;
  width?: number;
  height?: number;
  type?: ScreenType | null;
  folderId?: string | null;
}

type AssetFormRenderProp = (bag: {
  isValid: boolean;
  form: React.ReactNode;
  makeSubmitHandler: any;
}) => any;

interface FolderOption {
  label: string;
  value: string | null;
}

export interface DefaultValues {
  title?: string;
  description?: string;
  folder?: FolderOption;
  orientation?: ScreenOrientation;
  upload?: UploadType;
  thumbnail?: UploadType;
  type?: ScreenType | null;
  screenTypes?: GetLockableUploadById_lockableUpload_screens_nodes[];
  activeScreensCount?: number;
}

const defaultValues = {
  title: '',
  description: '',
  folder: null,
  orientation: null,
  type: null,
  upload: undefined,
  thumbnail: undefined,
  width: 0,
  height: 0,
  screenTypes: [],
  activeScreensCount: 0,
};

interface AssetFormProps {
  defaultValues?: DefaultValues;
  heading: string;
  feedId: FeedId;
  children: AssetFormRenderProp;
  formType: FormTypes;
}

enum AssetFormStages {
  SCREEN_TYPE_SELECTION = 'SCREEN_TYPE_SELECTION',
  UPLOAD_CONTENT_SELECTION = 'UPLOAD_CONTENT_SELECTION',
}

const MAIN_FOLDER = { label: '[Main]', value: null };

const AssetForm: React.FC<AssetFormProps> = ({
  children,
  feedId,
  heading,
  defaultValues: defaults = defaultValues,
  formType,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { shouldShowToast, showToast, hideToast } = useToast();

  const isSocialMediaUpload = formType === FormTypes.SocialMedia;
  const isLockableUpload = formType === FormTypes.Lockable;

  const {
    title: defaultTitle = defaultValues.title,
    description: defaultDescription = defaultValues.description,
    folder: defaultFolder = defaultValues.folder ?? MAIN_FOLDER,
    orientation: defaultOrientation = defaultValues.orientation,
    type: defaultType = defaultValues.type,
    upload: defaultUpload = defaultValues.upload,
    thumbnail: defaultThumbnail = defaultValues.thumbnail,
    screenTypes: defaultScreenTypes = defaultValues.screenTypes,
    activeScreensCount:
      defaultActiveScreensCount = defaultValues.activeScreensCount,
  } = defaults;

  const [surfaceErrors, setSurfaceErrors] = useState(false);

  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [folder, setFolder] = useState(defaultFolder);
  const [orientation, setOrientation] = useState(defaultOrientation);
  const [type, setType] = useState(defaultType);
  const [upload, setUpload] = useState(defaultUpload);
  const [thumbnail, setThumbnail] = useState(defaultThumbnail);
  const [dimensions, setDimensions] = useState(
    getDimensionsFromScreenType(orientation, type),
  );

  const [activeStage, setActiveStage] = useState(
    upload
      ? AssetFormStages.UPLOAD_CONTENT_SELECTION
      : AssetFormStages.SCREEN_TYPE_SELECTION,
  );

  useEffect(() => {
    if (type && orientation) {
      setDimensions(getDimensionsFromScreenType(orientation, type));
    } else if (!!upload?.width && !!upload?.height) {
      setDimensions([upload.width, upload.height]);
    }
  }, [type, orientation, upload, setDimensions]);

  const [orientationWidth, orientationHeight] = dimensions;

  const uploadDimensions: string = (() => {
    return `${orientationWidth} x ${orientationHeight}px`;
  })();

  const titleStatusBannerIsVisible = surfaceErrors && title.length === 0;
  const descriptionStatusBannerIsVisible =
    surfaceErrors && description.length === 0;

  const targetedScreens = defaultScreenTypes
    .map((screen) => screen.type && getLabelFromScreenType(screen.type, feedId))
    .join(', ');

  const isValid = [
    !!title.length,
    isSocialMediaUpload || (isLockableUpload && !!orientation?.length),
    !!upload,
    !!description.length,
  ].every(Boolean);

  const { data: foldersData, loading: areFoldersLoading } = useQuery<
    Folders,
    FoldersVariables
  >(FoldersQuery, {
    variables: { feedId },
    fetchPolicy: 'cache-and-network',
    skip: !isSocialMediaUpload,
  });

  const folderOptions = [
    ...(isSocialMediaUpload ? [MAIN_FOLDER] : []),
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...(foldersData?.socialMediaFolders?.nodes ?? [])?.map((folder) => ({
      label: folder.name,
      value: folder.id,
    })),
  ];

  return children({
    isValid,
    makeSubmitHandler: (fn: (fv: FormValues) => any) => async (evt: any) => {
      evt.preventDefault();

      if (isSubmitting) {
        return;
      }

      if (!isValid) {
        setSurfaceErrors(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        return;
      }

      setIsSubmitting(true);

      const formValues: FormValues = {
        feedId,
        type,
        title,
        width: orientationWidth,
        height: orientationHeight,
        description,
        upload,
        thumbnail,
        folderId: folder?.value,
      };

      await fn(formValues);

      setIsSubmitting(false);
    },
    form: (
      <S.AssetComposeContainer>
        <S.Content>
          <S.Section>
            <S.MainHeading>{heading}</S.MainHeading>
          </S.Section>
          <S.Section>
            <Label label={formTitleInputLabels[formType]}>
              <S.Input
                id="title"
                name="title"
                type="text"
                value={title}
                onChange={(evt) => setTitle(evt.target.value)}
                required
              />
            </Label>
            <StatusBanner
              as="label"
              status="error"
              text={ASSET_TITLE_REQUIRED}
              isVisible={titleStatusBannerIsVisible}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${titleStatusBannerIsVisible ? spacing.small : 0};
                transition: margin-top 0.3s ease;
              `}
            />
            <Label
              label="Description"
              css={css`
                margin-top: 24px;
              `}
            >
              <S.Input
                id="description"
                name="description"
                type="text"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
                required
              />
            </Label>
            <StatusBanner
              as="label"
              status="error"
              text={ASSET_DESCRIPTION_REQUIRED}
              isVisible={descriptionStatusBannerIsVisible}
              css={({ spacing }: ThemeType) => css`
                margin-top: ${descriptionStatusBannerIsVisible
                  ? spacing.small
                  : 0};
                transition: margin-top 0.3s ease;
              `}
            />
            {isSocialMediaUpload && (
              <Label
                label="Folder"
                css={css`
                  margin-top: 24px;
                `}
              >
                <Select
                  css={css`
                    width: 40%;
                  `}
                  placeholder="Select a folder"
                  options={folderOptions}
                  value={folder}
                  onChange={setFolder}
                  isLoading={areFoldersLoading}
                  styles={{
                    option: (
                      provided: {},
                      {
                        data: { value },
                        isSelected,
                        isFocused,
                      }: {
                        data: FolderOption;
                        isSelected: boolean;
                        isFocused: boolean;
                      },
                    ) => ({
                      ...provided,
                      paddingLeft: `${value ? '40' : '18'}px`,
                      fontWeight: value ? 'normal' : 'bold',
                      backgroundColor:
                        isSelected || isFocused
                          ? theme.colors['select-option-hover']
                          : theme.colors.white,
                      color: theme.colors.black,
                      '&:active': {
                        backgroundColor: theme.colors['select-option-hover'],
                      },
                    }),
                  }}
                />
              </Label>
            )}
          </S.Section>
        </S.Content>
        <S.Content>
          {isLockableUpload && (
            <React.Fragment>
              <S.Section
                css={css`
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                `}
              >
                <S.SubHeading
                  css={css`
                    margin-right: 16px;
                  `}
                >
                  Screens
                </S.SubHeading>
                {!!defaultActiveScreensCount && (
                  <Toast
                    shouldShow={shouldShowToast}
                    onDismiss={hideToast}
                    positionChange={false}
                  >
                    New file will replace content on {defaultActiveScreensCount}{' '}
                    {targetedScreens}{' '}
                    {pluralize('screen', defaultActiveScreensCount)}
                  </Toast>
                )}
                {activeStage === AssetFormStages.SCREEN_TYPE_SELECTION && (
                  <Button
                    size="small"
                    type="button"
                    disabled={!orientation}
                    onClick={() =>
                      setActiveStage(AssetFormStages.UPLOAD_CONTENT_SELECTION)
                    }
                  >
                    Next
                  </Button>
                )}
              </S.Section>
              <S.Section>
                {activeStage === AssetFormStages.SCREEN_TYPE_SELECTION && (
                  <ScreenLayoutButtons
                    noScreensEl={<S.NoScreensAvailable />}
                    onSelect={(o, s) => {
                      if (orientation === o && s === type) {
                        setOrientation(defaultOrientation);
                        setType(defaultType);
                      } else {
                        setOrientation(o);
                        setType(s);
                      }
                    }}
                    selected={orientation}
                    screenType={type}
                  />
                )}
                {activeStage === AssetFormStages.UPLOAD_CONTENT_SELECTION && (
                  <div>
                    <AssetScreenInfo
                      feedId={feedId}
                      targetedSceensTypes={defaultScreenTypes}
                      type={type}
                      onClick={() =>
                        setActiveStage(AssetFormStages.SCREEN_TYPE_SELECTION)
                      }
                    />
                    <div
                      css={css`
                        display: flex;
                      `}
                    >
                      <div
                        css={css`
                          width: 50%;
                        `}
                      >
                        <p
                          css={(theme: ThemeType) => css`
                            ${theme.typography.sizes.small};
                            line-height: 16px;
                          `}
                        >
                          Supported Image Files: PNG, JPEG (10mb max)
                          <br />
                          Supported Video Files: Mp4, mov (50mb max)
                          <br />
                          Dimensions: {uploadDimensions}
                        </p>
                        <div
                          css={css`
                            display: flex;
                            flex-direction: row;
                          `}
                        >
                          <ScreenUploader
                            id={upload?.id}
                            onChange={(id, currentUpload) => {
                              if (!currentUpload) {
                                setUpload(undefined);
                              }
                              if (
                                currentUpload &&
                                currentUpload?.id !== upload?.id
                              ) {
                                setUpload(currentUpload);
                                showToast();
                              }
                            }}
                            height={orientationHeight}
                            width={orientationWidth}
                            defaultUpload={upload}
                            scale={ScreenType.DUP === type ? 0.17 : undefined}
                            multiplier={
                              ScreenType.DUP === type ? 0.5 : undefined
                            }
                            transitSettings={{
                              shouldZip:
                                ScreenType.DUP === type &&
                                upload?.mimeType !== 'application/zip',
                              transitDestination: TransitDestination.VLE,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        css={css`
                          width: 50%;
                        `}
                      >
                        <p
                          css={(theme: ThemeType) => css`
                            ${theme.typography.sizes.small};
                            line-height: 16px;
                            margin-bottom: 46px;
                          `}
                        >
                          Thumbnail Preview
                        </p>
                        <div
                          css={css`
                            display: flex;
                            flex-direction: row;
                          `}
                        >
                          <ScreenUploader
                            id={thumbnail?.id}
                            onChange={(id, currentUpload) => {
                              if (!currentUpload) {
                                setThumbnail(undefined);
                              }
                              if (
                                currentUpload &&
                                currentUpload?.id !== thumbnail?.id
                              ) {
                                setThumbnail(currentUpload);
                                showToast();
                              }
                            }}
                            height={orientationHeight}
                            width={orientationWidth}
                            defaultUpload={thumbnail}
                            scale={ScreenType.DUP === type ? 0.17 : undefined}
                            multiplier={
                              ScreenType.DUP === type ? 0.5 : undefined
                            }
                            onlyAcceptImages
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <StatusBanner
                  status="error"
                  isVisible={surfaceErrors && (!orientation || !upload)}
                  text={ASSET_LAYOUTS_UPLOADS_REQUIRED}
                  css={({ spacing }: ThemeType) => css`
                    margin-top: ${spacing.small};
                  `}
                />
              </S.Section>
            </React.Fragment>
          )}
          {isSocialMediaUpload && (
            <React.Fragment>
              <S.Section>
                <S.SubHeading>Upload</S.SubHeading>
              </S.Section>
              <S.Section>
                <p
                  css={(theme: ThemeType) => css`
                    ${theme.typography.sizes.small};
                    line-height: 16px;
                  `}
                >
                  Supported Image Files: PNG, JPEG (5mb max)
                  <br />
                  Supported Video Files: Mp4, mov (512mb max)
                  <br />
                  Dimensions: No limitations
                </p>
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                  `}
                >
                  <SocialMediaAssetUploader
                    id={upload?.id}
                    onChange={(id, currentUpload) => {
                      if (!currentUpload) {
                        setUpload(undefined);
                      }
                      if (currentUpload && currentUpload?.id !== upload?.id) {
                        setUpload(currentUpload);
                      }
                    }}
                    height={upload?.height ?? 1080}
                    width={upload?.width ?? 1920}
                    multiplier={getAssetPreviewScale(
                      upload?.width ?? 1920,
                      upload?.height ?? 1080,
                    )}
                    defaultUpload={upload}
                  />
                </div>
                <StatusBanner
                  status="error"
                  isVisible={surfaceErrors && !upload}
                  text={ASSET_UPLOAD_REQUIRED}
                  css={({ spacing }: ThemeType) => css`
                    margin-top: ${spacing.small};
                  `}
                />
              </S.Section>
            </React.Fragment>
          )}
        </S.Content>
      </S.AssetComposeContainer>
    ),
  });
};

export default AssetForm;
