/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { Collapse } from 'react-collapse';
import { useFormContext } from 'react-hook-form';
import { prosemirrorNodeToHtml as toHTML, EditorState } from 'remirror';

import _uniq from 'lodash/uniq';

import { useDebouncedCallback } from 'use-debounce';
import AlertScreenPreview from 'ui-kit/alert-screen-preview/alert-screen-preview';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import InputLabel from 'components/common/form-elements/input-label';
import { input as inputCss } from 'components/common/styles';
import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import {
  getSuggestedDisplayType,
  ScreenPreviewDisplayTypes,
} from 'utils/screen-preview';

import HR from 'components/common/HR';
import { AlertFormSectionWrapper } from 'components/common/alert-form';
import Button from 'components/common/Button';
import { getScreenSubtitle } from 'components/pages/Screen/in-screens-preview/helpers';
import { FeatureFlagName, TargetType } from 'generated/global-types';
import { modifyIndexInArray } from 'utils/compose-helpers';
import { ALERTS_DEFAULT_DISP_FREQUENCY } from 'components/common/form-elements/display-frequency-selector';
import ScreensAccordion from 'components/common/screens-accordion';
import StatusBanner from 'components/common/status-banner';
import { TARGETING_TYPE_REQUIRED } from 'constants/error-messages';
import TargetingAccordionLabel from 'components/common/screen-targeting-accordion-label';
import FormHeader from '../../form-elements/FormHeader';
import DurationPicker from '../../form-elements/expiration-time-picker';
import Heading from '../../Heading';

import {
  getAlertDefaultScreenTargetTypes,
  registerScreenTargeting,
} from '../../../../utils/screen-targeting';
import { getMessageType, getAllRoutesFromImpacts } from '../impact-selector';

import Editor, {
  getInitialContent,
  getMentionsByType,
} from '../../form-elements/editor';
import ScreenSelector from '../../ScreenSelector';

import * as CS from '../../common-forms.styled';
import * as S from './index.styled';
import * as Layout from '../../layout';
import { ThemeType } from '../../../../theme';

import {
  AlertScreenTargeting,
  RouteMention,
  ScreenData,
  StopMention,
  TImpact,
} from '../../../../types';
import {
  SCREEN_TITLE_NAME,
  SCREEN_BODY_NAME,
  WEB_BODY_NAME,
  WEB_EXPIRATION_NAME,
  SCREEN_TARGETED_ROUTES_NAME,
  SCREEN_CUSTOMIZED_NAME,
  SCREEN_TARGETING_NAME,
  WEB_IMPACTS_NAME,
  SCREEN_BODY_ADDITIONAL_NAME,
  WEB_BODY_ADDITIONAL_NAME,
  WEIGHT_FREQUENCY_NAME,
  INITIAL_SCREEN_CHOICE_NAME,
  SCREENS_NAME,
  SCREEN_IS_DUPLICATE_NAME,
} from '../../../../constants/alerts';
import { useRoutes } from '../../../../contexts/Routes';
import { useFeedId } from '../../../../contexts/FeedId';

export interface AlertScreenInitialValues {
  initialBodyHtml?: string | null;
  initialAdditionalInfoHtml?: string | null;
  initialTitle?: string | null;
}

const DEBOUNCE_ON_CHANGE_MS = 300;

const ScreenDetails: React.FC<{
  show: boolean;
  initialHtml?: string | null;
  initialAdditionalHtml?: string | null;
  initialTitle?: string | null;
  index: number;
  onDelete: () => void;
}> = ({
  show,
  initialHtml,
  initialAdditionalHtml,
  initialTitle,
  index,
  onDelete,
}) => {
  const {
    register,
    unregister,
    watch,
    setValue,
    clearError,
    errors,
    triggerValidation,
    getValues,
  } = useFormContext();

  const hasScreenBodyError = errors
    ? errors[`${SCREEN_BODY_NAME}_${index}`]?.type ===
      `${SCREEN_BODY_NAME}_${index}`
    : false;

  const hasInitialScreenError = errors
    ? errors[`${INITIAL_SCREEN_CHOICE_NAME}_${index}`]?.type ===
      `${INITIAL_SCREEN_CHOICE_NAME}_${index}`
    : false;

  const showScreenTitle = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERTS_SCREEN_TITLE,
  );

  const feedId = useFeedId();
  const allRoutes = useRoutes();

  const [initAdditionalEditorCount, setInitAdditionalEditorCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);
  const [routesInEditor, setRoutesInEditor] = useState<RouteMention[]>([]);
  const [stopsInEditor, setStopsInEditor] = useState<StopMention[]>([]);
  const webExpiration = watch(WEB_EXPIRATION_NAME) as Date;
  const webBody = watch(WEB_BODY_NAME) as EditorState | undefined;
  const webAdditionalBody = watch(WEB_BODY_ADDITIONAL_NAME) as
    | EditorState
    | undefined;
  const webImpacts = watch(WEB_IMPACTS_NAME) as TImpact[];
  const allImpactedRouteMentions = getAllRoutesFromImpacts(webImpacts);
  const allAffectedRoutes = allRoutes.filter((route) =>
    allImpactedRouteMentions.find(
      (mention) => mention.routeId === route.gtfsId,
    ),
  );
  const allRoutesAffected = allRoutes.length === allAffectedRoutes.length;
  const screens = watch(SCREENS_NAME) as ScreenData[];
  const screentitle = screens[index][SCREEN_TITLE_NAME] as string;
  const screenBody = screens[index][SCREEN_BODY_NAME] as EditorState;
  const screenAdditionalBody = screens[index][
    SCREEN_BODY_ADDITIONAL_NAME
  ] as EditorState;
  const screenRoutes: RouteMention[] = watch(SCREEN_TARGETED_ROUTES_NAME) || [];
  const screenCustomized = screens[index][SCREEN_CUSTOMIZED_NAME] as boolean;
  const selectedRoutes = screens[index][SCREEN_TARGETING_NAME];
  const initialScreenChoice = screens[index][INITIAL_SCREEN_CHOICE_NAME] as
    | AlertScreenTargeting
    | undefined;
  const messageType = getMessageType(webImpacts);
  const messageTitleFallback =
    messageType && !initialTitle
      ? MESSAGE_TYPE_DESCRIPTIONS[messageType]
      : (initialTitle ?? '');
  const [isScreenTitleCustomized, setIsScreenTitleCustomized] =
    useState(!!screentitle);

  const descriptionStateRef = useRef<EditorState>();
  const additionalInfoStateRef = useRef<EditorState>();

  const [localScreenTitle, setLocalScreenTitle] = useState<string>(
    screentitle ?? '',
  );
  const [localEditorState, setLocalEditorState] = useState<EditorState>();
  const [localAdditionalEditorState, setLocalAdditionalEditorState] =
    useState<EditorState>();

  const relatedGtfsIds = _uniq(
    [...screenRoutes, ...routesInEditor, ...stopsInEditor].map(
      (mention) => mention.id,
    ),
  );
  const sidewalkScreenEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );

  const bodyHtml = useMemo(() => {
    return screenBody ? toHTML(screenBody.doc) : '';
  }, [screenBody]);

  const additionalBodyHtml = useMemo(() => {
    return screenAdditionalBody ? toHTML(screenAdditionalBody.doc) : '';
  }, [screenAdditionalBody]);

  useEffect(() => {
    if (
      !screenCustomized &&
      deletedCount > 0 &&
      screentitle !== localScreenTitle
    ) {
      setLocalScreenTitle(screentitle);
    }
  }, [deletedCount, localScreenTitle, screenCustomized, screentitle]);

  useEffect(() => {
    if (!webAdditionalBody && localAdditionalEditorState) {
      setInitAdditionalEditorCount((count) => count + 1);
      setLocalAdditionalEditorState(undefined);
      setValue(
        SCREENS_NAME,
        modifyIndexInArray({
          index,
          value: {
            ...screens[index],
            [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
          },
          array: screens,
        }),
      );
    }
  }, [
    webAdditionalBody,
    setValue,
    screenAdditionalBody,
    index,
    screens,
    localAdditionalEditorState,
  ]);

  useEffect(() => {
    register({
      name: SCREENS_NAME,
    });
    register({ name: SCREEN_TARGETED_ROUTES_NAME });
    registerScreenTargeting(register);

    // Data is being saved in SCREENS_NAME, the rest of these register functions are being used for validations
    register(
      {
        name: `${INITIAL_SCREEN_CHOICE_NAME}_${index}`,
      },
      {
        validate: {
          [`${INITIAL_SCREEN_CHOICE_NAME}_${index}`]: () =>
            show && sidewalkScreenEnabled
              ? !!screens[index][INITIAL_SCREEN_CHOICE_NAME]
              : true,
        },
      },
    );
    register(
      {
        name: `${SCREEN_BODY_NAME}_${index}`,
      },
      {
        validate: {
          [`${SCREEN_BODY_NAME}_${index}`]: () =>
            show
              ? screens[index][SCREEN_BODY_NAME]?.doc.textContent.length !== 0
              : true,
        },
      },
    );
    register(
      {
        name: `${SCREEN_TARGETING_NAME}_${index}`,
      },
      {
        validate: {
          [`${SCREEN_TARGETING_NAME}_${index}`]: () =>
            show
              ? screens[index][SCREEN_TARGETING_NAME].every(
                  (t) => t.options && t.options.length,
                )
              : true,
        },
      },
    );
  }, [register, show, index, screens, sidewalkScreenEnabled]);

  useEffect(() => {
    if (!show) {
      clearError(`${SCREEN_BODY_NAME}_${index}`);
      clearError(SCREEN_TARGETING_NAME);
    }
  }, [show, clearError, screenBody, index]);

  const screenPreviewDisplayType = getSuggestedDisplayType(feedId);

  const [debouncedScreenTitleUpdate] = useDebouncedCallback((value: string) => {
    setValue(
      SCREENS_NAME,
      modifyIndexInArray({
        index,
        array: screens,
        value: {
          ...screens[index],
          [SCREEN_TITLE_NAME]: value,
        },
      }),
    );
  }, DEBOUNCE_ON_CHANGE_MS);

  const isSidewalkScreen =
    initialScreenChoice === AlertScreenTargeting.SIDEWALK_SCREEN;

  const { selectedRouteIds, selectedStopIds } = selectedRoutes.reduce<{
    selectedRouteIds: string[];
    selectedStopIds: string[];
  }>(
    (selections, selector) => {
      selections.selectedRouteIds.push(selector.routeId);
      selector.options.forEach((o) => {
        if (o.stopId) {
          selections.selectedStopIds.push(o.stopId);
        }
      });
      return selections;
    },
    {
      selectedRouteIds: [],
      selectedStopIds: [],
    },
  );
  const descRef = useRef();
  const additionalInfoRef = useRef();

  return (
    <ScreensAccordion
      label={
        <TargetingAccordionLabel
          routeIds={selectedRouteIds}
          stopIds={selectedStopIds}
          screenNumber={index + 1}
        />
      }
      subtitle={
        initialScreenChoice
          ? getScreenSubtitle({
              targetTypes: selectedRoutes.flatMap((v) => v.layouts),
            })
          : undefined
      }
      canDelete={screens?.length > 1}
      onDelete={() => {
        unregister(`${INITIAL_SCREEN_CHOICE_NAME}_${screens.length - 1}`);
        unregister(`${SCREEN_BODY_NAME}_${screens.length - 1}`);
        unregister(`${SCREEN_TARGETING_NAME}_${screens.length - 1}`);
        setDeletedCount((c) => c + 1);
        onDelete();
      }}
      additionalCss={css`
        margin: 0 38px;
      `}
      index={index}
    >
      {!initialScreenChoice && sidewalkScreenEnabled && (
        <React.Fragment>
          <Layout.Row>
            <S.Screens.Section
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 40px 0;
              `}
            >
              <Button
                primary
                type="button"
                onClick={() => {
                  setValue(
                    SCREENS_NAME,
                    modifyIndexInArray({
                      index,
                      value: {
                        ...screens[index],
                        [WEIGHT_FREQUENCY_NAME]: ALERTS_DEFAULT_DISP_FREQUENCY,
                        [INITIAL_SCREEN_CHOICE_NAME]:
                          AlertScreenTargeting.STATION_SCREEN,
                        [SCREEN_TARGETING_NAME]: screens[index][
                          SCREEN_TARGETING_NAME
                        ].map((target) => {
                          return {
                            layouts:
                              target.layouts ??
                              getAlertDefaultScreenTargetTypes(feedId),
                            options: target.options ?? [],
                            routeId: target.routeId ?? '',
                          };
                        }),
                        [SCREEN_BODY_NAME]: webBody,
                      },
                      array: screens,
                    }),
                  );
                }}
                additionalStyles={css`
                  margin-right: 12px;
                  min-width: 48%;
                  min-height: 40px;
                `}
                size="large"
              >
                Station Screen
              </Button>
              <Button
                primary
                type="button"
                onClick={() =>
                  setValue(
                    SCREENS_NAME,
                    modifyIndexInArray({
                      index,
                      value: {
                        ...screens[index],
                        [INITIAL_SCREEN_CHOICE_NAME]:
                          AlertScreenTargeting.SIDEWALK_SCREEN,
                        [SCREEN_TARGETING_NAME]: screens[index][
                          SCREEN_TARGETING_NAME
                        ].map((target) => {
                          return {
                            layouts: [TargetType.DUP],
                            options: target.options ?? [],
                            routeId: target.routeId ?? '',
                          };
                        }),
                        [SCREEN_BODY_NAME]: webBody,
                        [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
                      },
                      array: screens,
                    }),
                  )
                }
                additionalStyles={css`
                  margin-right: 12px;
                  min-width: 48%;
                  min-height: 40px;
                `}
                size="large"
              >
                Sidewalk Screen
              </Button>
            </S.Screens.Section>
          </Layout.Row>
          <StatusBanner
            as="label"
            htmlFor="initial-screen-error"
            status="error"
            hasIcon
            text={TARGETING_TYPE_REQUIRED}
            isVisible={hasInitialScreenError}
            css={css`
              margin-top: 12px;
              margin-bottom: 12px;
            `}
          />
        </React.Fragment>
      )}
      {(initialScreenChoice || !sidewalkScreenEnabled) && (
        <Layout.Row>
          <div
            css={(theme: ThemeType) => css`
              flex: 1 1 auto;
              padding: ${theme.spacing.medium} ${theme.spacing.large}
                ${theme.spacing.medium};
              min-width: 0;
            `}
          >
            <CS.MainFieldContainer>
              {showScreenTitle &&
                initialScreenChoice !==
                  AlertScreenTargeting.SIDEWALK_SCREEN && (
                  <div
                    css={css`
                      margin-bottom: 24px;
                    `}
                  >
                    <InputLabel htmlFor={SCREEN_TITLE_NAME} label="Title">
                      <input
                        id={SCREEN_TITLE_NAME}
                        disabled={!show}
                        css={inputCss}
                        value={
                          isScreenTitleCustomized
                            ? localScreenTitle
                            : messageTitleFallback
                        }
                        onChange={(e) => {
                          setIsScreenTitleCustomized(true);
                          setLocalScreenTitle(e.target.value);
                          debouncedScreenTitleUpdate(e.target.value);
                        }}
                      />
                    </InputLabel>
                  </div>
                )}
              <Editor
                ref={descRef}
                labelText="Description"
                id={`${SCREEN_BODY_NAME}_${index}`}
                shouldMirror={!screenCustomized && !initialHtml}
                key={`${SCREEN_BODY_NAME}_${index}_${deletedCount}`}
                invalid={!!hasScreenBodyError}
                mirrorState={webBody ? [webBody] : undefined}
                value={localEditorState}
                initialContent={getInitialContent(initialHtml, screenBody, {
                  ignoreInitHtml: deletedCount > 0,
                })}
                relatedGtfsIds={relatedGtfsIds}
                showSelectionControls={false}
                showListButton={false}
                showLinkButton={false}
                enableLinkExtension={false}
                onBlur={() => {
                  triggerValidation({ name: SCREEN_BODY_NAME });
                }}
                onFocus={() =>
                  setValue(
                    SCREENS_NAME,
                    modifyIndexInArray({
                      index,
                      value: {
                        ...screens[index],
                        [SCREEN_CUSTOMIZED_NAME]: true,
                      },
                      array: screens,
                    }),
                  )
                }
                onStateChange={({ state }) => setLocalEditorState(state)}
                onChange={({ state }) => {
                  const { routes: _routes, stops: _stops } =
                    getMentionsByType(state);

                  descriptionStateRef.current = state;

                  setRoutesInEditor(_routes);
                  setStopsInEditor(_stops);
                  const currVals = getValues();

                  if (typeof currVals[SCREENS_NAME] !== 'undefined') {
                    setValue(
                      SCREENS_NAME,
                      modifyIndexInArray({
                        index,
                        value: {
                          ...getValues()[SCREENS_NAME][index],
                          [SCREEN_BODY_NAME]: state,
                          [SCREEN_BODY_ADDITIONAL_NAME]:
                            additionalInfoStateRef.current,
                        },
                        array: getValues()[SCREENS_NAME],
                      }),
                    );
                  }

                  setValue(SCREEN_TARGETED_ROUTES_NAME, _routes);
                }}
                shouldFormatOnPaste
              />
              {webAdditionalBody &&
                initialScreenChoice !==
                  AlertScreenTargeting.SIDEWALK_SCREEN && (
                  <Editor
                    className="optional-editor"
                    ref={additionalInfoRef}
                    showDetailMenu
                    shouldMirror={!screenCustomized && !initialAdditionalHtml}
                    key={`add-${initAdditionalEditorCount}_${deletedCount}`}
                    initialContent={getInitialContent(
                      initialAdditionalHtml,
                      screenAdditionalBody,
                      {
                        ignoreInitHtml: deletedCount > 0,
                      },
                    )}
                    labelText="Additional Information"
                    id={`${SCREEN_BODY_ADDITIONAL_NAME}_${index}`}
                    mirrorState={
                      webAdditionalBody ? [webAdditionalBody] : undefined
                    }
                    relatedGtfsIds={relatedGtfsIds}
                    value={localAdditionalEditorState}
                    showLinkButton={false}
                    enableLinkExtension={false}
                    onFocus={() =>
                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          value: {
                            ...screens[index],
                            [SCREEN_CUSTOMIZED_NAME]: true,
                          },
                          array: screens,
                        }),
                      )
                    }
                    onStateChange={({ state }) => {
                      setLocalAdditionalEditorState(state);
                    }}
                    onChange={({ state }) => {
                      additionalInfoStateRef.current = state;

                      setValue(
                        SCREENS_NAME,
                        modifyIndexInArray({
                          index,
                          value: {
                            ...getValues()[SCREENS_NAME][index],
                            [SCREEN_BODY_NAME]: descriptionStateRef.current,
                            [SCREEN_BODY_ADDITIONAL_NAME]: state,
                          },
                          array: getValues()[SCREENS_NAME],
                        }),
                      );
                    }}
                    shouldFormatOnPaste
                  />
                )}
            </CS.MainFieldContainer>
            <DurationPicker
              id="compose-screen-duration"
              value={webExpiration}
              disabled
            />
            <ScreenSelector index={index} isSidewalkLocked={isSidewalkScreen} />
          </div>
          <Layout.SideBar
            css={(theme: ThemeType) => css`
              background-color: ${theme.colors.white};
              margin: ${theme.spacing.medium};
              margin-top: 0px;
              flex-basis: ${screenPreviewDisplayType ===
              ScreenPreviewDisplayTypes.PORTRAIT
                ? '300px'
                : '375px'};
            `}
          >
            <Heading
              level={3}
              size="medium"
              css={css`
                margin-bottom: 8px;
              `}
            >
              Screen Preview
            </Heading>
            <AlertScreenPreview
              messageType={messageType}
              title={localScreenTitle}
              content={bodyHtml}
              additionalContent={additionalBodyHtml}
              affectedRoutes={allAffectedRoutes}
              allRoutesAffected={allRoutesAffected}
              displayType={
                isSidewalkScreen
                  ? ScreenPreviewDisplayTypes.DUP
                  : screenPreviewDisplayType
              }
            />
          </Layout.SideBar>
        </Layout.Row>
      )}
    </ScreensAccordion>
  );
};

const ComposeScreens: React.FC<{
  show: boolean;
  onToggle: (toggle: boolean) => void;
  isDuplicate?: boolean;
  initialValues?: AlertScreenInitialValues[];
}> = ({ show, onToggle, initialValues = [], isDuplicate = false }) => {
  const { watch, setValue, register } = useFormContext();
  const screensData = watch(SCREENS_NAME) as ScreenData[];
  const webBody = watch(WEB_BODY_NAME) as EditorState;
  const webAdditionalBody = watch(WEB_BODY_ADDITIONAL_NAME) as EditorState;
  const feedId = useFeedId();

  if (!screensData.length) {
    register({ name: SCREENS_NAME });
  }

  const onScreenDelete = (index: number): void => {
    setValue(SCREENS_NAME, [
      ...screensData.slice(0, index),
      ...screensData.slice(index + 1),
    ]);
  };

  return (
    <S.Screens.Section>
      <Layout.Row>
        <FormHeader
          title="Screens"
          enabled={show}
          onToggle={(event: React.ChangeEvent<HTMLInputElement>) =>
            onToggle(event.target.checked)
          }
          toggle
        />
      </Layout.Row>
      <HR />
      <Collapse isOpened={show}>
        {screensData.map((_screen, index) => {
          const key = `screendetails-${index}`;
          // If this is a duplicate alert, and the screen message is a duplicate,
          // then we use the content from the previous screen.
          const screenContentIdx =
            isDuplicate && _screen?.screenIsDuplicate && index > 0
              ? index - 1
              : index;
          return (
            <Fragment key={key}>
              <ScreenDetails
                show={show}
                initialHtml={initialValues[screenContentIdx]?.initialBodyHtml}
                initialAdditionalHtml={
                  initialValues[screenContentIdx]?.initialAdditionalInfoHtml
                }
                initialTitle={initialValues[screenContentIdx]?.initialTitle}
                index={index}
                onDelete={() => onScreenDelete(index)}
              />
              <HR />
            </Fragment>
          );
        })}
        <Layout.Row>
          <AlertFormSectionWrapper isGrayscale flex>
            <Button
              primary
              size="small"
              type="button"
              additionalStyles={css`
                margin-right: 12px;
              `}
              onClick={() => {
                setValue(SCREENS_NAME, [
                  ...screensData,
                  {
                    [WEIGHT_FREQUENCY_NAME]: ALERTS_DEFAULT_DISP_FREQUENCY,
                    [SCREEN_TITLE_NAME]: '',
                    [SCREEN_TARGETING_NAME]: [
                      {
                        options: [],
                        routeId: '',
                        layouts: getAlertDefaultScreenTargetTypes(feedId),
                      },
                    ],
                    [SCREEN_BODY_ADDITIONAL_NAME]: webAdditionalBody,
                    [SCREEN_BODY_NAME]: webBody,
                    [INITIAL_SCREEN_CHOICE_NAME]: undefined,
                    [SCREEN_CUSTOMIZED_NAME]: false,
                  },
                ]);
              }}
            >
              Add Screen
            </Button>
            {!!screensData.length && (
              <Button
                primary
                size="small"
                type="button"
                onClick={() => {
                  const lastScreenMessage = screensData[screensData.length - 1];
                  setValue(SCREENS_NAME, [
                    ...screensData,
                    {
                      ...lastScreenMessage,
                      [SCREEN_IS_DUPLICATE_NAME]: true,
                      [SCREEN_TARGETING_NAME]:
                        lastScreenMessage?.screenTargeting?.map((st) => ({
                          ...st,
                          options: [],
                        })),
                    },
                  ]);
                }}
              >
                Duplicate Screen
              </Button>
            )}
          </AlertFormSectionWrapper>
        </Layout.Row>
      </Collapse>
    </S.Screens.Section>
  );
};

export default ComposeScreens;
