/** @jsxImportSource @emotion/react */

import React, { useRef, useState, useEffect } from 'react';
import { css, jsx } from '@emotion/react';

import useWindowSize from '../../hooks/use-window-size';
import theme from '../../theme';
import StatusIcon from './status-icon';

const useElementHeight = (isVisible: boolean) => {
  const ref = useRef<HTMLElement | null>();
  const refHeight = ref.current?.getBoundingClientRect().height || 0;
  const [height, setHeight] = useState(refHeight);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    // we're only concerned with increasing height values
    // because a height smaller than the max-height will still
    // be accommodated, whereas a height larger than the max-height will be cut off.
    if (isVisible && (refHeight === 0 || refHeight > height)) {
      setHeight(refHeight);
    }
  }, [windowWidth, refHeight, isVisible, height]);

  return { ref, height };
};

type TStatusBannerTypes = 'success' | 'error' | 'warning';

const getStatusCSS = (status: TStatusBannerTypes) => {
  if (status === 'error') {
    return css`
      background-color: ${theme.colors['status-error-background']};
      color: ${theme.colors.black};
    `;
  }

  if (status === 'warning') {
    return css`
      background-color: ${theme.colors['status-warning']};
      padding: 9px 7px 9px;
    `;
  }

  return css`
    background-color: rgba(0, 170, 8, 0.3);
  `;
};

const StatusBanner: React.FC<{
  status: TStatusBannerTypes;
  text?: string;
  as?: string;
  hasIcon?: boolean;
  htmlFor?: string;
  isVisible?: boolean;
  transition?: boolean;
  children?: React.ReactNode;
}> = ({
  as = 'span',
  isVisible = true,
  hasIcon = false,
  transition = true,
  text,
  htmlFor,
  status,
  children,
  ...rest
}) => {
  const { ref: elementRef, height: elementHeight } =
    useElementHeight(isVisible);

  const containerCSS = transition
    ? css`
        max-height: ${isVisible && elementHeight
          ? `${elementHeight + 16}px`
          : '0px'};
        overflow: hidden;
        transition: max-height 0.3s ease;
      `
    : css`
        overflow: hidden;
      `;

  const numChildren = React.Children.count(children);

  return (
    <div css={containerCSS} {...rest}>
      <div
        css={css`
          ${theme.typography.sizes.small};
          display: flex;
          align-items: center;
          background-color: rgba(0, 170, 8, 0.3);
          border-radius: 4px;
          color: ${theme.colors.black};
          font-family: ${theme.typography.families.primary};
          padding: ${theme.spacing.xxsmall};

          ${getStatusCSS(status)};
        `}
        ref={(el) => {
          elementRef.current = el;
        }}
      >
        {hasIcon && (
          <div
            css={css`
              position: relative;
              top: 2px;
              display: inline-block;
              padding-right: 8px;
              padding-left: 4px;
            `}
          >
            <StatusIcon status={status} />
          </div>
        )}
        {numChildren > 0
          ? children
          : jsx(as, {
              htmlFor,
              css: css`
                display: inline-block;
                color: ${theme.colors.black};
                padding: ${theme.spacing.xxsmall};
                padding-left: ${hasIcon ? 0 : theme.spacing.xxsmall};
              `,
              'aria-hidden': !isVisible,
              children: text,
            })}
      </div>
    </div>
  );
};

export default StatusBanner;
