import React, { PropsWithChildren, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { sortedRoutes } from '@mta-live-media-manager/shared';
import {
  RoutesByFeed,
  RoutesByFeedVariables,
  RoutesByFeed_routes_RoutesConnection_nodes_Route as Route,
} from '../generated/RoutesByFeed';
import { isOutfront } from '../utils/feed-switches';
import { FeedId, useFeedId } from './FeedId';

const RoutesByFeedQuery = loader('../graphql/RoutesByFeed.gql');

const FeedIdContext = React.createContext<Route[]>([]);

interface Props {
  children?: React.ReactNode;
}

export const RoutesProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}: Props) => {
  const feedId = useFeedId();

  const { data } = useQuery<RoutesByFeed, RoutesByFeedVariables>(
    RoutesByFeedQuery,
    {
      variables: {
        feedId: isOutfront(feedId) ? FeedId.NYCTSubway : feedId,
      },
    },
  );
  const routes = sortedRoutes((data?.routes?.nodes as Route[]) || []);

  return (
    <FeedIdContext.Provider value={routes}>{children}</FeedIdContext.Provider>
  );
};

export const useRoutes = () => useContext(FeedIdContext);

export const useRoutesById = (routes: Route['gtfsId'][]) => {
  const routeData = useContext(FeedIdContext);

  return routeData.filter((route) => routes.indexOf(route.gtfsId) > -1);
};
