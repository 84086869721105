/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Fragment } from 'react';
import { useFeedId } from 'contexts/FeedId';
import { useRoutes } from 'contexts/Routes';
import useRoutesWithStops from 'hooks/useRoutesWithStops';
import uniq from 'lodash/uniq';
import { MnrRollingStockLine, Shop } from 'generated/global-types';

import { BOROUGH_INLINE_LABELS } from 'utils/boroughs';
import Bullet, { BulletSize } from './Bullet';
import {
  DIVISION_SHOP_OPTIONS,
  SHOP_LABELS,
  SHOP_LINES,
} from './form-elements/shop-selector';
import { MNR_ROLLING_STOCK_LINES } from './form-elements/mnr-rolling-stock-line-selector';
import AllStationsTag from './all-stations-tag';

const areStopsSequenced = (
  orderedStops: string[],
  orderedTargetedStops: string[],
): boolean => {
  const initialStopIndex = orderedStops.findIndex(
    (s) => s === orderedTargetedStops[0],
  );
  const possibleStopSequence = orderedStops.slice(
    initialStopIndex,
    initialStopIndex + orderedTargetedStops.length,
  );

  if (
    possibleStopSequence.length === orderedTargetedStops.length &&
    possibleStopSequence.every((s, i) => s === orderedTargetedStops[i])
  ) {
    return true;
  }

  return false;
};

export const BulletsList: React.FC<{
  routeIds: string[];
  children?: React.ReactNode;
}> = ({ routeIds }) => {
  const allRoutes = useRoutes();
  const allRoutesSelected = allRoutes.every((r) => routeIds.includes(r.gtfsId));

  if (allRoutesSelected) {
    return <AllStationsTag />;
  }

  return (
    <Fragment>
      {routeIds.map((r) => {
        return (
          <Bullet
            key={r}
            size={BulletSize.medium}
            routeId={r}
            style={{
              marginRight: '3px',
            }}
          />
        );
      })}
    </Fragment>
  );
};

export const ScreenTargetingAccordionLabel: React.FC<
  {
    screenNumber: number;
    routeIds?: string[];
    stopIds?: string[];
    trainTargeting?: {
      shops?: Shop[] | null;
      lines?: MnrRollingStockLine[] | null;
      isTargetingAllLirrTrains?: boolean | null;
    } | null;
  } & { children?: React.ReactNode }
> = ({ routeIds = [], stopIds = [], trainTargeting, screenNumber }) => {
  const feedId = useFeedId();
  const displayRoutes = [...routeIds];

  const allRoutesWithStops = useRoutesWithStops(feedId);

  const specificTargetingLabel = (() => {
    if (trainTargeting) {
      const { shops, lines, isTargetingAllLirrTrains } = trainTargeting;

      if (shops?.length) {
        displayRoutes.push(...uniq(shops.flatMap((s) => SHOP_LINES[s])));

        if (shops.length === 1) {
          return SHOP_LABELS[shops[0]];
        }

        const includesAllADivisionShops = DIVISION_SHOP_OPTIONS.A.every((o) =>
          shops.includes(o.value as Shop),
        );
        const includesAllBDivisionShops = DIVISION_SHOP_OPTIONS.B.every((o) =>
          shops.includes(o.value as Shop),
        );

        if (includesAllADivisionShops && includesAllBDivisionShops) {
          return 'All Shops';
        }

        if (
          includesAllADivisionShops &&
          DIVISION_SHOP_OPTIONS.A.length === shops.length
        ) {
          return 'All A Division Shops';
        }

        if (
          includesAllBDivisionShops &&
          DIVISION_SHOP_OPTIONS.B.length === shops.length
        ) {
          return 'All B Division Shops';
        }

        return `${shops.length} Shops`;
      }

      if (lines?.length) {
        displayRoutes.push(...lines.flatMap((l) => MNR_ROLLING_STOCK_LINES[l]));
      }

      if (isTargetingAllLirrTrains) {
        displayRoutes.push(...allRoutesWithStops.map((r) => r.gtfsId));
      }

      return 'All Stations';
    }

    if (!stopIds.length) {
      return 'All Stations';
    }

    if (routeIds.length === 1) {
      const routeOrderedStops =
        allRoutesWithStops.find((r) => r.gtfsId === routeIds[0])?.stops
          ?.nodes ?? [];

      if (stopIds.length === 1) {
        return routeOrderedStops.find((s) => s.gtfsId === stopIds[0])?.name;
      }

      const allRouteStopIds = routeOrderedStops.map((s) => s.gtfsId);
      const sortedStopIds = stopIds.sort(
        (a, b) => allRouteStopIds.indexOf(a) - allRouteStopIds.indexOf(b),
      );

      if (routeIds.length === 1) {
        const allStopsBoroughs = allRoutesWithStops.find(
          (r) => r.gtfsId === routeIds[0],
        );
        const selectedStops = (allStopsBoroughs?.stops.nodes ?? []).filter(
          ({ gtfsId }) => sortedStopIds.includes(gtfsId),
        );
        const firstBorough = selectedStops[0]?.borough;
        const allBoroughStopsCount = allStopsBoroughs?.stops.nodes.filter(
          (n) => n.borough === firstBorough,
        );
        if (
          sortedStopIds.length === allBoroughStopsCount?.length &&
          firstBorough
        ) {
          return `All ${BOROUGH_INLINE_LABELS[firstBorough]} Stations`;
        }
      }

      if (allRouteStopIds.every((s) => sortedStopIds.includes(s))) {
        return 'All Stations';
      }

      if (areStopsSequenced(allRouteStopIds, sortedStopIds)) {
        return `${
          routeOrderedStops.find((s) => sortedStopIds[0] === s.gtfsId)?.name
        } - ${
          routeOrderedStops.find(
            (s) => sortedStopIds[sortedStopIds.length - 1] === s.gtfsId,
          )?.name
        }`;
      }
    }

    const routesWithSpecificStops = allRoutesWithStops
      .filter((r) => r.stops.nodes.find((s) => stopIds.includes(s.gtfsId)))
      .map((r) => r.gtfsId);
    const stopCounts =
      stopIds.length +
      uniq(
        allRoutesWithStops
          .filter(
            (r) =>
              !routesWithSpecificStops.includes(r.gtfsId) &&
              routeIds.includes(r.gtfsId),
          )
          .flatMap((r) => r.stops.nodes)
          .map((s) => s.gtfsId),
      ).length;

    return `${stopCounts} Stations`;
  })();

  return (
    <span
      css={css`
        margin-right: 8px;
      `}
    >
      {!displayRoutes.length &&
      !trainTargeting?.shops?.length &&
      !trainTargeting?.lines?.length &&
      !trainTargeting?.isTargetingAllLirrTrains ? (
        `Screen ${screenNumber}`
      ) : (
        <Fragment>
          <BulletsList routeIds={displayRoutes.sort()} />
          <span
            css={css`
              margin-right: 8px;
            `}
          >
            {specificTargetingLabel}
          </span>
        </Fragment>
      )}
    </span>
  );
};

export default ScreenTargetingAccordionLabel;
