/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import ComplexDurationsSelector from 'components/common/complex-durations-selector';
import {
  validate as validateDurationsSource,
  sanitize as sanitizeDurationsSource,
  Context,
} from 'utils/durations-source';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { FeedId } from '@mta-live-media-manager/shared';
import { useRoutes } from 'contexts/Routes';
import {
  getSelectorsFromTrainTargeting,
  getSelectorFromRouteId,
  CampaignTargetingFormStages,
  getInitalTargetingActiveStage,
  getInitialPropsForTrainTargeting,
  getTransformedScreenSelectors,
} from 'utils/campaign-helpers';

import { areAnyDatesInPast } from 'utils/date-helpers';
import {
  getScreenTargetingFieldKey,
  scrollToErrors,
} from 'utils/compose-helpers';

import { UploadType } from '../../../hooks/useUpload';
import Label from '../form-elements/input-label';
import {
  CAMPAIGN_DATES_EXPIRED,
  CAMPAIGN_LAYOUTS_UPLOADS_REQUIRED,
  CAMPAIGN_LAYOUTS_UPLOADS_REQUIRED_NO_LAYOUT_SELECTION,
  CAMPAIGN_MNR_LINES_REQUIRED,
  CAMPAIGN_SHOPS_REQUIRED,
  CAMPAIGN_STATIONS_SCREENS_REQUIRED,
  CAMPAIGN_TITLE_REQUIRED,
  STOP_SELECTOR_MISSING_STOPS,
  TARGETING_TYPE_REQUIRED,
} from '../../../constants/error-messages';
import StatusBanner from '../status-banner';
import {
  getPositionalScreensWithDimensions,
  Screens as LayoutKind,
} from '../screen-layout-buttons';
import SingleScreenTargeting, {
  getISelectorFromEntitySelectors,
  ISingleScreenTargeting,
  getScreenIconFromScreenDetais,
} from './single-screen-targeting';
import {
  MessageError,
  CampaignErrorKeys,
  ICampaignError,
  IScreenTargetingError,
  IScreenTargetingErrorKey,
} from './errors';

import { ThemeType } from '../../../theme';
import {
  DurationsSourceInput,
  DurationsSourceInputMethod,
  CreateCampaignUploadsInputRecordInput,
  TargetType,
  SchedulePriority,
  TrainTargetingInput as TrainTargeting,
  CadenceInput,
  CampaignScreenSelectorInput,
  FeatureFlagName,
} from '../../../generated/global-types';
import {
  GetScreenDetailsByName,
  GetScreenDetailsByNameVariables,
  GetScreenDetailsByName_screenDetails_ScreensNormalized as IScreenDetails,
} from '../../../generated/GetScreenDetailsByName';
import {
  ISelector,
  getContextualSelectorsByType,
} from '../form-elements/multi-route-stop-selector';

import * as S from './index.styled';

import Loader from '../Loader';
import CampaignScreenTargeting from './campaign-screen-targeting';
import Button from '../Button';
import ConfirmModal from '../confirm-modal';

const GetScreenDetailsByNameQuery = loader(
  '../../../graphql/GetScreenDetailsByName.gql',
);

interface CampaignTargetingInput {
  id?: number | null;
  targetTypes: TargetType[];
  screenSelectors: CampaignScreenSelectorInput[];
  uploads: CreateCampaignUploadsInputRecordInput[];
  priority?: SchedulePriority | null;
  weight: number;
  trainTargeting?: TrainTargeting | null;
  screenName?: string | null;
}

export interface FormValues {
  feedId: string;
  title: string;
  durationsSource: DurationsSourceInput;
  targeting: CampaignTargetingInput[];
}

type CampaignFormRenderProp = (bag: {
  isValid: boolean;
  form: React.ReactNode;
  makeSubmitHandler: any;
}) => any;

const getEmptyTargeting = ({
  rollingStockEnabled,
  sidewalkTargetingEnabled,
  activeStage,
}: {
  rollingStockEnabled: boolean;
  sidewalkTargetingEnabled: boolean;
  activeStage?: CampaignTargetingFormStages;
}) => ({
  id: undefined,
  activeStage:
    activeStage ??
    getInitalTargetingActiveStage({
      sidewalkTargetingEnabled,
      rollingStockEnabled,
    }),
  layouts: [],
  screenSelectors: [
    {
      selector: {
        routes: [],
        stops: [],
      },
      tags: {},
    },
  ],
  weightFrequency: {
    priority: SchedulePriority.NORMAL,
    weight: 1,
  },
  trainTargeting: null,
  uploads: {},
  screenName: null,
});

export interface DefaultValues {
  title?: string;
  durationsSource?: DurationsSourceInput;
  targeting?: {
    id?: number;
    screenSelectors?: CampaignScreenSelectorInput[];
    layouts?: LayoutKind[];
    uploads?: { [k: string]: UploadType };
    weightFrequency?: {
      priority: SchedulePriority;
      weight: number;
    };
    trainTargeting?: TrainTargeting | null;
    screenName?: string | null;
  }[];
}

const defaultValues = {
  title: '',
  durationsSource: {
    inputMethod: DurationsSourceInputMethod.DIRECT,
    directDurations: [{}],
    cadences: [{}] as CadenceInput[],
  },
  targeting: [] as any[],
};

interface CampaignFormProps {
  defaultValues?: DefaultValues;
  heading: string;
  feedId: FeedId;
  children: CampaignFormRenderProp;
}

const CampaignForm: React.FC<CampaignFormProps> = ({
  children,
  feedId,
  heading,
  defaultValues: defaults = defaultValues,
}) => {
  const allRoutes = useRoutes();

  const isMountedRef = useRef<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationModalOptions, setConfirmationModalOptions] =
    React.useState({
      confirmedExpiredDates: false,
      isOpen: false,
      onConfirm: () => {},
    });

  // Form values
  const {
    title: defaultTitle = defaultValues.title,
    durationsSource: defaultDurationsSource = defaultValues.durationsSource,
    targeting: defaultTargeting = defaultValues.targeting,
  } = defaults;

  const screenName = defaultTargeting?.[0]?.screenName;
  const isSingleScreenCampaign = !!screenName;

  const { data: screenDetailsData, loading: screenDetailsLoading } = useQuery<
    GetScreenDetailsByName,
    GetScreenDetailsByNameVariables
  >(GetScreenDetailsByNameQuery, {
    variables: { name: screenName ?? '' },
    skip: !screenName,
  });

  const screenDetails = screenDetailsData?.screenDetails;

  const [surfaceErrors, setSurfaceErrors] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const [durationsSource, setDurationsSource] = useState<DurationsSourceInput>(
    defaultDurationsSource,
  );

  const rollingStockEnabled = useFeatureFlag(
    FeatureFlagName.CAMPAIGN_ROLLING_STOCK,
  );
  const sidewalkTargetingEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );

  const [targeting, setTargeting] = useState(
    defaultTargeting?.length
      ? defaultTargeting.map(
          (t) =>
            ({
              id: t.id,
              activeStage: getInitalTargetingActiveStage({
                sidewalkTargetingEnabled,
                rollingStockEnabled,
                layouts: t.layouts?.length ? [...t.layouts] : undefined,
                trainTargeting: t.trainTargeting,
              }),
              screenSelectors: t.screenSelectors
                ? getTransformedScreenSelectors(
                    t.screenSelectors as CampaignScreenSelectorInput[],
                    allRoutes,
                  )
                : [],
              layouts: t.layouts?.length ? [...t.layouts] : undefined,
              weightFrequency: t.weightFrequency ?? {
                priority: SchedulePriority.NORMAL,
                weight: 1,
              },
              trainTargeting: t.trainTargeting,
              uploads: t.uploads,
              screenName: t.screenName,
            }) as {
              id?: number;
              activeStage: CampaignTargetingFormStages;
              screenSelectors: ISelector[];
              layouts: TargetType[];
              weightFrequency: {
                priority: SchedulePriority;
                weight: number;
              };
              trainTargeting?: TrainTargeting | null;
              uploads?: { [k: string]: UploadType };
              screenName?: string | null;
            },
        )
      : [
          getEmptyTargeting({
            rollingStockEnabled,
            sidewalkTargetingEnabled,
          }),
        ],
  );

  const addEmptyTargeting = (overrideTargeting: Object) => {
    setTargeting([
      ...targeting,
      {
        ...getEmptyTargeting({
          rollingStockEnabled,
          sidewalkTargetingEnabled,
        }),
        ...overrideTargeting,
      },
    ]);
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const initSingleScreenCampaignTargeting = () => {
      if (!isSingleScreenCampaign || !screenDetails) {
        return;
      }
      const { targetType, name: screenName } = screenDetails;
      const { routes, stops } = getISelectorFromEntitySelectors(
        screenDetails.entitySelectors ?? [],
      );
      const layouts = targetType
        ? [
            targetType === TargetType.TRIPTYCH_AD
              ? TargetType.SINGLE_AD_PORTRAIT
              : targetType,
          ]
        : [];
      setTargeting((oldTargeting) => [
        {
          ...getEmptyTargeting({
            rollingStockEnabled,
            sidewalkTargetingEnabled,
          }),
          ...oldTargeting[0],
          activeStage:
            CampaignTargetingFormStages.STATION_UPLOAD_CONTENT_SELECTION,
          screenSelectors: [
            {
              selector: {
                routes,
                stops,
              },
            },
          ],
          layouts,
          screenName,
        },
      ]);
    };

    initSingleScreenCampaignTargeting();
  }, [
    screenDetails,
    isSingleScreenCampaign,
    rollingStockEnabled,
    sidewalkTargetingEnabled,
  ]);

  const durationsSourceError = validateDurationsSource(
    durationsSource,
    Context.campaigns,
  );

  const isValid = [
    title.length > 0,
    !durationsSourceError,
    targeting.every(
      ({ activeStage, layouts, screenSelectors, trainTargeting, uploads }) => {
        const selectors = screenSelectors.flatMap(({ selector }) => selector);
        const allContextualTargetsByType =
          getContextualSelectorsByType(selectors);

        const positionsAndDimensions = getPositionalScreensWithDimensions(
          layouts?.[0] ?? null,
        );

        return (
          activeStage !==
            CampaignTargetingFormStages.TARGETING_TYPE_SELECTION &&
          (!!trainTargeting?.shops?.length ||
            !!trainTargeting?.lines?.length ||
            (trainTargeting?.isTargetingAllLirrTrains &&
              activeStage ===
                CampaignTargetingFormStages.TRAIN_UPLOAD_CONTENT_SELECTION) ||
            !!allContextualTargetsByType.routes.length ||
            allContextualTargetsByType.stops.length > 0) &&
          layouts?.length &&
          positionsAndDimensions.every(
            ({ setIndex }) => !!uploads?.[setIndex],
          ) &&
          !selectors.some((s) => s.routes.some((r) => r.hasAllStopsUnselected))
        );
      },
    ),
  ].every(Boolean);

  const [errors, setErrors] = useState<MessageError>({
    campaign: {},
    screens: [],
  });

  const durationsSatusBannerIsVisible =
    surfaceErrors && !!errors.campaign.durationsSource;
  const titleStatusBannerIsVisible = surfaceErrors && !!errors.campaign.title;

  return children({
    isValid,
    makeSubmitHandler: (fn: (fv: FormValues) => any) => async (evt: any) => {
      if (evt) {
        evt.preventDefault();
      }

      if (isSubmitting) {
        return;
      }

      if (!isValid) {
        const newErrors: ICampaignError = {};
        const targetingErrors: IScreenTargetingError[] = [];

        if (!title.length) {
          newErrors.title = CAMPAIGN_TITLE_REQUIRED;
        }

        if (durationsSourceError) {
          newErrors.durationsSource = durationsSourceError.message;
        }

        targeting.forEach(
          (
            { activeStage, layouts, screenSelectors, trainTargeting, uploads },
            index,
          ) => {
            const selectors = screenSelectors.flatMap(
              ({ selector }) => selector,
            );
            targetingErrors[index] = {};

            if (
              activeStage ===
              CampaignTargetingFormStages.TARGETING_TYPE_SELECTION
            ) {
              targetingErrors[index].targetingTypeSelection =
                TARGETING_TYPE_REQUIRED;
            }

            const hasSelectorsWithNoStops = selectors.some((s) =>
              s.routes.some((r) => r.hasAllStopsUnselected),
            );
            const hasSelectorsWithNoRoutes = selectors.some(
              (s) => !s.routes.length,
            );

            if (
              (trainTargeting ||
                activeStage ===
                  CampaignTargetingFormStages.TRAIN_SCREEN_SELECTION) &&
              !trainTargeting?.shops?.length &&
              !trainTargeting?.lines?.length
            ) {
              if (feedId === FeedId.MNR) {
                targetingErrors[index].selectors = CAMPAIGN_MNR_LINES_REQUIRED;
              } else {
                targetingErrors[index].selectors = CAMPAIGN_SHOPS_REQUIRED;
              }
            } else if (!trainTargeting) {
              if (hasSelectorsWithNoRoutes) {
                targetingErrors[index].selectors =
                  CAMPAIGN_STATIONS_SCREENS_REQUIRED;
              } else if (hasSelectorsWithNoStops) {
                targetingErrors[index].selectors = STOP_SELECTOR_MISSING_STOPS;
              }
            }

            const positionsAndDimensions = getPositionalScreensWithDimensions(
              layouts?.[0] ?? null,
            );

            const isValidUploads =
              layouts?.length &&
              positionsAndDimensions.every(
                ({ setIndex }) => !!uploads?.[setIndex],
              );

            if (!isValidUploads) {
              if (
                trainTargeting &&
                new Set([FeedId.LIRR, FeedId.MNR]).has(feedId)
              ) {
                targetingErrors[index].uploadsAndLayouts =
                  CAMPAIGN_LAYOUTS_UPLOADS_REQUIRED_NO_LAYOUT_SELECTION;
              } else {
                targetingErrors[index].uploadsAndLayouts =
                  CAMPAIGN_LAYOUTS_UPLOADS_REQUIRED;
              }
            }
          },
        );

        setErrors({
          campaign: newErrors,
          screens: targetingErrors,
        });

        const allErrors = (targetingErrors ?? []).reduce((acc, cur, idx) => {
          const screenAtIdxErrors: { [key: string]: string } = {};

          Object.keys(cur).forEach((key) => {
            const errorValue = cur[key as IScreenTargetingErrorKey];

            if (errorValue) {
              screenAtIdxErrors[getScreenTargetingFieldKey(key, idx)] =
                errorValue;
            }
          });

          return { ...acc, ...screenAtIdxErrors };
        }, newErrors ?? {}) as { [key: string]: string };

        setSurfaceErrors(true);
        scrollToErrors(allErrors);

        return;
      }

      setIsSubmitting(true);
      const formValues: FormValues = {
        feedId,
        title,
        durationsSource: sanitizeDurationsSource(durationsSource),
        targeting: targeting.map<CampaignTargetingInput>(
          ({
            id,
            layouts,
            trainTargeting,
            screenSelectors,
            weightFrequency,
            uploads,
            screenName,
          }: any) => {
            const layoutUploadsCount = getPositionalScreensWithDimensions(
              layouts?.[0] ?? null,
            ).length;

            return {
              id,
              targetTypes: layouts,
              screenSelectors: (() => {
                if (
                  trainTargeting?.shops?.length ||
                  trainTargeting?.lines?.length
                ) {
                  return {
                    selectors: getSelectorsFromTrainTargeting({
                      shops: trainTargeting?.shops ?? [],
                      lines: trainTargeting?.lines ?? [],
                    }),
                    tags: undefined,
                  };
                }

                if (trainTargeting?.isTargetingAllLirrTrains) {
                  return {
                    selectors: allRoutes.map((route) =>
                      getSelectorFromRouteId(route.gtfsId),
                    ),
                    tags: undefined,
                  };
                }

                return screenSelectors.map(({ selector, tags }) => {
                  const allContextualTargetsByType =
                    getContextualSelectorsByType([selector]);
                  return {
                    selectors: [
                      ...allContextualTargetsByType.routes,
                      ...allContextualTargetsByType.stops,
                    ],
                    tags,
                  };
                });
              })() as CampaignScreenSelectorInput[],
              trainTargeting,
              priority: weightFrequency.priority,
              weight: layouts.some((l) => l.includes(TargetType.DUP))
                ? 1
                : weightFrequency.weight,
              uploads: Object.entries(uploads as { [k: string]: UploadType })
                .slice(0, layoutUploadsCount)
                .map(([initialSetIndex, upload]: [string, UploadType]) => {
                  let setIndex = parseInt(initialSetIndex, 10);

                  if (Number.isNaN(setIndex)) {
                    setIndex = -1;
                  }

                  return { setIndex, uploadId: upload.id };
                }),
              screenName,
            };
          },
        ),
      };

      if (
        formValues.durationsSource?.directDurations &&
        !confirmationModalOptions.confirmedExpiredDates
      ) {
        const expired = areAnyDatesInPast(
          formValues.durationsSource.directDurations.map((v) => v.end?.value),
        );
        if (expired) {
          setIsSubmitting(false);
          setConfirmationModalOptions({
            confirmedExpiredDates: false,
            isOpen: true,
            onConfirm: () => {
              setConfirmationModalOptions({
                confirmedExpiredDates: true,
                isOpen: false,
                onConfirm: () => {},
              });

              fn(formValues);
            },
          });
          return;
        }
      }

      await fn(formValues);

      if (isMountedRef.current) {
        setIsSubmitting(false);
      }
    },
    form: (
      <React.Fragment>
        <S.CampaignComposeContainer>
          <S.Content>
            <S.Section>
              <S.MainHeading>{`${heading}${
                isSingleScreenCampaign
                  ? ` for ${screenDetails?.actualName}`
                  : ''
              }`}</S.MainHeading>
            </S.Section>
            <S.Section>
              {isSingleScreenCampaign && !screenDetailsLoading && (
                <div
                  css={css`
                    margin-bottom: 10px;
                  `}
                >
                  {getScreenIconFromScreenDetais(screenDetails)}
                </div>
              )}
              <Label label="Internal Title">
                <S.Input
                  id={CampaignErrorKeys.title}
                  name="title"
                  type="text"
                  value={title}
                  onChange={(evt) => setTitle(evt.target.value)}
                  required
                />
              </Label>
              <StatusBanner
                status="error"
                isVisible={titleStatusBannerIsVisible}
                text={errors.campaign.title}
                css={({ spacing }: ThemeType) => css`
                  margin-top: ${titleStatusBannerIsVisible ? spacing.small : 0};
                  transition: margin-top 0.3s ease;
                `}
              />
              <div id={CampaignErrorKeys.durationsSource}>
                <S.DurationHeading>Duration</S.DurationHeading>
                <ComplexDurationsSelector
                  value={durationsSource}
                  onChange={setDurationsSource}
                  erroneousDurationIndices={
                    durationsSourceError?.indices?.[
                      DurationsSourceInputMethod.DIRECT
                    ]?.[0]
                      ? durationsSourceError?.indices?.[
                          DurationsSourceInputMethod.DIRECT
                        ]
                      : []
                  }
                  erroneousCadenceIndices={
                    durationsSourceError?.indices?.[
                      DurationsSourceInputMethod.CADENCE
                    ] || []
                  }
                />
                <StatusBanner
                  status="error"
                  isVisible={durationsSatusBannerIsVisible}
                  text={errors.campaign.durationsSource}
                  css={({ spacing }: ThemeType) => css`
                    margin-top: ${durationsSatusBannerIsVisible
                      ? spacing.small
                      : 0};
                    transition: margin-top 0.3s ease;
                  `}
                />
              </div>
            </S.Section>
            {(() => {
              if (!isSingleScreenCampaign) {
                return null;
              }
              return (
                <S.Section>
                  {screenDetailsLoading ? (
                    <div
                      css={css`
                        padding: 10px;
                      `}
                    >
                      <Loader loading={screenDetailsLoading} />
                    </div>
                  ) : (
                    <SingleScreenTargeting
                      screenDetails={screenDetails as IScreenDetails}
                      screenPosition={
                        screenDetails?.targetType === TargetType.TRIPTYCH_AD ||
                        screenDetails?.setIndex === -1
                          ? 0
                          : (screenDetails?.setIndex ?? 0)
                      }
                      targeting={targeting[0] as ISingleScreenTargeting}
                      showErrors={surfaceErrors}
                      onChange={({ uploads, weightFrequency }) => {
                        setTargeting([
                          {
                            ...targeting[0],
                            uploads,
                            weightFrequency,
                          },
                        ]);
                      }}
                    />
                  )}
                </S.Section>
              );
            })()}
          </S.Content>
          {!isSingleScreenCampaign && (
            <S.Content>
              <S.Section
                css={css`
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                `}
              >
                <S.SubHeading
                  css={css`
                    margin-right: 16px;
                  `}
                >
                  Screens
                </S.SubHeading>
              </S.Section>
              {targeting.map((t, index) => {
                const key = `screen-targeting-${index}-${t?.id}`;
                return (
                  <CampaignScreenTargeting
                    key={key}
                    targetingIndex={index}
                    targeting={t}
                    onTargetingChange={(newTargeting) => {
                      setTargeting([
                        ...targeting.slice(0, index),
                        newTargeting,
                        ...targeting.slice(index + 1),
                      ]);
                    }}
                    onTargetingDelete={() => {
                      const newTargeting = [
                        ...targeting.slice(0, index),
                        ...targeting.slice(index + 1),
                      ];

                      setTargeting(
                        newTargeting.length
                          ? newTargeting
                          : [
                              getEmptyTargeting({
                                rollingStockEnabled,
                                sidewalkTargetingEnabled,
                              }),
                            ],
                      );
                    }}
                    canDelete={
                      targeting.length > 1 ||
                      rollingStockEnabled ||
                      sidewalkTargetingEnabled
                    }
                    errors={errors.screens?.[index]}
                    forceSurfaceErrors={() => setSurfaceErrors(true)}
                  />
                );
              })}
              {targeting[0]?.activeStage !==
                CampaignTargetingFormStages.TARGETING_TYPE_SELECTION && (
                <S.Section
                  css={css`
                    display: flex;
                  `}
                >
                  <Button
                    primary
                    type="button"
                    onClick={() =>
                      addEmptyTargeting({
                        activeStage:
                          CampaignTargetingFormStages.STATION_SCREEN_SELECTION,
                      })
                    }
                    additionalStyles={css`
                      margin-right: 12px;
                    `}
                    size="small"
                  >
                    Add Station Screen
                  </Button>
                  {sidewalkTargetingEnabled && (
                    <Button
                      primary
                      type="button"
                      onClick={() =>
                        addEmptyTargeting({
                          activeStage:
                            CampaignTargetingFormStages.SIDEWALK_UPLOAD_CONTENT_SELECTION,
                          layouts: [TargetType.DUP],
                        })
                      }
                      additionalStyles={css`
                        margin-right: 12px;
                      `}
                      size="small"
                    >
                      Add Sidewalk Screen
                    </Button>
                  )}
                  {rollingStockEnabled && (
                    <Button
                      primary
                      type="button"
                      onClick={() =>
                        addEmptyTargeting(
                          getInitialPropsForTrainTargeting(feedId),
                        )
                      }
                      size="small"
                    >
                      Add Train Screen
                    </Button>
                  )}
                </S.Section>
              )}
            </S.Content>
          )}
          <ConfirmModal
            onConfirm={() => {
              return confirmationModalOptions.onConfirm();
            }}
            isOpen={confirmationModalOptions.isOpen}
            onDismiss={() => {
              setConfirmationModalOptions({
                confirmedExpiredDates: false,
                isOpen: false,
                onConfirm: () => {},
              });
            }}
            title="Warning"
            dismissText="Cancel"
            confirmText="Confirm"
          >
            {CAMPAIGN_DATES_EXPIRED}
          </ConfirmModal>
        </S.CampaignComposeContainer>
      </React.Fragment>
    ),
  });
};

export default CampaignForm;
