/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';

import { Global, css } from '@emotion/react';

import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { loader } from 'graphql.macro';

import { FeatureEnabled, FeatureFlagName } from 'generated/global-types';
import { isFeatureEnabled } from 'hooks/useFeatureFlag';
import FeatureFlags from 'components/pages/feature-flags';
import useLiveQuery from 'hooks/use-live-query';

import global from './theme/global';

import AppBar from './components/scaffolding/AppBar';

import Contexts from './Contexts';
import { ThemeType } from './theme';
import { useFeedIdInRoute } from './contexts/FeedId';
import { FeedId } from './types/feeds';
import { SIGN_OUT_PATH, SIGN_IN_PATH } from './constants/authentication';
import useAuthentication from './contexts/Authentication';
import useLocalForage from './hooks/useLocalForage';
import { RoutesProvider } from './contexts/Routes';
import { useCurrentUser } from './contexts/CurrentUser';
import { CurrentUserFragment as CurrentUserType } from 'generated/CurrentUser.fragment';
import { displayMainApp, AppError } from './utils/app-error-handler';
import AlertDashboard from './components/pages/alert-dashboard';
import AlertCompose from './components/pages/alert-compose';
import PermissionsNeeded from './components/pages/errors/PermissionsNeeded';
import AlertView from './components/pages/alert-view';
import AlertUpdate from './components/pages/alert-update';
import AlertDraft from './components/pages/alert-draft';
import ComposePlannedWork from './components/pages/planned-work/compose';
import PlannedWorkDashboard from './components/pages/planned-work/Dashboard';
import PlannedWorkCleared from './components/pages/planned-work/Cleared';
import PlannedWorkDraft from './components/pages/planned-work/Draft';
import PlannedWorkSingle from './components/pages/planned-work/Single';
import Screens from './components/pages/Screens';
import Screen from './components/pages/Screen';
import FallbackScreens from './components/pages/fallback-screens';
import ScreensStop from './components/pages/ScreensStop';
import LockedAssets from './components/pages/LockedAssets';
import AssetCompose from './components/pages/asset-compose';
import AssetView from './components/pages/asset-view';
import AssetEdit from './components/pages/asset-edit';
import Login from './components/pages/Login';
import LiveContentDashboard from './components/pages/live-content';
import AlertCleared from './components/pages/alert-cleared';
import GeneratedLogs from './components/pages/alert-generated-logs';
import Users from './components/pages/Users';
import SSO from './components/pages/SSO';
import CampaignCompose from './components/pages/campaign-compose';
import CampaignCleared from './components/pages/campaign-cleared';
import CampaignDashboard from './components/pages/campaign-dashboard';
import CampaignView from './components/pages/campaign-view';
import CampaignEdit from './components/pages/campaign-edit';
import NotFoundPage from './components/common/not-found';
import StaleBanner from './components/common/stale-banner';
import SocialMediaAssets from './components/pages/social-media-assets';
import FolderView from './components/pages/folder-view';
import { TopKeyboardFocusElement } from './components/scaffolding/scroll-to-top';
import { MaintenanceMode } from './generated/MaintenanceMode';
import PreloadEditorIcons from './theme/preload-editor-icons';

import { SETTINGS_PAGE_URL } from './constants/page-urls';
import '@reach/dialog/styles.css';
import { __DEV__ } from '@apollo/client/utilities/globals';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

const JobsDashboard = lazy(() => import('./components/pages/jobs-dashboard'));
const MaintenanceModeQuery = loader('./graphql/MaintenanceMode.gql');

const { REACT_APP_DEPLOY_ENV } = process.env;

if (__DEV__) {
  loadDevMessages();
  loadErrorMessages();
}

const defaultLocation = (
  user: CurrentUserType | null,
  feed: FeedId | 'inactive',
): string => {
  if (!user || feed === 'inactive') {
    return 'inactive';
  }

  if (isFeatureEnabled(user, FeatureFlagName.SERVICE_ALERTS, feed)) {
    return feed;
  }

  if (isFeatureEnabled(user, FeatureFlagName.CAMPAIGNS, feed)) {
    return `${feed}/campaigns`;
  }

  if (isFeatureEnabled(user, FeatureFlagName.PLANNED_WORK, feed)) {
    return `${feed}/planned-work`;
  }

  return 'inactive';
};

const feedSortOrder: FeedId[] = [
  FeedId.NYCTSubway,
  FeedId.NYCTBus,
  FeedId.LIRR,
  FeedId.MNR,
  FeedId.BT,
];

const AppContainer: React.FC<{
  navOpen?: boolean;
  children?: React.ReactNode;
}> = ({ navOpen, ...rest }) => {
  const prevOpen = useRef(navOpen);
  const [transition, setTransition] = useState(false);
  /**
   * We don't want to transition the nav open initially, only after the initial
   * state has been set. This checks for the initial state then allows transitions for
   * user interactions
   */
  useEffect(() => {
    if (
      typeof prevOpen.current === 'undefined' &&
      typeof navOpen !== 'undefined'
    ) {
      window.setTimeout(() => {
        setTransition(true);
      }, 50);
    }
  }, [navOpen, prevOpen]);

  return (
    <div
      className={`${navOpen ? 'nav-open' : ''}${
        transition ? ' nav-transition' : ''
      }`}
      css={(theme: ThemeType) => css`
        background-color: ${theme.colors.light1};
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
      `}
      {...rest}
    />
  );
};

const InactiveUserHandler = ({ children }: { children: any }) => {
  const feedIdInRoute = useFeedIdInRoute();
  const currentUser = useCurrentUser();

  const matchSettingsPage = useRouteMatch({
    path: `/${SETTINGS_PAGE_URL}`,
    exact: true,
  });

  if (!currentUser) {
    return null;
  }
  if (!currentUser.access?.isActive) {
    return <PermissionsNeeded />;
  }
  if (!currentUser.feeds.nodes.length) {
    return <PermissionsNeeded />;
  }
  if (matchSettingsPage && !currentUser?.access?.isAdmin) {
    return <PermissionsNeeded />;
  }

  if (feedIdInRoute) {
    const allowedFeedIds =
      (currentUser.feeds.nodes?.flatMap(
        (userGroupGrantNode) =>
          userGroupGrantNode.feedGroupByFeedGroupName?.feedIds,
      ) as FeedId[]) || [];

    const isFeedAllowed = allowedFeedIds.some(
      (allowedFeedId) => allowedFeedId === feedIdInRoute,
    );

    if (!isFeedAllowed) {
      return <PermissionsNeeded />;
    }
  }

  return children;
};

const PrivateRoutes: React.FC<any> = ({ children, ...rest }) => {
  const { session, isFetching } = useAuthentication();

  if (isFetching) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        session.isAuthenticated ? (
          <InactiveUserHandler>{children}</InactiveUserHandler>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const FlaggedRoutes: React.FC<{
  path: string;
  features: FeatureFlagName[];
  children?: React.ReactNode;
}> = ({ path, features, children }) => {
  const currentUser = useCurrentUser();
  const feedIdInRoute = useFeedIdInRoute();

  const enabled =
    currentUser && feedIdInRoute
      ? features.every((f) => isFeatureEnabled(currentUser, f, feedIdInRoute))
      : false;

  return (
    <Route path={path} render={() => enabled && <Switch>{children}</Switch>} />
  );
};

const PageContainer: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <main
    css={css`
      justify-content: flex-start;
      display: flex;
      width: 100%;
      height: 100%;
      padding-left: 54px;
      box-sizing: border-box;

      .nav-open & {
        padding-left: 240px;
      }

      .nav-transition & {
        transition: padding 0.3s ease-out;
      }
    `}
    {...props}
  />
);

const App: React.FC<{ children?: React.ReactNode }> = () => {
  const [navOpen, setNavOpen] = useLocalForage<boolean>('navOpen');

  const { session } = useAuthentication();

  const currentUser = useCurrentUser();
  const allowedFeedIds =
    (currentUser?.feeds.nodes
      ?.flatMap(
        (userGroupGrantNode) =>
          userGroupGrantNode.feedGroupByFeedGroupName?.feedIds,
      )
      .sort(
        (a, b) =>
          feedSortOrder.indexOf(a as FeedId) -
          feedSortOrder.indexOf(b as FeedId),
      ) as FeedId[]) || [];
  const feedIds = Object.values(allowedFeedIds).join('|');
  const maintenanceMode = useLiveQuery<MaintenanceMode>(
    MaintenanceModeQuery,
    {},
  );
  const isUnderMaintenance = !!maintenanceMode?.data?.featureFlags?.nodes?.find(
    (f) => f.enabled === FeatureEnabled.ON,
  );

  // TODO: Remember user's default (or last accessed) feed?
  const initialFeed =
    currentUser?.access?.isActive && allowedFeedIds[0]
      ? allowedFeedIds[0]
      : 'inactive';
  // TODO: Do we do some sort of feedId loading/validation at the app level?
  // Perhaps alongside JWT auth?

  const initialPath = defaultLocation(currentUser, initialFeed);
  const navIsOpen = navOpen && session.isAuthenticated;
  return (
    <Contexts allowedFeedIds={allowedFeedIds}>
      <div
        css={css`
          height: 100%;
        `}
      >
        <Global styles={global} />
        <StaleBanner navOpen={navIsOpen}>
          <AppContainer navOpen={navIsOpen}>
            {!isUnderMaintenance && displayMainApp ? (
              <React.Fragment>
                {currentUser && <PreloadEditorIcons />}
                <TopKeyboardFocusElement />
                <AppBar
                  env={REACT_APP_DEPLOY_ENV}
                  user={currentUser}
                  userFeeds={allowedFeedIds}
                  isOpen={navIsOpen || false}
                  onToggleOpen={() => setNavOpen(!navOpen)}
                />
                <PageContainer>
                  <Switch>
                    {currentUser &&
                      initialFeed !== 'inactive' &&
                      initialPath !== 'inactive' && (
                        <Redirect exact from="/inactive" to={initialPath} />
                      )}
                    <Redirect
                      exact
                      from="/under-maintenance"
                      to={initialPath}
                    />
                    <Route path="/inactive" component={PermissionsNeeded} />
                    <Route path="/login" component={Login} />
                    <Route path={SIGN_IN_PATH} component={SSO} />
                    <Route path={SIGN_OUT_PATH} component={SSO} />
                    <PrivateRoutes>
                      <RoutesProvider>
                        <Switch>
                          <Redirect exact from="/" to={initialPath} />
                          <FlaggedRoutes
                            features={[FeatureFlagName.ASSETS]}
                            path={`/:feedId(${feedIds})/assets/locked`}
                          >
                            <Route
                              path={`/:feedId(${feedIds})/assets/locked`}
                              exact
                              component={LockedAssets}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.ASSETS]}
                            path={`/:feedId(${feedIds})/folders`}
                          >
                            <Route
                              path={`/:feedId(${feedIds})/folders/:id`}
                              exact
                              component={FolderView}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.ASSETS]}
                            path={`/:feedId(${feedIds})/assets/social-media`}
                          >
                            <Route
                              path={`/:feedId(${feedIds})/assets/social-media`}
                              exact
                              component={SocialMediaAssets}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.ASSETS_CRUD]}
                            path={`/:feedId(${feedIds})/assets`}
                          >
                            <Route
                              path={`/:feedId(${feedIds})/assets/compose`}
                              exact
                              component={AssetCompose}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/assets/:id`}
                              exact
                              component={AssetView}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/assets/:id/edit`}
                              exact
                              component={AssetEdit}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            path={`/:feedId(${feedIds})/campaigns`}
                            features={[
                              FeatureFlagName.CAMPAIGNS,
                              FeatureFlagName.SCREENS_UI,
                            ]}
                          >
                            <Route
                              path={`/:feedId(${feedIds})/campaigns`}
                              exact
                              component={CampaignDashboard}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/campaigns/compose`}
                              exact
                              component={CampaignCompose}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/campaigns/cleared`}
                              exact
                              component={CampaignCleared}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/campaigns/:campaignId/edit`}
                              exact
                              component={CampaignEdit}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/campaigns/:campaignId`}
                              exact
                              component={CampaignView}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/campaigns/:status?`}
                              exact
                              component={CampaignDashboard}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.WEIGHTING_FREQUENCY]}
                            path={`/:feedId(${feedIds})/screens/live`}
                          >
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/screens/live`}
                              component={LiveContentDashboard}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.SCREENS_UI]}
                            path={`/:feedId(${feedIds})/screens`}
                          >
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/screens`}
                              component={Screens}
                            />
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/screens/fallback`}
                              component={FallbackScreens}
                            />
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/screens/:id`}
                              component={Screen}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.SCREENS_UI]}
                            path={`/:feedId(${feedIds})/stops/:stopId/screens`}
                          >
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/stops/:stopId/screens`}
                              component={ScreensStop}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.PLANNED_WORK]}
                            path={`/:feedId(${feedIds})/planned-work`}
                          >
                            <Route
                              path={`/:feedId(${feedIds})/planned-work`}
                              exact
                              component={PlannedWorkDashboard}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/planned-work/compose`}
                              exact
                              component={ComposePlannedWork}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/planned-work/cleared`}
                              exact
                              component={PlannedWorkCleared}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/planned-work/draft`}
                              exact
                              component={PlannedWorkDraft}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/planned-work/:id`}
                              exact
                              component={PlannedWorkSingle}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/planned-work/:id/edit`}
                              exact
                              component={ComposePlannedWork}
                            />
                          </FlaggedRoutes>
                          <FlaggedRoutes
                            features={[FeatureFlagName.SERVICE_ALERTS]}
                            path={`/:feedId(${feedIds})`}
                          >
                            <Route
                              path={`/:feedId(${feedIds})`}
                              component={AlertDashboard}
                              exact
                            />
                            <Route
                              path={`/:feedId(${feedIds})/compose`}
                              component={AlertCompose}
                            />
                            <Route
                              path={[
                                `/:feedId(${feedIds})/events/:id`,
                                `/:feedId(${feedIds})/events/:id/alerts/:alertId`,
                              ]}
                              exact
                              component={AlertView}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/events/:id/update`}
                              component={AlertUpdate}
                            />
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/alerts/cleared`}
                              component={AlertCleared}
                            />
                            <Route
                              exact
                              path={`/:feedId(${feedIds})/alerts/draft`}
                              component={AlertDraft}
                            />
                            <Route
                              path={`/:feedId(${feedIds})/alerts/cleared/history`}
                              component={GeneratedLogs}
                            />
                          </FlaggedRoutes>
                          <Route
                            exact
                            path={['/users', '/users/page/:page(\\d+)']}
                          >
                            <Users currentUser={currentUser} />
                          </Route>
                          {currentUser?.access?.isAdmin && (
                            <Suspense fallback={<h1>Loading…</h1>}>
                              <Route exact path="/internal/jobs">
                                <JobsDashboard />
                              </Route>
                              <Route
                                exact
                                path="/feature-flags"
                                component={FeatureFlags}
                              />
                            </Suspense>
                          )}
                          <Route path="*" component={NotFoundPage} />
                        </Switch>
                      </RoutesProvider>
                    </PrivateRoutes>
                  </Switch>
                </PageContainer>
              </React.Fragment>
            ) : (
              <AppError isUnderMaintenance={isUnderMaintenance} />
            )}
          </AppContainer>
        </StaleBanner>
      </div>
    </Contexts>
  );
};

export default App;
