/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { EditorState } from 'remirror';
import _isEqual from 'lodash/isEqual';
import { formatMessageDoc } from '@mta-live-media-manager/shared';
import TextareaAutosize from 'react-textarea-autosize';
import { useDebouncedCallback } from 'use-debounce';

import ToggleSection from '../../../ui-kit/toggle-section/toggle-section';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  constructPlainTextEmailBody,
  constructSMS,
  useGeneratedEmailSubject,
} from '../../../hooks';
import { useFeedId } from '../../../contexts/FeedId';
import { EMAIL_ICON_WARNING_MESSAGE } from '../../../constants/alerts';
import {
  EMAIL_SUBJECT_REQUIRED,
  EMAIL_BODY_REQUIRED,
} from '../../../constants/error-messages';
import { TImpact } from '../../../types';

import { EditableTextPreview } from '../../../ui-kit/editable-text-preview/editable-text-preview';
import HtmlPreview from '../../../ui-kit/html-preview/html-preview';
import { AlertFormSectionWrapper, AlertFormFieldSpacer } from './styles';
import { input as inputCss } from '../styles';
import StatusBanner from '../status-banner';
import InputLabel from '../form-elements/input-label';
import Editor from '../form-elements/editor';
import { mergeMessages } from '../form-elements/editor/mirror-listener';
import { FeatureFlagName } from 'generated/global-types';

const DEBOUNCE_ON_CHANGE_MS = 300;
const UPDATE_PREFIX = 'Update: ';
const defaultSubjectForPreviousSubject = (previousSubject: string): string =>
  previousSubject.startsWith(UPDATE_PREFIX)
    ? previousSubject
    : `${UPDATE_PREFIX}${previousSubject}`;
const SUBJECT_MAX_LENGTH = 100;
const SMS_MAX_LENGTH = 248;

interface AlertEmailAndSubjectProps {
  additionalMessageName: string;
  emailBodyName: string;
  smsName: string;
  messageName: string;
  webImpactsName: string;
  onToggle: (enabled: boolean) => void;
  show: boolean;
  subjectName: string;
  isDuplicate?: boolean;
  prevEmailSubject?: string;
  prevEmailText?: string;
  prevSmsBody?: string | null;
  prevMessageImpacts?: TImpact[];
  title?: string;
  initBody?: any;
  initSms?: string;
  initSubject?: string;
}

const findMarkIds = (content: any[]): string[] => {
  const ids: string[] = [];

  content.forEach((node) => {
    if (node.marks) {
      ids.push(
        ...(node.marks as any[])
          .filter((mark) => mark.attrs && mark.attrs['data-bullet-id'])
          .map((mark) => mark.attrs['data-bullet-id']),
      );
    }
    if (node.content) {
      ids.push(...findMarkIds(node.content));
    }
  });

  return ids;
};

const TROUBLESOME_ICONS = ['ICON:SHUTTLE_BUS', 'ICON:ADA'];

const hasTroublesomeIcons = (content: any[]): boolean => {
  const ids = findMarkIds(content);
  return ids.some((id) => TROUBLESOME_ICONS.includes(id));
};

export const AlertEmailAndSubject: React.FC<AlertEmailAndSubjectProps> = ({
  additionalMessageName,
  emailBodyName,
  messageName,
  smsName,
  subjectName,
  webImpactsName,
  title = 'Email & SMS',
  isDuplicate = false,
  prevEmailText,
  prevEmailSubject,
  prevSmsBody,
  prevMessageImpacts,
  onToggle,
  show,
  initBody,
  initSubject,
  initSms,
}) => {
  const feedId = useFeedId();
  const { register, watch, setValue, clearError, errors } = useFormContext();
  const emailSubject = watch(subjectName);
  const webImpacts = watch(webImpactsName) as TImpact[];

  const isEditableSmsEnabled = useFeatureFlag(FeatureFlagName.EDITABLE_SMS);

  const isWebImpactsChanged =
    !prevMessageImpacts || !_isEqual(webImpacts, prevMessageImpacts);

  const [debouncedEmailBodyUpdate] = useDebouncedCallback(
    (value: EditorState) => {
      setValue(emailBodyName, value, true);
    },
    DEBOUNCE_ON_CHANGE_MS,
  );

  const [debouncedSubjectUpdate] = useDebouncedCallback((value: string) => {
    setValue(subjectName, value, true);
  }, DEBOUNCE_ON_CHANGE_MS);

  const [debouncedSmsUpdate] = useDebouncedCallback((value: string) => {
    setValue(smsName, value, true);
  }, DEBOUNCE_ON_CHANGE_MS);

  const [isEmailTextCustomized, setIsEmailTextCustomized] =
    useState(isDuplicate);
  const [isSmsCustomized, setIsSmsCustomized] = useState(isDuplicate);
  const [isSubjectCustomized, setIsSubjectCustomized] = useState(isDuplicate);
  const message = watch(messageName) as EditorState | undefined;
  const additionalMessage = watch(additionalMessageName) as
    | EditorState
    | undefined;
  const smsText = useMemo(() => {
    return constructSMS(feedId, message);
  }, [message, feedId]);
  const generatedSubject = useGeneratedEmailSubject();
  const updateDefaultSubject = prevEmailSubject
    ? defaultSubjectForPreviousSubject(prevEmailSubject)
    : '';
  const updateGeneratedSubject = prevEmailSubject
    ? defaultSubjectForPreviousSubject(generatedSubject)
    : '';
  const defaultSubject = (() => {
    if (!prevEmailSubject) {
      return generatedSubject;
    }
    if (isWebImpactsChanged) {
      return updateGeneratedSubject;
    }
    return updateDefaultSubject;
  })();
  const subjectError = errors[subjectName];
  const emailBodyError = errors[emailBodyName];
  const smsError = errors[smsName];

  const [localEmailBody, setLocalEmailBody] = useState<
    EditorState | undefined
  >();
  const [localEmailSubject, setLocalEmailSubject] = useState(
    emailSubject || defaultSubject,
  );
  const [localSms, setLocalSms] = useState('');
  const messageHasInitialized = useRef(false);
  const messageLoadedOnce = useRef(false);

  useEffect(() => {
    /**
     * If this is a duplicate alert, we need to examine the value in the
     * emailBody form field and compare it to the message/additionalMessage
     * combination (read as, headline and details, respectively, to
     * determine whether the email message has been customized
     * or not. If it has not been customized, then the emailBody should
     * continue to mirror the contents of head and details.
     *
     * Message and additionalMessage, which are stored with useForm, do not
     * show up on the initial renders. Thus, we must wait until they are both
     * either defined or null to  make our final comparison.
     *
     * The final complication is inital value of emailBody, which is derived
     * initBody, which itself was derived from either email_body (a string) or
     * email_body_meta (a json) of the original alert, depending on whether the
     * original alert was created before or after we introduced styled email.
     *
     * If initBody was a string, we compare emailBody to the expected
     * plain-text fo the email body that, using constructBody, would have been
     * created prior to styled email.
     *
     * If initBody was a JSON, indicating rich text, then we compare it to the rich
     * email that would be generated by the initial message and additionalMessage.
     */

    if (
      isDuplicate &&
      !messageHasInitialized.current &&
      initBody &&
      message &&
      (additionalMessage || additionalMessage === null)
    ) {
      const legacyFormattedMessageText = constructPlainTextEmailBody(
        feedId,
        message,
        additionalMessage,
      );
      const formmattedMessageText = mergeMessages([
        message,
        ...(additionalMessage ? [additionalMessage] : []),
      ]);

      setIsEmailTextCustomized(
        typeof initBody === 'string'
          ? initBody !== legacyFormattedMessageText
          : initBody?.html !== formmattedMessageText,
      );

      setIsSmsCustomized(initSms === constructSMS(feedId, message as any));

      messageHasInitialized.current = true;
    }
  }, [message, additionalMessage, isDuplicate, feedId, initBody, initSms]);

  useEffect(() => {
    register(
      {
        name: subjectName,
      },
      {
        required: show && EMAIL_SUBJECT_REQUIRED,
        maxLength: show ? SUBJECT_MAX_LENGTH : Number.MAX_SAFE_INTEGER,
      },
    );
  }, [subjectName, register, show]);

  useEffect(() => {
    register(
      {
        name: emailBodyName,
      },
      {
        validate: (value) => {
          // check types here.
          const emailBodyString = formatMessageDoc(value.doc, {
            bracketCustomIcons: true,
          });
          return (
            !show || emailBodyString.trim().length > 0 || EMAIL_BODY_REQUIRED
          );
        },
      },
    );
  }, [emailBodyName, register, show]);

  useEffect(() => {
    register(
      {
        name: smsName,
      },
      {
        required: show,
        maxLength: show ? SMS_MAX_LENGTH : Number.MAX_SAFE_INTEGER,
      },
    );
  });

  useEffect(() => {
    if (!isSubjectCustomized && !!defaultSubject) {
      setLocalEmailSubject(defaultSubject);
      setValue(subjectName, defaultSubject, !!subjectError);
    }
  }, [
    subjectName,
    setValue,
    defaultSubject,
    isSubjectCustomized,
    subjectError,
    feedId,
  ]);

  useEffect(() => {
    if (
      isDuplicate &&
      isSubjectCustomized &&
      generatedSubject === initSubject?.replace(UPDATE_PREFIX, '')
    ) {
      setIsSubjectCustomized(false);
    }
  }, [isDuplicate, generatedSubject, isSubjectCustomized, initSubject]);

  useEffect(() => {
    if (
      !isSmsCustomized ||
      (!messageLoadedOnce.current && (initSms || smsText))
    ) {
      const localSmsText = (initSms || smsText).replace(/\\n/g, '\n');
      setLocalSms(localSmsText);
      setValue(smsName, localSmsText, !!smsError);
      messageLoadedOnce.current = true;
    }
  }, [smsName, smsText, initSms, isSmsCustomized, setValue, smsError]);

  const showEmailBodyError =
    !!isEmailTextCustomized && !!emailBodyError?.message;

  const showTroublesomeIconError =
    Boolean(localEmailBody?.doc?.toJSON()?.content) &&
    hasTroublesomeIcons(localEmailBody?.doc?.toJSON()?.content);

  return (
    <AlertFormSectionWrapper isGrayscale>
      <ToggleSection
        id="email-and-subject"
        enabled={show}
        title={title}
        onToggle={(enabled) => {
          onToggle(enabled);

          if (!enabled) {
            clearError(subjectName);
            clearError(emailBodyName);
          }
        }}
      >
        <div
          css={css`
            padding-top: 32px;
          `}
        />
        {prevEmailText && (
          <React.Fragment>
            <HtmlPreview title="Previous Message" html={prevEmailText} />
            <AlertFormFieldSpacer />
          </React.Fragment>
        )}
        <InputLabel
          htmlFor={subjectName}
          label="Email Subject Line"
          invalid={!!subjectError}
          height={44}
          maxLength={{
            max: SUBJECT_MAX_LENGTH,
            current: localEmailSubject ? localEmailSubject.length : 0,
          }}
          inputIsMultiLine
        >
          <TextareaAutosize
            id={subjectName}
            disabled={!show}
            value={localEmailSubject}
            onChange={(e) => {
              // prevent input of line breaks
              const value = e.target.value.replace(/[\r\n\v]+/g, '');

              setIsSubjectCustomized(true);
              setLocalEmailSubject(value);
              debouncedSubjectUpdate(value);
            }}
            css={inputCss}
          />
        </InputLabel>
        <StatusBanner
          as="label"
          htmlFor="email-subject-input"
          status="error"
          hasIcon
          text={subjectError?.message || '\u00a0'}
          isVisible={!!subjectError?.message}
          css={css`
            margin-top: 12px;
          `}
        />
        <AlertFormFieldSpacer />
        <div
          onKeyDown={() => {
            setIsEmailTextCustomized(true);
          }}
        >
          <Editor
            id={emailBodyName}
            className="email-editor"
            showDetailMenu
            shouldFormatOnPaste
            labelText="Message"
            richEditing
            richEditingMenu
            showSelectionControls
            value={localEmailBody}
            onStateChange={({ state }) => {
              setLocalEmailBody(state);
            }}
            onChange={({ state }) => {
              debouncedEmailBodyUpdate(state);
            }}
            initialContent={
              typeof initBody === 'string'
                ? initBody.replace(/[\r\n\v]+/g, '<br />') // Legacy plain text
                : initBody?.doc // Rich text
            }
            shouldMirror={!isEmailTextCustomized}
            mirrorState={
              [message, additionalMessage].filter(
                (m) => m !== undefined,
              ) as EditorState[]
            }
          />
        </div>
        <StatusBanner
          as="label"
          htmlFor={emailBodyName}
          status="warning"
          hasIcon
          isVisible={showTroublesomeIconError}
          text={EMAIL_ICON_WARNING_MESSAGE}
          css={css`
            margin-top: 12px;
          `}
        />

        <StatusBanner
          as="label"
          htmlFor="email-body-input"
          status="error"
          hasIcon
          text={emailBodyError?.message || '\u00a0'}
          isVisible={showEmailBodyError}
          css={css`
            margin-top: 12px;
          `}
        />
        <AlertFormFieldSpacer />
        {prevSmsBody && (
          <HtmlPreview
            isSingleLine
            className="html-preview"
            title="Previous SMS message"
            html={prevSmsBody.replace(/(\\n)|[\r\n\v]+/g, '<br />')}
            css={css`
              margin-bottom: 12px;
            `}
          />
        )}
        {isEditableSmsEnabled ? (
          <EditableTextPreview
            id={smsName}
            isLocked={!isSmsCustomized}
            invalid={Boolean(smsError)}
            title="SMS Message"
            value={localSms}
            onUnlock={() => {
              setIsSmsCustomized(true);
            }}
            onChange={(e) => {
              const { value } = e.target;
              setLocalSms(value);
              debouncedSmsUpdate(value);
            }}
            maxLength={{
              current: localSms.length || 0,
              max: SMS_MAX_LENGTH,
            }}
          />
        ) : (
          <HtmlPreview
            hasColor
            isSingleLine
            className="html-preview"
            title="SMS message"
            html={smsText || '<span>&nbsp;</span>'}
            subTitle="SMS messages reflect the Message Headline exactly."
          />
        )}
      </ToggleSection>
    </AlertFormSectionWrapper>
  );
};
