/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { ORDERED_ROUTES } from '@mta-live-media-manager/shared';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { FeatureFlagName } from 'generated/global-types';
import Toggle from '../Toggle';
import Button from '../../Button';
import HR from '../../HR';
import { FeedId, useFeedId, FEEDS } from '../../../../contexts/FeedId';
import { useRoutes } from '../../../../contexts/Routes';
import { routeToRouteMention } from '../../../../utils/route-mentions';
import { RouteMention } from '../../../../types';

type IQuickSelectOption = {
  label: string;
  value: string[];
  isAllRoutesOption?: boolean;
};

export type IOnChangeQuickSelect = (
  value: RouteMention[],
  isAgencyWide?: boolean,
) => void;

const QUICK_SELECT_OPTIONS: {
  [key: string]: IQuickSelectOption[];
} = {
  [FeedId.NYCTSubway]: [
    {
      label: 'All Lines',
      value: ORDERED_ROUTES.filter((r) => r.startsWith('MTASBWY:')),
      isAllRoutesOption: true,
    },
    {
      label: 'A Division',
      value: [
        'MTASBWY:1',
        'MTASBWY:2',
        'MTASBWY:3',
        'MTASBWY:4',
        'MTASBWY:5',
        'MTASBWY:6',
        'MTASBWY:6X',
        'MTASBWY:7',
        'MTASBWY:GS',
      ],
    },
    {
      label: 'B-1 Division',
      value: [
        'MTASBWY:J',
        'MTASBWY:Z',
        'MTASBWY:L',
        'MTASBWY:N',
        'MTASBWY:Q',
        'MTASBWY:R',
        'MTASBWY:W',
        'MTASBWY:FS',
        'MTASBWY:7X',
      ],
    },
    {
      label: 'B-2 Division',
      value: [
        'MTASBWY:A',
        'MTASBWY:C',
        'MTASBWY:E',
        'MTASBWY:B',
        'MTASBWY:D',
        'MTASBWY:F',
        'MTASBWY:M',
        'MTASBWY:G',
        'MTASBWY:H',
      ],
    },
  ],
  [FeedId.LIRR]: [
    {
      label: 'Lines via Jamaica',
      value: [
        'LI:1',
        'LI:2',
        'LI:3',
        'LI:4',
        'LI:5',
        'LI:6',
        'LI:7',
        'LI:8',
        'LI:10',
        'LI:12',
      ],
    },
  ],
  [FeedId.NYCTBus]: [
    {
      label: 'Verrazzano Bridge (Peak)',
      value: [
        'MTA:SIM1',
        'MTA:SIM2',
        'MTA:SIM3',
        'MTA:SIM4',
        'MTA:SIM4X',
        'MTA:SIM5',
        'MTA:SIM6',
        'MTA:SIM7',
        'MTA:SIM9',
        'MTA:SIM10',
        'MTA:SIM11',
        'MTA:SIM15',
        'MTA:SIM31',
        'MTA:SIM32',
        'MTA:SIM33',
        'MTA:SIM34',
        'MTA:SIM35',
      ],
    },
    {
      label: 'Verrazzano Bridge (Off-Peak)',
      value: ['MTA:SIM1C', 'MTA:SIM3C', 'MTA:SIM4C', 'MTA:SIM33C'],
    },
    {
      label: 'SIM via Jersey',
      value: [
        'MTA:SIM8',
        'MTA:SIM8X',
        'MTA:SIM22',
        'MTA:SIM23',
        'MTA:SIM24',
        'MTA:SIM25',
        'MTA:SIM26',
        'MTA:SIM30',
      ],
    },
    {
      label: 'Hugh Carey Tunnel (Peak)',
      value: [
        'MTA:SIM1',
        'MTA:SIM2',
        'MTA:SIM3',
        'MTA:SIM4',
        'MTA:SIM4X',
        'MTA:SIM5',
        'MTA:SIM6',
        'MTA:SIM7',
        'MTA:SIM9',
        'MTA:SIM10',
        'MTA:SIM11',
        'MTA:SIM15',
        'MTA:SIM31',
        'MTA:SIM32',
        'MTA:SIM33',
        'MTA:SIM34',
        'MTA:SIM35',
        'MTA:X27',
        'MTA:X28',
        'MTA:X37',
        'MTA:X38',
      ],
    },
    {
      label: 'Hugh Carey Tunnel (Off-Peak)',
      value: [
        'MTA:SIM1C',
        'MTA:SIM3C',
        'MTA:SIM4C',
        'MTA:SIM33C',
        'MTA:X27',
        'MTA:X28',
      ],
    },
  ],
  [FeedId.MNR]: [],
  [FeedId.BT]: [],
};

const Pill: React.FC<
  {
    label: string;
    isSelected: boolean;
    onClick: () => void;
  } & { children?: React.ReactNode }
> = ({ label, isSelected, onClick }) => (
  <button
    type="button"
    onClick={(e: any) => {
      e.preventDefault();
      onClick();
    }}
    css={css`
      border: 1px solid #aaaaaa;
      border-radius: 12px;
      padding: 3px 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;

      ${isSelected &&
      css`
        background-color: #707070;
        border-color: #707070;
        color: white;
      `}
    `}
  >
    {label}
  </button>
);

export const QuickSelectFooter: React.FC<{
  isAgencyWide: boolean;
  routeOptions: RouteMention[];
  onChange: IOnChangeQuickSelect;
  children?: React.ReactNode;
}> = ({ isAgencyWide, routeOptions, onChange }) => {
  const feedId = useFeedId();
  const allRoutes = useRoutes();
  const agencyWideEnabled = useFeatureFlag(
    FeatureFlagName.TARGET_AGENCY_IN_GTFS,
  );

  return (
    <React.Fragment>
      {agencyWideEnabled && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: -20px;
            margin-bottom: 5px;
          `}
        >
          <div>
            <h4>Target {FEEDS[feedId].name} Agency</h4>
            {isAgencyWide && (
              <p
                css={css`
                  font-size: 14px;
                  line-height: 16px;
                  margin-top: -15px;
                `}
              >
                This will send as an agency-wide alert.
              </p>
            )}
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Toggle
              checked={isAgencyWide}
              onChange={() =>
                onChange(
                  isAgencyWide
                    ? []
                    : routeOptions
                        .map(({ routeId }) => {
                          const route = allRoutes.find(
                            ({ gtfsId }) => gtfsId === routeId,
                          );
                          return route
                            ? routeToRouteMention({ route })
                            : undefined;
                        })
                        .filter(
                          (mention): mention is RouteMention => !!mention,
                        ),
                  !isAgencyWide,
                )
              }
              css={css`
                margin-top: 24px;
                :is(input) {
                  display: flex;
                  justify-content: center;
                  margin-right: 8px;
                  width: 36px;
                  height: 20px;
                }
                :is(div) {
                  width: 15px;
                  height: 15px;
                  left: 2px;
                  top: 2px;
                  transform: ${isAgencyWide ? 'translate(16px)' : ''};
                }
                svg {
                  width: 9px;
                  height: 9px;
                }
              `}
            />
          </div>
        </div>
      )}
      <Button size="xsmall" type="button" onClick={() => onChange([])}>
        Clear
      </Button>
    </React.Fragment>
  );
};

const QuickSelect: React.FC<
  {
    routes: RouteMention[] | RouteMention;
    options: RouteMention[];
    isAgencyWide: boolean;
    addSpacing?: boolean;
    hideSeparator?: boolean;
    hideFooter?: boolean;
    onChange: IOnChangeQuickSelect;
    children: React.ReactNode;
  } & { children?: React.ReactNode }
> = ({
  routes,
  options,
  isAgencyWide,
  addSpacing = false,
  hideSeparator = false,
  hideFooter = false,
  onChange,
  children,
}) => {
  const feedId = useFeedId();
  const allRoutes = useRoutes();
  const routeValues = Array.isArray(routes) ? routes : [routes];

  const quickSelectOptions = QUICK_SELECT_OPTIONS[feedId];
  const selectedRouteIds = routeValues.map(({ routeId }) => routeId);

  const handleChange = (isSelected: boolean, routeIds: string[]) => {
    const updatedOptions = options.filter(({ routeId }) =>
      isSelected
        ? selectedRouteIds.includes(routeId) && !routeIds.includes(routeId)
        : routeIds.includes(routeId) || selectedRouteIds.includes(routeId),
    );
    const updatedMentions = updatedOptions
      .map(({ routeId }) => {
        const route = allRoutes.find(({ gtfsId }) => gtfsId === routeId);
        return route ? routeToRouteMention({ route }) : undefined;
      })
      .filter((mention): mention is RouteMention => !!mention);
    onChange(updatedMentions);
  };

  return (
    <React.Fragment>
      {!!quickSelectOptions?.length && (
        <div
          css={
            addSpacing &&
            css`
              padding: 10px 20px 5px;
            `
          }
        >
          <h3
            css={css`
              margin: 0 0 3px 0;
            `}
          >
            Quick Select
          </h3>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 4px 0;
              gap: 8px;
              flex-wrap: wrap;
            `}
          >
            {quickSelectOptions.map(({ label, value }, index) => {
              const pillKey = `${label}-${index}`;
              const isSelected = value.every((v) =>
                selectedRouteIds.includes(v),
              );

              return (
                <Pill
                  key={pillKey}
                  label={label}
                  isSelected={isSelected}
                  onClick={() => handleChange(isSelected, value)}
                />
              );
            })}
          </div>
          {!hideSeparator && (
            <HR
              css={css`
                margin: 8px 0;
              `}
            />
          )}
        </div>
      )}
      {children}
      {!hideFooter && (
        <QuickSelectFooter
          isAgencyWide={isAgencyWide}
          routeOptions={options}
          onChange={onChange}
        />
      )}
    </React.Fragment>
  );
};

export default QuickSelect;
