/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';

import formatDate from 'date-fns/format';
import pluralize from 'pluralize';

import theme from 'theme';
import { useFeedId } from 'contexts/FeedId';
import useLiveQuery from 'hooks/use-live-query';
import { Toast, ToastLevel, useToast } from 'ui-kit/toast';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import StatusBanner from 'components/common/status-banner';
import Button from 'components/common/Button';
import { ReactComponent as IconEllipsis } from 'images/icon_ellipsis.svg';

import {
  PolledCampaignStatusPill as CampaignStatusQueryType,
  PolledCampaignStatusPillVariables as CampaignStatusVariables,
} from 'generated/PolledCampaignStatusPill';
import removeTypenames from 'utils/remove-typenames';
import Tooltip from 'components/common/tooltip';
import { ReactComponent as InfoIcon } from 'images/information.svg';
import ConfirmModal from 'components/common/confirm-modal';
import { areAnyDatesInPast } from 'utils/date-helpers';
import { useRoutes } from 'contexts/Routes';
import { getValidCampaignScreenContentSelectors } from 'utils/campaign-helpers';
import {
  RECLEAR_SCREENS_TOAST_MESSAGE,
  RECLEAR_SCREENS_FAILED_TOAST_MESSAGE,
} from 'constants/generic-messages';
import { CAMPAIGN_DATES_EXPIRED } from 'constants/error-messages';

import * as Well from '../../common/well';
import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import PageMeta from '../../common/PageMeta';
import { PolledCampaignStatusPill as CampaignStatusPill } from '../../common/campaign-status-pill';
import StatusPill, { TStatusPillTypes } from '../../common/status-pill';
import Loader from '../../common/skeletons/PageWithContent';
import {
  durations_gqlToForm,
  uploads_gqlToForm,
  cadences_gqlToForm,
} from '../../common/campaign-form/serializers';

import * as S from './index.styled';

import {
  CampaignStatus,
  DurationsSourceInput,
} from '../../../generated/global-types';
import {
  SingleCampaignView,
  SingleCampaignViewVariables,
} from '../../../generated/SingleCampaignView';
import {
  ClearCampaign,
  ClearCampaignVariables,
} from '../../../generated/ClearCampaign';
import {
  DeleteCampaign,
  DeleteCampaignVariables,
} from '../../../generated/DeleteCampaign';
import {
  PublishCampaign,
  PublishCampaignVariables,
} from '../../../generated/PublishCampaign';
import {
  ReclearCampaignScreens,
  ReclearCampaignScreensVariables,
} from '../../../generated/ReclearCampaignScreens';

import { DATE_FORMAT } from '../../../constants/time';
import CampaignScreenTargetingView from './campaign-screen-targeting-view';

const SingleCampaignViewQuery = loader(
  '../../../graphql/SingleCampaignView.gql',
);

const CampaignStatusQuery = loader(
  '../../../graphql/PolledCampaignStatusPill.gql',
);

const ClearCampaignMutation = loader('../../../graphql/ClearCampaign.gql');
const DeleteCampaignMutation = loader('../../../graphql/DeleteCampaign.gql');
const PublishCampaignMutation = loader('../../../graphql/PublishCampaign.gql');
const ReclearCampaignScreensMutation = loader(
  '../../../graphql/ReclearCampaignScreens.gql',
);

const MenuListStyles = css`
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  background-color: #ffffff;
  outline-color: #aaaaaa;
  box-shadow:
    inset 0 0 4px 0 #aaaaaa,
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  &[data-reach-menu-list] {
    position: relative;
    right: 98px;
  }
`;
const MenuItemStyles = css`
  padding: 10px 19px;
  margin: 4px 0;
  cursor: pointer;
  &[data-selected] {
    background-color: ${theme.colors['select-option-hover']};
  }
`;

const TrainScreensTooltip: React.FC<
  unknown & { children?: React.ReactNode }
> = () => (
  <Tooltip
    isActive
    label="Content published to train screens may take up to 24 hours to sync"
  >
    <div
      css={css`
        margin-left: 8px;
      `}
    >
      <InfoIcon />
    </div>
  </Tooltip>
);

interface CampaignViewProps {}
const CampaignView: React.FC<CampaignViewProps> = () => {
  const { campaignId } = useParams<{
    campaignId: string;
  }>();
  const feedId = useFeedId();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const allRoutes = useRoutes();

  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const [expiredDatesModalIsOpen, setExpiredDatesModalIsOpen] =
    React.useState(false);
  const [campaignStatus, setCampaignStatus] = React.useState('');
  const previousFastStatus = React.useRef<string>('');
  const [calculatedStatus, setCalculatedStatus] = React.useState('');
  const [toastProperties, setToastProperties] = React.useState<{
    level: ToastLevel;
    message: string;
  }>({
    level: ToastLevel.success,
    message: '',
  });

  const [deleteCampaignMutation] = useMutation<
    DeleteCampaign,
    DeleteCampaignVariables
  >(DeleteCampaignMutation, {
    variables: { campaignId: parseFloat(campaignId as string) },
  });
  const [publishCampaign] = useMutation<
    PublishCampaign,
    PublishCampaignVariables
  >(PublishCampaignMutation, {
    optimisticResponse: {
      result: {
        __typename: 'PublishCampaignPayload',
        campaign: {
          __typename: 'Campaign',
          id: parseFloat(campaignId as string),
          status: CampaignStatus.DEPLOYING,
          updatedAt: new Date().toISOString(),
          publishedAt: new Date().toISOString(),
        },
      },
    },
    variables: { campaignId: parseFloat(campaignId as string) },
  });
  const [clearCampaign] = useMutation<ClearCampaign, ClearCampaignVariables>(
    ClearCampaignMutation,
    {
      optimisticResponse: {
        result: {
          __typename: 'ClearCampaignPayload',
          campaign: {
            __typename: 'Campaign',
            id: parseFloat(campaignId as string),
            status: CampaignStatus.CLEARED,
            updatedAt: new Date().toISOString(),
            clearedAt: new Date().toISOString(),
          },
        },
      },
      variables: { campaignId: parseFloat(campaignId as string) },
    },
  );
  const [reclearCampaignScreens] = useMutation<
    ReclearCampaignScreens,
    ReclearCampaignScreensVariables
  >(ReclearCampaignScreensMutation, {
    variables: { campaignId: parseFloat(campaignId as string) },
  });

  const query = useLiveQuery<SingleCampaignView, SingleCampaignViewVariables>(
    SingleCampaignViewQuery,
    {
      variables: {
        campaignId: parseFloat(campaignId as string),
        // We only want to show the clearing screen content if the campaign is not
        // already being cleared (i.e: the campaign is still active but was edited
        // and screens were removed). If the campaign is already being cleared,
        // it is not necessary to include the clearing content cause it will be
        // included in the campaign screen contents.
        includeClearingScreenContent:
          campaignStatus !== CampaignStatus.CLEARING,
      },
    },
  );
  const location = useLocation<{
    numScreensTargeted?: number;
    status: CampaignStatus;
  }>();
  const { numScreensTargeted } = location.state ?? {};
  const startShown = numScreensTargeted != null;
  const { shouldShowToast, hideToast, showToast } = useToast(startShown);

  const deleteCampaign = async () => {
    await deleteCampaignMutation();
    history.push(`/${feedId}/campaigns`);
  };

  const campaign = query?.data?.campaign;
  const pageTitle = campaign?.title ?? `Campaign id: ${campaignId}`;

  const campaignScreenContents = getValidCampaignScreenContentSelectors(
    [
      ...(campaign?.clearingCampaignScreenContent?.nodes ?? []),
      ...(campaign?.campaignScreenContents?.nodes ?? []),
    ],
    allRoutes,
  );

  const finalAuthor = campaign?.updater ?? campaign?.author;

  const hasMotion = campaignScreenContents?.some((csc) =>
    csc.campaignScreenContentUploads.nodes.some((u) => u.upload?.hasMotion),
  );
  const hasTrainTargeting = campaignScreenContents?.some(
    (csc) => csc.trainTargeting,
  );
  const screenName = campaignScreenContents?.[0]?.screenName ?? null;
  const isSingleScreenCampaign = !!screenName;

  const fastStatus = query?.data?.campaign?.status;

  const deployingToastMessage: React.ReactNode = (() => {
    const { status: locationStateStatus } = location.state ?? {};
    const prefix = 'Campaign saved.';
    switch (locationStateStatus) {
      case CampaignStatus.DEPLOYING:
      case CampaignStatus.SCHEDULED:
      case CampaignStatus.LIVE:
        return [
          prefix,
          `Deploying to ${pluralize(
            'screens',
            isSingleScreenCampaign ? 1 : numScreensTargeted,
            true,
          )}…`,
        ].join(' ');
      default:
        return prefix;
    }
  })();

  const fetchCalculatedStatus = async () => {
    const { data } = await apolloClient.query<
      CampaignStatusQueryType,
      CampaignStatusVariables
    >({
      query: CampaignStatusQuery,
      variables: { campaignId: parseFloat(campaignId as string) },
    });

    if (data?.campaign?.status) {
      setCalculatedStatus(data?.campaign?.status);
    }
  };

  React.useEffect(() => {
    // Prioritize live fast status over calculated status in case it
    // changes after an action has happened elsewhere on mercury
    if (
      fastStatus &&
      (!campaignStatus ||
        (fastStatus === CampaignStatus.CLEARED &&
          calculatedStatus === CampaignStatus.CLEARING) ||
        (fastStatus !== previousFastStatus.current &&
          fastStatus !== calculatedStatus))
    ) {
      previousFastStatus.current = fastStatus;
      setCampaignStatus(fastStatus);
    } else if (calculatedStatus && calculatedStatus !== campaignStatus) {
      setCampaignStatus(calculatedStatus);
    }
  }, [fastStatus, calculatedStatus, campaignStatus]);

  const isCampaignClearing = campaignStatus === CampaignStatus.CLEARING;

  const buttons = (() => {
    switch (campaignStatus) {
      case CampaignStatus.DRAFT:
        return (
          <React.Fragment>
            <S.Button
              primary
              onClick={() => history.push(`${campaignId}/edit`)}
            >
              Edit
            </S.Button>
            <S.Button
              onClick={async () => {
                setIsActionLoading(true);

                if (query?.data?.campaign?.durations) {
                  const expired = areAnyDatesInPast(
                    query?.data.campaign.durations.map((v) => v.end?.value),
                  );
                  if (expired) {
                    setExpiredDatesModalIsOpen(true);
                  } else {
                    await publishCampaign();
                    await fetchCalculatedStatus();
                  }
                }

                setIsActionLoading(false);
              }}
              disabled={isActionLoading}
            >
              Publish
            </S.Button>
            <S.Button
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this campaign?',
                  )
                ) {
                  deleteCampaign();
                }
              }}
              disabled={isActionLoading}
            >
              Delete
            </S.Button>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <S.Button
              primary
              disabled={
                campaignStatus === CampaignStatus.CLEARED ||
                isCampaignClearing ||
                isActionLoading
              }
              onClick={() => history.push(`${campaignId}/edit`)}
            >
              Edit
            </S.Button>
            <S.Button
              onClick={() => {
                if (campaign) {
                  const directDurations = campaign?.durationsSource
                    ?.directDurations
                    ? durations_gqlToForm(
                        campaign?.durationsSource?.directDurations,
                      )
                    : null;
                  const cadences = campaign?.durationsSource.cadences
                    ? cadences_gqlToForm(campaign?.durationsSource?.cadences)
                    : null;

                  const durationsSource = removeTypenames({
                    inputMethod: campaign?.durationsSource?.inputMethod,
                    directDurations,
                    cadenceRange: campaign?.durationsSource?.cadenceRange,
                    cadences,
                  }) as DurationsSourceInput;

                  const targeting = getValidCampaignScreenContentSelectors(
                    campaign.campaignScreenContents.nodes,
                    allRoutes,
                  )
                    .filter(({ isClearing }) => !isClearing)
                    .map((csc) => ({
                      screenSelectors: csc.screenSelectors,
                      layouts: csc.targetTypes,
                      uploads: uploads_gqlToForm(
                        csc.campaignScreenContentUploads.nodes,
                      ),
                      weightFrequency: {
                        priority: csc.priority,
                        weight: csc.weight,
                      },
                      trainTargeting: removeTypenames(
                        csc.trainTargeting as any,
                      ),
                      screenName: csc.screenName,
                    }));

                  history.push(`/${feedId}/campaigns/compose`, {
                    defaultValues: {
                      title: campaign.title,
                      durationsSource,
                      targeting,
                    },
                  });
                } else {
                  history.push(`/${feedId}/campaigns/compose`);
                }
              }}
              disabled={isActionLoading}
            >
              Duplicate
            </S.Button>
            <S.Button
              disabled={
                campaignStatus === CampaignStatus.CLEARED ||
                isCampaignClearing ||
                isActionLoading
              }
              onClick={async () => {
                if (
                  window.confirm(
                    'Are you sure you want to clear this campaign?',
                  )
                ) {
                  setIsActionLoading(true);
                  await clearCampaign();
                  await fetchCalculatedStatus();

                  setIsActionLoading(false);
                }
              }}
            >
              Clear
            </S.Button>
            {campaign?.canReclearScreens && (
              <Menu>
                <MenuButton
                  css={css`
                    width: 56px;
                    height: 40px;
                  `}
                >
                  <Button
                    as="div"
                    css={css`
                      height: 100%;
                      width: 100%;
                      min-width: 0;
                      padding: 0;
                    `}
                    title="More options"
                    disabled
                  >
                    <IconEllipsis aria-hidden />
                  </Button>
                </MenuButton>
                <MenuList css={MenuListStyles}>
                  <MenuItem
                    onSelect={async () => {
                      try {
                        await reclearCampaignScreens();
                        setToastProperties({
                          level: ToastLevel.info,
                          message: RECLEAR_SCREENS_TOAST_MESSAGE,
                        });
                      } catch {
                        setToastProperties({
                          level: ToastLevel.error,
                          message: RECLEAR_SCREENS_FAILED_TOAST_MESSAGE,
                        });
                      }
                      showToast();
                    }}
                    css={MenuItemStyles}
                  >
                    Reclear Screens
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </React.Fragment>
        );
    }
  })();

  const dateDurations =
    campaign?.durations
      ?.filter((d) => !!d?.start?.value && !!d?.end?.value)
      ?.map((d) => ({
        start: new Date(d?.start?.value as string),
        end: new Date(d?.end?.value as string),
      }))
      .sort((d1, d2) =>
        !!d1.start && !!d2.start && d1.start < d2.start ? -1 : 1,
      ) ?? [];

  // Instead of fetching 2 statuses, we can just check if any of the durations
  // are live, and if so, the campaign is live, otherwise it's scheduled.
  const baseStatus: TStatusPillTypes | undefined = (() => {
    if (!isCampaignClearing) {
      return undefined;
    }
    return (campaign?.durations ?? []).some(({ start, end }) => {
      const now = new Date().getTime();
      return (
        !!start?.value &&
        !!end?.value &&
        new Date(start.value).getTime() <= now &&
        new Date(end.value).getTime() >= now
      );
    })
      ? TStatusPillTypes.LIVE
      : TStatusPillTypes.SCHEDULED;
  })();

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <Toast
        shouldShow={shouldShowToast}
        level={toastProperties.level}
        onDismiss={hideToast}
      >
        {toastProperties.message || deployingToastMessage}
      </Toast>
      <ConfirmModal
        onConfirm={async () => {
          setIsActionLoading(true);
          setExpiredDatesModalIsOpen(false);
          await publishCampaign();

          await fetchCalculatedStatus();

          setIsActionLoading(false);
        }}
        isOpen={expiredDatesModalIsOpen}
        onDismiss={() => {
          setExpiredDatesModalIsOpen(false);
        }}
        title="Warning"
        dismissText="Cancel"
        confirmText="Publish"
      >
        {CAMPAIGN_DATES_EXPIRED}
      </ConfirmModal>
      <PageMeta title={pageTitle} />
      <BackOnlyPageHeading
        back={
          campaignStatus === CampaignStatus.CLEARED
            ? {
                to: `/${feedId}/campaigns/cleared`,
                title: 'Back to Cleared Campaigns',
              }
            : { to: `/${feedId}/campaigns`, title: 'Back to Campaigns' }
        }
      />
      <Well.Container>
        <Loader loading={query.loading}>
          <Well.Section>
            <S.Header>
              <S.Info>
                <S.Title>
                  {campaign?.title ?? 'Campaign'}
                  {campaign && (
                    <React.Fragment>
                      {baseStatus && (
                        <StatusPill
                          css={css`
                            margin-left: 8px;
                          `}
                          size="small"
                          status={baseStatus}
                        />
                      )}
                      <CampaignStatusPill
                        css={css`
                          margin-left: 8px;
                        `}
                        size="small"
                        campaignId={campaign.id}
                        fallbackStatus={campaignStatus as CampaignStatus}
                        onlyUseProvidedStatus
                        polling={false}
                      />
                    </React.Fragment>
                  )}
                  {hasTrainTargeting && <TrainScreensTooltip />}
                </S.Title>
                <S.EditedLast>
                  {campaign?.updatedAt && (
                    <React.Fragment>
                      <strong>Last Updated:</strong>{' '}
                      {formatDate(new Date(campaign.updatedAt), DATE_FORMAT)}
                      {finalAuthor && ` by ${finalAuthor?.name}`}
                    </React.Fragment>
                  )}
                  {isSingleScreenCampaign && (
                    <div
                      css={css`
                        margin-top: 4px;
                      `}
                    >
                      <strong>Screen:</strong>{' '}
                      {campaignScreenContents?.[0]?.actualScreenName}
                    </div>
                  )}
                </S.EditedLast>
              </S.Info>
              <S.Buttons>{buttons}</S.Buttons>
            </S.Header>

            {(campaignStatus === CampaignStatus.LIMITED ||
              campaignStatus === CampaignStatus.ERROR) && (
              <StatusBanner
                css={css`
                  margin-top: 12px;
                `}
                hasIcon
                transition={false}
                status={
                  campaignStatus === CampaignStatus.ERROR ? 'error' : 'warning'
                }
              >
                {campaignStatus === CampaignStatus.LIMITED &&
                  !hasMotion &&
                  'Deployment of this campaign failed on at least 10% of screens. Please contact Outfront for more information.'}
                {campaignStatus === CampaignStatus.ERROR &&
                  'Deployment of this campaign failed. Please duplicate and clear it to try publishing again.'}
              </StatusBanner>
            )}
          </Well.Section>
          <Well.Section>
            <S.Label>Duration</S.Label>
            <S.Durations>
              {dateDurations.map((d) => (
                <S.Duration key={`${d.start}-${d.end}`}>
                  {formatDate(d.start, DATE_FORMAT)} -{' '}
                  {d.end ? formatDate(d.end, DATE_FORMAT) : ''}
                </S.Duration>
              ))}
            </S.Durations>
          </Well.Section>
          {campaignScreenContents?.map((csc, idx) => (
            <CampaignScreenTargetingView
              key={`screen-targeting-view-${csc.id}`}
              index={idx}
              {...csc}
            />
          ))}
        </Loader>
      </Well.Container>
    </div>
  );
};

export default CampaignView;
