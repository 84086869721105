import { findTextNodes, } from '@remirror/core';
export const isMention = (nodeWithPos) => {
    return (nodeWithPos.node.marks &&
        nodeWithPos.node.marks.length === 1 &&
        nodeWithPos.node.marks[0].type.name === 'mention' &&
        !!nodeWithPos.node.marks[0].attrs['data-bullet-type']);
};
export const isBullet = (nodeWithPos) => {
    const blockType = nodeWithPos.node.type.name;
    return blockType === 'bulletList' || blockType === 'listItem';
};
export const getAllMentions = ({ doc }) => {
    const mentionNodes = findTextNodes({ node: doc }).filter(isMention);
    return mentionNodes;
};
