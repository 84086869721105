/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { UploadType } from 'hooks/useUpload';
import { Thumbnail } from 'ui-kit/media-library';
import Modal from 'components/common/modal';
import Button from 'components/common/Button';

import { LockableContent } from 'generated/LockableContent';

import theme, { ThemeType } from '../../../../theme';

const LockableContentQuery = loader('../../../../graphql/LockableContent.gql');

interface LockableContentModalProps {
  isConfirmDisabled: boolean;
  onSelectUpload: (upload: UploadType) => void;
  onDismiss: () => void;
  allowZipfiles?: boolean;
  allowedDimensions?: {
    width?: number;
    height?: number;
  };
}

const LockableContentModal: React.FC<LockableContentModalProps> = ({
  isConfirmDisabled,
  onSelectUpload,
  onDismiss,
  allowZipfiles = true,
  allowedDimensions: { width, height } = {},
}) => {
  const { loading, error, data } = useQuery<LockableContent>(
    LockableContentQuery,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [formError, setFormError] = useState<null | string>(null);
  const [selectedContent, setSelectedContent] = useState<UploadType | null>(
    null,
  );

  const onSubmit = () => {
    if (selectedContent) {
      if (selectedContent.mimeType === 'application/zip' && !allowZipfiles) {
        setFormError('This screen only supports media content.');
        return;
      }
      if (
        (selectedContent?.mimeType?.startsWith('image/') ||
          selectedContent?.mimeType?.startsWith('video/')) &&
        (selectedContent?.width !== width || selectedContent?.height !== height)
      ) {
        setFormError(
          `This screen only supports media content with dimensions ${width}x${height}.`,
        );
        return;
      }
      onSelectUpload(selectedContent);
    } else {
      setFormError('You must select content to lock this screen.');
    }
  };

  const modalFooterEl = (
    <div
      css={(theme: ThemeType) => css`
        padding: 24px 32px;
        border-top: 1px solid ${theme.colors.border};
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
        `}
      >
        <Button
          primary
          size="medium"
          type="button"
          css={css`
            margin-left: 12px;
            width: 160px;
          `}
          onClick={onSubmit}
          disabled={isConfirmDisabled}
        >
          Confirm
        </Button>
        <Button
          css={css`
            width: 160px;
          `}
          size="medium"
          type="button"
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      title="Select Locked Screen Content"
      restOnContent
      css={css`
        max-width: unset;
      `}
      bodyBackgroundColor={theme.colors['background-inset']}
      footer={modalFooterEl}
    >
      <div>
        {loading && 'Loading lockable content...'}
        {error && `Failed to load lockable content: ${error}`}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          `}
        >
          {!error &&
            !loading &&
            data?.lockableUploads &&
            data.lockableUploads.nodes.map(({ upload, thumbnail }: any) => (
              <Thumbnail
                css={css`
                  margin: 0 24px 24px 0;
                  cursor: pointer;
                `}
                key={upload.id}
                upload={upload}
                thumbnailSrc={thumbnail?.signedS3Url}
                checked={upload.id === selectedContent?.id}
                onClick={() => setSelectedContent(upload)}
                previewBackgroundColor={theme.colors.white}
              />
            ))}
        </div>
        {formError && (
          <p
            css={css`
              color: red;
            `}
          >
            {formError}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default LockableContentModal;
