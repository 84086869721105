/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { useFeedId } from 'contexts/FeedId';
import { useBulletGenerator } from 'hooks/useBulletGenerator';
import Bullet, { BulletSize } from 'components/common/Bullet';
import Checkbox, { CheckboxSize } from 'components/common/Checkbox';
import Select from 'components/common/form-elements/Select';
import { isSubway } from 'utils/feed-switches';
import theme from 'theme';
import { ReactComponent as ArrowUp } from 'images/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'images/arrow-down.svg';
import { ReactComponent as ArrowBothDirections } from 'images/arrow-both-directions.svg';
import { OptionProps, ControlProps, components } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import { CardinalDirection as Directionality } from 'generated/global-types';

import * as C from './constants';
import * as S from './route-range-stop-selector.styled';
import useRangeSelection, { EnhancedOption } from './use-range-selection';

const BoroughLabels: any = {
  BRONX: 'BRNX',
  BROOKLYN: 'BKLYN',
  MANHATTAN: 'MANH',
  QUEENS: 'QNS',
  STATEN_ISLAND: 'ST ISL',
};

export interface RouteIdsByStopId {
  [id: string]: Set<string>;
}

interface RouteRangeStopSelectorOption {
  value: string;
  label: string;
  borough?: string | null;
}

interface RouteRangeStopSelectorProps {
  gtfsRouteId: string;
  options: RouteRangeStopSelectorOption[];
  selectedStops: string[] | null;
  directionality: Directionality | null;
  onSelectedStopsChange: (stopIds: string[]) => void;
  onDirectionalityChange: (directionality: Directionality | null) => void;
  routeIdsByStopId?: RouteIdsByStopId;
}

const getDirectionArrow = (
  direction: Directionality | null,
): React.ReactNode => {
  switch (direction) {
    case Directionality.NORTH:
    case Directionality.EAST:
      return <ArrowUp />;
    case Directionality.SOUTH:
    case Directionality.WEST:
      return <ArrowDown />;
    default:
      return <ArrowBothDirections />;
  }
};

const CustomOption = (props: OptionProps<any>) => (
  <components.Option {...props}>
    <span
      css={css`
        margin-left: 5px;
        margin-right: 11px;
      `}
    >
      {getDirectionArrow(props.data?.value as Directionality)}
    </span>{' '}
    {props.children}
  </components.Option>
);

const CustomControl = (props: ControlProps<any>, stopName?: string) => {
  const children = props.children as React.ReactNode[];

  return (
    <components.Control {...props}>
      <span
        css={css`
          margin-left: 14px;
        `}
      >
        {getDirectionArrow(props.selectProps?.value?.value as Directionality)}
      </span>{' '}
      {children[0]}
      {stopName && (
        <div
          css={css`
            color: ${theme.colors.dark3};
            font-size: ${theme.typography.sizes.small.fontSize};
            width: 95px;
          `}
        >
          (
          {stopName.length > 8 ? `${stopName.slice(0, 9).trim()}...` : stopName}
          )
        </div>
      )}
      {children[1]}
    </components.Control>
  );
};

const getLastSelectedStationName = (
  options: EnhancedOption[],
  direction: Directionality | null,
) => {
  if (direction === Directionality.NORTH || direction === Directionality.EAST) {
    return options.find((o) => o.selected)?.option?.label;
  }

  if (direction === Directionality.SOUTH || direction === Directionality.WEST) {
    return options
      .slice()
      .reverse()
      .find((o) => o.selected)?.option?.label;
  }

  return '';
};

type BoroughHeights = {
  [key: string]: {
    start: number;
    end: number;
  };
};

const getBoroughHeights = (
  enhancedOptions: EnhancedOption[],
): BoroughHeights => {
  const boroughHeights: BoroughHeights = {};
  let lastBorough: string | null = null;

  enhancedOptions.forEach(({ option }) => {
    if (!boroughHeights[option.borough]) {
      if (!lastBorough) {
        boroughHeights[option.borough] = {
          // Scroll container padding
          start: 16,
          end: 16,
        };
      } else {
        boroughHeights[option.borough] = {
          start: boroughHeights[lastBorough].end,
          end: boroughHeights[lastBorough].end,
        };
      }
    }

    if (lastBorough !== option.borough) {
      if (!lastBorough) {
        // Initial borough selection height
        boroughHeights[option.borough].end += 54;
      } else {
        // Non initial borough selection height
        boroughHeights[option.borough].end += 78;
      }
    }

    // Station height
    boroughHeights[option.borough].end += 32;

    lastBorough = option.borough;
  });

  return boroughHeights;
};

type DirChangeFn = (param: { value: Directionality | null }) => void;

const RouteRangeStopSelector: React.FC<RouteRangeStopSelectorProps> = ({
  gtfsRouteId,
  options,
  selectedStops,
  directionality,
  onSelectedStopsChange,
  onDirectionalityChange,
  routeIdsByStopId,
}) => {
  const feedId = useFeedId();
  const bulletGenerator = useBulletGenerator();
  const [{ color: routeColor }] = bulletGenerator([gtfsRouteId]);
  const handleDirectionalityChange = React.useCallback<DirChangeFn>(
    (newDirectionality) => {
      if (newDirectionality) {
        onDirectionalityChange(newDirectionality.value);
      } else {
        onDirectionalityChange(null);
      }
    },
    [onDirectionalityChange],
  );
  const [rangeInView, setRangeInView] = React.useState({
    start: 0,
    // Viewed stations height
    end: 384,
  });

  const [debouncedSetRangeInView] = useDebouncedCallback((value: any) => {
    setRangeInView(value);
  }, 50);

  const {
    onClick,
    enhancedOptions,
    selectAll,
    deselectAll,
    boroughSelectAll,
    boroughDeselectAll,
  } = useRangeSelection({
    values: selectedStops || [],
    onChange: onSelectedStopsChange,
    options,
  });

  const handleClickBorough = React.useCallback((evt: any, routeId: string) => {
    const { borough } = evt.target.dataset;
    const scrollContainer = document.querySelector(
      `div[data-route="${routeId}"]`,
    );
    const boroughEl = document.querySelector(
      `div[data-route-borough="${routeId}_${borough}"]`,
    ) as HTMLElement;
    if (boroughEl && scrollContainer) {
      scrollContainer.scroll({
        top: boroughEl.offsetTop + 1,
        behavior: 'smooth',
      });
    }
  }, []);

  const allSelected = enhancedOptions.every(
    (o: { selected: boolean }) => o.selected,
  );

  const boroughs = Array.from(
    new Set(
      enhancedOptions
        .filter((eo) => eo.option.borough)
        .map((eo) => eo.option.borough),
    ).values(),
  );

  let lastSeenBorough: any = null;
  const boroughHeights = getBoroughHeights(enhancedOptions);

  return (
    <S.Container data-route-range-stop-selector>
      <S.Header>
        <S.LineAndGlobalCheck>
          <Bullet size={BulletSize.large} routeId={gtfsRouteId} />
          <S.AllStationsLabel>
            <Checkbox
              css={S.allStationsCheckbox}
              checked={allSelected}
              onChange={(checked) => {
                if (checked) {
                  selectAll();
                } else {
                  deselectAll();
                }
              }}
            />
            All Stations
          </S.AllStationsLabel>
        </S.LineAndGlobalCheck>
        <S.Row>
          {/* TODO: this <Select/> is too tall. */}
          <Select
            css={S.select}
            styles={{
              singleValue: (
                provided: {},
                state: {
                  selectProps: {
                    menuIsOpen: boolean;
                  };
                },
              ) => {
                return {
                  ...provided,
                  color: state.selectProps.menuIsOpen
                    ? '#0F61A9'
                    : theme.colors.black,
                  fontSize: theme.typography.sizes.medium.fontSize,
                  lineHeight: theme.typography.sizes.medium.lineHeight,
                  fontFamily: theme.typography.families.primary,
                  fontWeight: theme.typography.weights.normal,
                };
              },
              valueContainer: (provided: {}) => {
                return {
                  ...provided,
                  padding: '0 9px',
                };
              },
              input: (provided = {}) => {
                return {
                  ...provided,
                  gridTemplateColumns: '0 max-content',
                };
              },
              option: (provided: {}, state: any) => {
                return {
                  ...provided,
                  color: theme.colors.black,
                  backgroundColor:
                    state.isFocused || state.isSelected
                      ? '#E7EFF6'
                      : theme.colors.white,
                  fontSize: theme.typography.sizes.medium.fontSize,
                  lineHeight: theme.typography.sizes.medium.lineHeight,
                  fontFamily: theme.typography.families.primary,
                  fontWeight: theme.typography.weights.normal,
                  position: 'relative',
                  display: 'flex',
                  padding: '0 9px',
                };
              },
            }}
            options={C.DirectionOptions[feedId]}
            value={C.DirectionOptions[feedId].find(
              (o) => o.value === directionality,
            )}
            onChange={handleDirectionalityChange}
            components={{
              Option: CustomOption,
              Control: (props: ControlProps<any>) =>
                CustomControl(
                  props,
                  getLastSelectedStationName(enhancedOptions, directionality),
                ),
            }}
          />
        </S.Row>
      </S.Header>
      {boroughs.length > 0 && (
        <S.BoroughTabs>
          {boroughs.map((b) => (
            <S.BoroughTab
              key={b}
              data-borough={b}
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) =>
                handleClickBorough(evt, gtfsRouteId)
              }
              isActive={
                boroughHeights[b].start <= rangeInView.end &&
                rangeInView.start <= boroughHeights[b].end
              }
            >
              {BoroughLabels[b]}
            </S.BoroughTab>
          ))}
        </S.BoroughTabs>
      )}
      <S.ScrollContainer
        onScroll={(event: any) => {
          const { scrollTop } = event.target;
          debouncedSetRangeInView({
            start: scrollTop,
            // Viewed stations height
            end: scrollTop + 384,
          });
        }}
        data-route={gtfsRouteId}
      >
        <S.Stops routeColor={routeColor}>
          {enhancedOptions.map(({ option, selected }, idx) => {
            const startOfNewBorough = lastSeenBorough !== option.borough;
            lastSeenBorough = option.borough;

            const overlappingRouteIds =
              routeIdsByStopId && routeIdsByStopId[option.value]
                ? Array.from(routeIdsByStopId[option.value]).filter(
                    (rid) => rid !== gtfsRouteId,
                  )
                : [];

            return (
              <React.Fragment key={option.value}>
                {startOfNewBorough && (
                  <S.Borough
                    data-borough={lastSeenBorough}
                    data-highlight={
                      enhancedOptions[idx - 1]?.selected && selected
                    }
                    data-is-first={idx === 0}
                    data-route-borough={`${gtfsRouteId}_${lastSeenBorough}`}
                  >
                    <label
                      css={css`
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                      `}
                    >
                      <Checkbox
                        css={S.allStationsCheckbox}
                        checked={enhancedOptions
                          .filter((o) => o.option.borough === option.borough)
                          .every((o: { selected: boolean }) => o.selected)}
                        onChange={(checked) => {
                          if (checked) {
                            boroughSelectAll(option.borough);
                          } else {
                            boroughDeselectAll(option.borough);
                          }
                        }}
                        size={CheckboxSize.small}
                      />
                      All {BoroughLabels[lastSeenBorough]} Stations
                    </label>
                  </S.Borough>
                )}
                <S.Stop
                  checked={selected}
                  startsRange={!enhancedOptions[idx - 1]?.selected}
                  endsRange={!enhancedOptions[idx + 1]?.selected}
                  onClick={onClick(option)}
                  routeIds={isSubway(feedId) ? overlappingRouteIds : undefined}
                >
                  {option.label}
                </S.Stop>
              </React.Fragment>
            );
          })}
        </S.Stops>
      </S.ScrollContainer>
    </S.Container>
  );
};

export default RouteRangeStopSelector;
