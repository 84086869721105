/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { components, ValueContainerProps, OptionProps } from 'react-select';
import { STATION_SCREEN_OPTIONS } from 'constants/screens';
import { FeatureFlagName, TargetType } from 'generated/global-types';
import { FeedId } from 'contexts/FeedId';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import Select from '../form-elements/Select';
import RouteSelector from '../form-elements/route-selector';
import BusRouteSelector from '../form-elements/route-selector/bus-route-selector';

import * as styles from './live-content-filters.styles';

import ScreenTypeFilter from '../screen-filter-selector';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from '../../../generated/RoutesByFeed';
import { RouteMention } from '../../../types';
import { routeToRouteMention } from '../../../utils/route-mentions';
import { getRouteType } from '../../../utils/feed-switches';
import useQueryParams from '../../../hooks/useQueryParams';
import { useRoutes } from '../../../contexts/Routes';
import Button from '../Button';
import BaseSearchInput from '../form-elements/search-input';
import Checkbox, { CheckboxSize } from '../Checkbox';

type ScreenOption = {
  label: string;
  value: TargetType;
};

enum QueryParamNames {
  routes = 'routes',
  targets = 'targets',
  search = 'search',
}

const queryRouteToOption = (
  queryString: string | undefined,
  routes: RoutesByFeed_routes_nodes[],
  isMulti?: boolean,
): RouteMention | RouteMention[] => {
  if (!queryString) {
    return [];
  }
  const decoded = decodeURIComponent(queryString).split(',');
  const validRoutes = decoded
    .map((routeId) => routes.find((route) => route.gtfsId === routeId))
    .filter(
      (route) => typeof route !== 'undefined',
    ) as RoutesByFeed_routes_nodes[];

  if (isMulti) {
    return validRoutes.map((route) => routeToRouteMention({ route }));
  }
  if (validRoutes[0]) {
    return routeToRouteMention({ route: validRoutes[0] });
  }
  return [];
};

const screenTypesComponents = {
  Option: (props: OptionProps<any>) => {
    return (
      <div
        {...props.innerProps}
        style={props.getStyles('option', props) as any}
      >
        <div css={styles.options}>
          <Checkbox
            css={styles.checkbox}
            size={CheckboxSize.medium}
            checked={props.isSelected}
            onChange={() => {}}
          />
          <span css={styles.optionLabel}>{props.label}</span>
        </div>
      </div>
    );
  },
  ValueContainer: ({ children, ...rest }: ValueContainerProps<any>) => {
    const selectedScreenTypes = (rest.getValue() as ScreenOption[])
      .map((o) => o.label)
      .sort();

    return (
      <components.ValueContainer {...rest}>
        <div css={styles.selectedvalues}>
          {selectedScreenTypes.length
            ? `${selectedScreenTypes.join(', ')}`
            : ''}
          {children}
        </div>
      </components.ValueContainer>
    );
  },
};

const LiveContentFilters: React.FC<{
  showRoute?: boolean;
  feedId: FeedId;
  disabled?: boolean;
  page?: string;
  children?: React.ReactNode;
}> = ({ showRoute = true, feedId, disabled = false, page }) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const pagePath = page ? `/${page}` : '';
  const updateQueryParam = (name: string, value: string) => {
    queryParams.set(name, value);
    history.push(
      `/${feedId}/screens/live${pagePath}?${queryParams.toString()}`,
    );
  };
  const deleteQueryParam = (name: string) => {
    queryParams.delete(name);
    history.push(
      `/${feedId}/screens/live${pagePath}?${queryParams.toString()}`,
    );
  };

  const routes = useRoutes();
  const routesQuery = queryParams.get(QueryParamNames.routes);
  const targetsQuery = queryParams.get(QueryParamNames.targets);
  const searchQuery = queryParams.get(QueryParamNames.search);
  const [searchValue, setSearchValue] = useState(searchQuery || '');
  const [debouncedCallback] = useDebouncedCallback((value: string) => {
    updateQueryParam(QueryParamNames.search, value);
  }, 500);

  const clearFilters = () => {
    deleteQueryParam(QueryParamNames.routes);
    deleteQueryParam(QueryParamNames.targets);
    deleteQueryParam(QueryParamNames.search);
    setSearchValue('');
  };

  const routeSelectorProps = {
    routes: queryRouteToOption(routesQuery || undefined, routes, true),
    isMulti: true,
    placeholder: getRouteType(feedId),
    disabled,
    onChange: (value: RouteMention[]) => {
      return value.length > 0
        ? updateQueryParam(
            QueryParamNames.routes,
            value.map((r) => r.routeId).join(','),
          )
        : deleteQueryParam(QueryParamNames.routes);
    },
    css: styles.select,
  };

  const filtersApplied =
    routesQuery?.length || targetsQuery?.length || searchValue?.length;

  const rollingStockEnabled = useFeatureFlag(
    FeatureFlagName.CAMPAIGN_ROLLING_STOCK,
  );
  const sidewalkScreenEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );

  return (
    <React.Fragment>
      <BaseSearchInput
        type="text"
        placeholder="Search titles"
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);
          debouncedCallback(e.target.value);
        }}
        onClear={() => {
          setSearchValue('');
          debouncedCallback('');
        }}
      />
      <div css={styles.filters}>
        {showRoute && (
          <div css={styles.selectContainer}>
            {feedId === FeedId.NYCTBus ? (
              <BusRouteSelector {...routeSelectorProps} />
            ) : (
              <RouteSelector {...routeSelectorProps} />
            )}
          </div>
        )}
        <div css={styles.selectContainer}>
          {rollingStockEnabled || sidewalkScreenEnabled ? (
            <ScreenTypeFilter
              onChange={(targetTypes) => {
                return updateQueryParam(
                  QueryParamNames.targets,
                  targetTypes.join(','),
                );
              }}
              value={(targetsQuery?.split(',') ?? []) as TargetType[]}
            />
          ) : (
            <Select
              css={styles.select}
              options={STATION_SCREEN_OPTIONS}
              disabled={disabled}
              isMulti
              components={screenTypesComponents}
              value={
                STATION_SCREEN_OPTIONS.filter((opt) =>
                  targetsQuery?.split(',').includes(opt.value),
                ) || ''
              }
              placeholder={targetsQuery ? '' : 'Screen Type'}
              isClearable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              isSearchable={false}
              onChange={(option: ScreenOption[]) => {
                if (option) {
                  return updateQueryParam(
                    QueryParamNames.targets,
                    option.map((r) => r.value).join(','),
                  );
                }
                return deleteQueryParam(QueryParamNames.targets);
              }}
            />
          )}
        </div>

        <Button
          size="small"
          aria-label="Clear Filters"
          css={styles.button}
          type="button"
          disabled={!filtersApplied}
          onClick={clearFilters}
        >
          Clear
        </Button>
      </div>
    </React.Fragment>
  );
};

export default LiveContentFilters;
