import { MentionType } from 'generated/global-types';
import {
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  HistoryExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MentionExtension,
  PlaceholderExtension,
} from 'remirror/extensions';

export type MentionAttributes = {
  spellcheck?: string | null;
  style?: string | null;
};

type TRemirrorExtFn = (p: { showDetailMenu: boolean }) => any;
export const remirrorExtensions: TRemirrorExtFn = ({ showDetailMenu }) => {
  const basicExtensions = [
    new PlaceholderExtension({}),
    new BoldExtension({}),
    new HistoryExtension({}),
    new HeadingExtension({
      defaultLevel: 1,
    }),
    new HardBreakExtension(),
    new MentionExtension({
      mentionTag: 'span',
      suggestTag: 'span',
      matchers: ['gtfs', 'ADA', 'SHUTTLE_BUS', 'AIRPLANE'].map((name) => {
        return {
          name,
          char: '=',
          validPrefixCharacters: /^[\s\0\u200c]?$/,
          supportedCharacters: /[ \w\d-]+/,
          mentionClassName: 'mention',
          suggestClassName: 'mta-mention-suggestion',
          appendText: '',
        };
      }),
      appendText: '',
      extraAttributes: {
        spellcheck: 'false',
        style: {
          default: '',
        },
        role: 'presentation',
        'data-bullet-title': {
          default: 'generic',
          parseDOM(node) {
            return node.getAttribute('data-bullet-title');
          },
          toDOM(attrs) {
            return ['data-bullet-title', attrs['data-bullet-title'] as string];
          },
        },
        'data-bullet-type': '',
        'data-bullet-id': '',
        'data-bullet-route-id': '',
        'data-bullet-trip-id': '',
        'data-bullet-stop-id': '',
        'data-bullet-boroughs': {
          default: '',
          parseDOM(domNode) {
            return JSON.parse(
              domNode.getAttribute('data-bullet-boroughs') || 'null',
            );
          },
          toDOM(attrs) {
            return [
              'data-bullet-boroughs',
              JSON.stringify(attrs['data-bullet-boroughs']),
            ];
          },
        },
        'data-bullet-route-names': {
          default: '',
          parseDOM(domNode) {
            return JSON.parse(
              domNode.getAttribute('data-bullet-route-names') || 'null',
            );
          },
          toDOM(attrs) {
            return [
              'data-bullet-route-names',
              JSON.stringify(attrs['data-bullet-route-names']),
            ];
          },
        },
        'data-bullet-display': {
          default: '',
          toDOM(attrs) {
            const type = attrs['data-bullet-type'];
            if (type === MentionType.ADA) {
              return ['data-bullet-display', 'ada'];
            }

            if (type === MentionType.SHUTTLE_BUS) {
              return ['data-bullet-display', 'shuttle_bus'];
            }

            if (type === MentionType.TRIP || type === MentionType.STOP) {
              return ['data-bullet-display', 'generic'];
            }

            if (type === MentionType.AIRPLANE) {
              return ['data-bullet-display', 'airplane'];
            }

            return ['data-bullet-display', ''];
          },
        },
        'data-bullet-meta': {
          default: '',
          parseDOM(domNode) {
            return JSON.parse(
              domNode.getAttribute('data-bullet-meta') || 'null',
            );
          },
          toDOM(attrs) {
            return [
              'data-bullet-meta',
              JSON.stringify(attrs['data-bullet-meta']),
            ];
          },
        },
        'data-bullet-size': 'medium',
      },
    }),
  ];
  const advancedExtensions = [
    new ItalicExtension(),
    new ListItemExtension({}),
    new BulletListExtension({}),
    new LinkExtension({
      defaultProtocol: 'https:',
      autoLink: true,
      defaultTarget: '_blank',
      selectTextOnClick: true,
      markOverride: {
        excludes: 'link',
      },
      extraAttributes: {
        title: {
          default: '',
          toDOM(attrs) {
            return ['title', attrs.title as string];
          },
        },
      },
    }),
  ];

  if (showDetailMenu) {
    return [...basicExtensions, ...advancedExtensions];
  }
  return basicExtensions;
};
