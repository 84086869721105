/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useState } from 'react';
import { format } from 'date-fns';

import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { Options } from 'react-select';

import Select from '../form-elements/Select';
import RouteSelector, {
  RouteSelectorProps,
} from '../form-elements/route-selector';
import BusRouteSelector from '../form-elements/route-selector/bus-route-selector';
import DatePicker from '../form-elements/datepicker';

import * as styles from './PlannedWorkFilters.styles';

import { RoutesByFeed_routes_RoutesConnection_nodes_Route as RoutesByFeed_routes_nodes } from '../../../generated/RoutesByFeed';
import { RouteMention } from '../../../types';
import { routeToRouteMention } from '../../../utils/route-mentions';
import { getRouteType } from '../../../utils/feed-switches';
import useQueryParams from '../../../hooks/useQueryParams';
import { useRoutes } from '../../../contexts/Routes';
import { FeedId } from '../../../types/feeds';
import Button from '../Button';
import BaseSearchInput from '../form-elements/search-input';
import CommonHeading from '../Heading';

enum QueryParamNames {
  live = 'live',
  line = 'line',
  status = 'status',
  category = 'category',
  search = 'search',
  start_date = 'start_date',
  end_date = 'end_date',
  page = 'page',
  all_routes = 'all_routes',
}

type WorkFilterOption = { value?: string };
type WorkFilterOptions = Options<WorkFilterOption>;

const queryRouteToOption = (
  queryString: string | undefined,
  routes: RoutesByFeed_routes_nodes[],
  isMulti?: boolean,
): RouteMention | RouteMention[] => {
  if (!queryString) {
    return [];
  }
  const decoded = decodeURIComponent(queryString).split(',');
  const validRoutes = decoded
    .map((routeId) => routes.find((route) => route.gtfsId === routeId))
    .filter(
      (route) => typeof route !== 'undefined',
    ) as RoutesByFeed_routes_nodes[];

  if (isMulti) {
    return validRoutes.map((route) => routeToRouteMention({ route }));
  }
  if (validRoutes[0]) {
    return routeToRouteMention({ route: validRoutes[0] });
  }
  return [];
};

const PlannedWorkFilters: React.FC<{
  showRoute?: boolean;
  showCategory?: boolean;
  showStatus?: boolean;
  showLive?: boolean;
  feedId: FeedId;
  statusOptions?: WorkFilterOptions;
  categoryOptions?: WorkFilterOptions;
  liveOptions?: WorkFilterOptions;
  disabled?: boolean;
  page?: string;
  children?: React.ReactNode;
}> = ({
  showRoute = true,
  showCategory = true,
  showStatus = true,
  showLive = true,
  feedId,
  statusOptions = [],
  categoryOptions = [],
  liveOptions = [],
  disabled = false,
  page,
}) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const pagePath = page ? `/${page}` : '';
  const updateQueryParam = (name: string, value: string) => {
    queryParams.set(name, value);
    history.push(
      `/${feedId}/planned-work${pagePath}?${queryParams.toString()}`,
    );
  };
  const deleteQueryParam = (name: string) => {
    queryParams.delete(name);
    history.push(
      `/${feedId}/planned-work${pagePath}?${queryParams.toString()}`,
    );
  };

  const routes = useRoutes();
  const routeQuery = queryParams.get(QueryParamNames.line);
  const statusQuery = queryParams.get(QueryParamNames.status);
  const liveQuery = queryParams.get(QueryParamNames.live);
  const categoryQuery = queryParams.get(QueryParamNames.category);
  const searchQuery = queryParams.get(QueryParamNames.search);
  const startDateQuery = queryParams.get(QueryParamNames.start_date);
  const endDateQuery = queryParams.get(QueryParamNames.end_date);
  const includeAllRoutes = queryParams.get(QueryParamNames.all_routes);
  const [searchValue, setSearchValue] = useState(searchQuery || '');
  const [debouncedCallback] = useDebouncedCallback((value: string) => {
    queryParams.delete(QueryParamNames.page);
    updateQueryParam(QueryParamNames.search, value);
  }, 500);

  const clearFilters = () => {
    deleteQueryParam(QueryParamNames.line);
    deleteQueryParam(QueryParamNames.status);
    deleteQueryParam(QueryParamNames.live);
    deleteQueryParam(QueryParamNames.category);
    deleteQueryParam(QueryParamNames.search);
    deleteQueryParam(QueryParamNames.start_date);
    deleteQueryParam(QueryParamNames.end_date);
    deleteQueryParam(QueryParamNames.page);
    deleteQueryParam(QueryParamNames.all_routes);
    setSearchValue('');
  };

  const routeSelectorProps: RouteSelectorProps = {
    routes: queryRouteToOption(routeQuery || undefined, routes, true),
    isMulti: true,
    placeholder: getRouteType(feedId),
    disabled,
    includeAllRoutes: Boolean(includeAllRoutes),
    onRadioChange: () => {
      if (includeAllRoutes) {
        updateQueryParam('all_routes', '');
      } else {
        updateQueryParam('all_routes', 'true');
      }
    },
    onChange: (value: RouteMention[]) => {
      deleteQueryParam(QueryParamNames.page);
      return value.length > 0
        ? updateQueryParam(
            QueryParamNames.line,
            value.map((r) => r.routeId).join(','),
          )
        : deleteQueryParam(QueryParamNames.line);
    },
    css: styles.select,
  };

  const formatDate = (date: Date | null): string =>
    date ? format(date, 'MM/dd/yyyy') : '';

  const filtersApplied =
    routeQuery?.length ||
    statusQuery?.length ||
    liveQuery?.length ||
    categoryQuery?.length ||
    searchValue?.length ||
    startDateQuery?.length ||
    endDateQuery?.length ||
    includeAllRoutes?.length;
  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <CommonHeading
          level={6}
          css={css`
            margin-top: -20px;
            margin-right: 310px;
          `}
        >
          ACTIVE WORK PERIOD
        </CommonHeading>
      </div>
      <div
        css={css`
          ${styles.filters}
          margin-top: 0;
          margin-bottom: -4px;
        `}
      >
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <BaseSearchInput
            type="text"
            placeholder="Search header, message, internal notes, SP# and GO#"
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
              debouncedCallback(e.target.value);
            }}
            onClear={() => {
              setSearchValue('');
              debouncedCallback('');
            }}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <DatePicker
            inputCss={css`
              ${styles.datePicker}
            `}
            placeholderText="Start Date"
            selected={startDateQuery ? new Date(startDateQuery) : null}
            onChange={(date) => {
              deleteQueryParam(QueryParamNames.page);
              updateQueryParam(QueryParamNames.start_date, formatDate(date));
            }}
          />
          <DatePicker
            popperPlacement="bottom-end"
            inputCss={css`
              ${styles.datePicker}
              margin-left: 1rem;
              width: calc(100% - 1rem);
            `}
            placeholderText="End Date"
            selected={endDateQuery ? new Date(endDateQuery) : null}
            onChange={(date) => {
              deleteQueryParam(QueryParamNames.page);
              updateQueryParam(QueryParamNames.end_date, formatDate(date));
            }}
          />
        </div>
      </div>
      <div css={styles.filters}>
        {showRoute && (
          <div css={styles.selectContainer}>
            {feedId === FeedId.NYCTBus ? (
              <BusRouteSelector {...routeSelectorProps} />
            ) : (
              <RouteSelector {...routeSelectorProps} />
            )}
          </div>
        )}
        {showCategory && (
          <div
            css={css`
              ${styles.selectContainer}
              ${!showStatus && 'margin-right: 1rem'};
            `}
          >
            <Select
              css={styles.select}
              options={categoryOptions}
              disabled={disabled}
              value={
                categoryOptions.find((opt) => opt.value === categoryQuery) || ''
              }
              placeholder="Alert Status"
              isSearchable={false}
              onChange={(option) => {
                deleteQueryParam(QueryParamNames.page);
                if (option && option.value) {
                  return updateQueryParam(
                    QueryParamNames.category,
                    option.value,
                  );
                }
                return deleteQueryParam(QueryParamNames.category);
              }}
            />
          </div>
        )}
        {showStatus && (
          <div css={styles.selectContainer}>
            <Select
              css={styles.select}
              options={statusOptions}
              disabled={disabled}
              value={
                statusOptions.find((opt) => opt.value === statusQuery) || ''
              }
              placeholder="State"
              isSearchable={false}
              onChange={(option: WorkFilterOption) => {
                deleteQueryParam(QueryParamNames.page);
                if (option && option.value) {
                  return updateQueryParam(QueryParamNames.status, option.value);
                }
                return deleteQueryParam(QueryParamNames.status);
              }}
            />
          </div>
        )}
        {showLive && (
          <div
            css={css`
              width: 211px;
              margin-right: 1rem;
            `}
          >
            <Select
              css={styles.select}
              options={liveOptions}
              disabled={disabled}
              value={liveOptions.find((opt) => opt.value === liveQuery) || ''}
              placeholder="Visible On"
              isSearchable={false}
              onChange={(option: WorkFilterOption) => {
                deleteQueryParam(QueryParamNames.page);
                if (option && option.value) {
                  return updateQueryParam(QueryParamNames.live, option.value);
                }
                return deleteQueryParam(QueryParamNames.live);
              }}
            />
          </div>
        )}
        <Button
          size="small"
          aria-label="Clear Filters"
          css={styles.button}
          type="button"
          disabled={!filtersApplied}
          onClick={clearFilters}
        >
          Clear
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PlannedWorkFilters;
