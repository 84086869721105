/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import PageMeta from 'components/common/PageMeta';
import BackOnlyPageHeading from 'components/scaffolding/back-only-page.heading';
import { useFeedId } from 'contexts/FeedId';
import { useLocation, useHistory } from 'react-router-dom';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';
import Button from 'components/common/Button';
import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import AssetForm, {
  FormValues as AssetFormValues,
  DefaultValues as AssetFormDefaultValues,
  FormTypes,
} from '../../common/asset-form';

import * as S from './index.styled';

import {
  UpdateUpload,
  UpdateUploadVariables,
} from '../../../generated/UpdateUpload';
import {
  CreateLockableUpload,
  CreateLockableUploadVariables,
} from '../../../generated/CreateLockableUpload';

const UpdateUploadMutation = loader('../../../graphql/UpdateUpload.gql');
const CreateLockableUploadMutation = loader(
  '../../../graphql/CreateLockableUpload.gql',
);

const LockableAssetCompose: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const history = useHistory();
  const location = useLocation<{ defaultValues?: AssetFormDefaultValues }>();
  const feedId = useFeedId();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = location.state?.defaultValues;

  const [updateUpload] = useMutation<UpdateUpload, UpdateUploadVariables>(
    UpdateUploadMutation,
    {
      onError: (error: ApolloError) => {
        throw error;
      },
    },
  );

  const [createLockableUpload] = useMutation<
    CreateLockableUpload,
    CreateLockableUploadVariables
  >(CreateLockableUploadMutation, {
    onError: (error: ApolloError) => {
      throw error;
    },
  });

  const handleSubmit = () => async (fv: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      const [{ data: uploadData }, thumbnail] = await Promise.all([
        updateUpload({
          variables: { ...fv, id: fv.upload?.id },
        }),
        fv?.thumbnail
          ? updateUpload({
              variables: {
                id: fv.thumbnail?.id,
                title: '',
                description: '',
                feedId,
              },
            })
          : null,
      ]);

      if (uploadData && fv.type) {
        const { data: lockableUploadData } = await createLockableUpload({
          variables: {
            uploadId: uploadData?.updateUpload?.upload?.id,
            thumbnailId: thumbnail?.data?.updateUpload?.upload?.id,
            screenType: fv.type,
          },
        });
        history.push(
          `/${feedId}/assets/${lockableUploadData?.createLockableUpload?.lockableUpload?.upload?.id}`,
        );
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    setIsSubmitting(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <PageMeta title="Add Locked Asset" />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}/assets/locked`, title: 'Back to locked' }}
      />
      <AssetForm
        feedId={feedId}
        heading="Add Locked Asset"
        defaultValues={defaultValues}
        formType={FormTypes.Lockable}
      >
        {/* @ts-ignore */}
        {({ makeSubmitHandler, form }) => {
          return (
            <React.Fragment>
              {form}

              <S.Buttons
                css={css`
                  margin: 16px 32px;
                `}
              >
                <Button
                  primary
                  type="button"
                  disabled={isSubmitting}
                  onClick={makeSubmitHandler(handleSubmit())}
                >
                  Add
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    if (
                      window.confirm(
                        `${DISCARD_CHANGES_MESSAGE} This asset will not be saved.`,
                      )
                    ) {
                      history.push(`/${feedId}/assets/locked`);
                    }
                  }}
                >
                  Cancel
                </Button>
              </S.Buttons>
            </React.Fragment>
          );
        }}
      </AssetForm>
    </div>
  );
};

export default LockableAssetCompose;
