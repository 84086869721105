/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import ms from 'ms.macro';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { STATION_SCREEN_OPTIONS } from 'constants/screens';

import {
  CampaignStatus,
  CampaignType,
  FeatureFlagName,
  SearchCampaignsOrderBy,
  TargetType,
} from 'generated/global-types';
import ScreenTypeFilter from '../screen-filter-selector';
import AppLink, { ApplinkStyles } from '../app-link';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as TRoute } from '../../../generated/RoutesByFeed';
import { RouteMention } from '../../../types';
import { routeToRouteMention } from '../../../utils/route-mentions';
import PageHeading from '../../scaffolding/PageHeading';
import CampaignTable from '../../pages/campaign-dashboard/campaign-table';
import { useFeedId, FeedId } from '../../../contexts/FeedId';
import { useRoutes } from '../../../contexts/Routes';
import * as S from './index.styled';
import Select from '../form-elements/Select';
import RouteSelector from '../form-elements/route-selector';
import BusRouteSelector from '../form-elements/route-selector/bus-route-selector';
import PageMeta from '../PageMeta';
import { ButtonList } from '../../scaffolding/PageHeading.styled';
import Button from '../Button';
import Toggle from '../form-elements/Toggle';

enum QueryParamNames {
  orderBy = 'order',
  page = 'page',
  search = 'search',
  status = 'status',
  targets = 'targets',
  routes = 'routes',
  type = 'type',
}

const STATUS_OPTIONS = [
  { label: 'Draft', value: CampaignStatus.DRAFT },
  { label: 'Deploying', value: CampaignStatus.DEPLOYING },
  { label: 'Live', value: CampaignStatus.LIVE },
  { label: 'Scheduled', value: CampaignStatus.SCHEDULED },
  { label: 'Cleared', value: CampaignStatus.CLEARED },
  { label: 'Limited', value: CampaignStatus.LIMITED },
  { label: 'Error', value: CampaignStatus.ERROR },
  { label: 'Clearing', value: CampaignStatus.CLEARING },
];

const getDefaultFromQueryParams = (
  name: string,
  defaultValue: string,
): string => {
  const queryParams = new URLSearchParams(window.location.search);
  const value = queryParams.get(name);
  return value ? decodeURIComponent(value) : defaultValue;
};

const MESSAGES_PER_PAGE = 20;

const CampaignPage: React.FC<{
  pageTitle: string;
  filteredStatus?: CampaignStatus;
  excludedStatus?: CampaignStatus;
  children?: React.ReactNode;
}> = ({ pageTitle, filteredStatus, excludedStatus = null }) => {
  const { replace: historyReplace } = useHistory();
  const feedId = useFeedId();
  const allRoutes = useRoutes();

  const singleScreenCampaignsEnabled = useFeatureFlag(
    FeatureFlagName.ENABLE_SINGLE_SCREEN_CAMPAIGNS,
  );

  const [orderBy, setOrderBy] = useState<SearchCampaignsOrderBy>(
    SearchCampaignsOrderBy.CREATED_AT_DESC,
  );
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>(
    getDefaultFromQueryParams(QueryParamNames.search, ''),
  );
  const [debouncedSearch] = useDebounce(search, ms('1 second'));
  const [status, setStatus] = useState<CampaignStatus | null>(
    filteredStatus ||
      ((getDefaultFromQueryParams(QueryParamNames.status, '') ||
        null) as CampaignStatus | null),
  );
  const [type, setType] = useState<CampaignType | undefined>(
    singleScreenCampaignsEnabled
      ? (getDefaultFromQueryParams(
          QueryParamNames.type,
          CampaignType.REGULAR,
        ) as CampaignType)
      : undefined,
  );

  const [targets, setTargets] = useState<string[]>(
    getDefaultFromQueryParams(QueryParamNames.targets, '')
      .split(',')
      .filter(Boolean),
  );
  const [routes, setRoutes] = useState<string[]>(
    getDefaultFromQueryParams(QueryParamNames.routes, '')
      .split(',')
      .filter(Boolean),
  );

  useEffect(() => {
    const pid = window.setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const setValue = (name: string, value: string | null | undefined) => {
        if (value) {
          queryParams.set(name, encodeURIComponent(value));
        } else {
          queryParams.delete(name);
        }
      };
      setValue(QueryParamNames.orderBy, orderBy.toString());
      setValue(QueryParamNames.page, page.toString());
      setValue(QueryParamNames.search, search);
      setValue(QueryParamNames.status, status?.toString());
      setValue(QueryParamNames.targets, targets.join(','));
      setValue(QueryParamNames.routes, routes.join(','));
      if (singleScreenCampaignsEnabled && type) {
        setValue(QueryParamNames.type, type.toString());
      }
      historyReplace({
        search: queryParams.toString(),
      });
    }, ms('0.5 seconds'));
    return () => window.clearTimeout(pid);
  }, [
    historyReplace,
    orderBy,
    page,
    search,
    status,
    targets,
    routes,
    type,
    singleScreenCampaignsEnabled,
  ]);

  const routeMentions: RouteMention[] = useMemo(
    () =>
      (
        routes
          .map((gtfsId: string) => allRoutes.find((r) => r.gtfsId === gtfsId))
          .filter(Boolean) as TRoute[]
      ).map((r) => routeToRouteMention({ route: r })),
    [routes, allRoutes],
  );

  const handleNextClick = useCallback(() => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  }, [page, setPage]);
  const handlePrevClick = useCallback(() => {
    setPage(page - 1);
    window.scrollTo(0, 0);
  }, [page, setPage]);

  const filtersApplied = !!(
    status ||
    targets?.length !== 0 ||
    routes?.length !== 0 ||
    (singleScreenCampaignsEnabled && type !== CampaignType.REGULAR)
  );

  // Clear filters.
  const clearFilters = () => {
    setStatus(filteredStatus || null);
    setTargets([]);
    setRoutes([]);
    if (singleScreenCampaignsEnabled) {
      setType(CampaignType.REGULAR);
    }
  };

  // Exclude statuses if needed.
  const statusOptions = STATUS_OPTIONS.filter((status) => {
    return status?.value !== excludedStatus;
  });

  const statuses = status
    ? [status]
    : statusOptions.map((status) => {
        return status?.value;
      });

  const routeSelectorProps = {
    css: css`
      flex: 1;
      margin-right: 12px;
    `,
    isMulti: true,
    routes: routeMentions,
    onChange: (routes: RouteMention[]) => {
      setRoutes(routes.map((r) => r.routeId));
    },
    placeholder: 'Line(s)',
  };

  const showSingleScreenCampaigns = type === CampaignType.SINGLE_SCREEN;

  const rollingStockEnabled = useFeatureFlag(
    FeatureFlagName.CAMPAIGN_ROLLING_STOCK,
  );
  const sidewalkScreenEnabled = useFeatureFlag(
    FeatureFlagName.SIDEWALK_SCREEN_TARGETING,
  );

  return (
    <S.PageContainer>
      <PageMeta title={pageTitle} />
      <PageHeading breadcrumbs={['Messages']} title={pageTitle}>
        <ButtonList>
          <AppLink
            to={`/${feedId}/campaigns/compose`}
            styleVariations={[
              ApplinkStyles.PRIMARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
          >
            Compose
          </AppLink>
        </ButtonList>
      </PageHeading>
      <S.BodyContainer>
        <React.Fragment>
          <S.BodyRow>
            <S.BodyColumnFill>
              <S.SearchInput
                type="text"
                placeholder="Search titles and stations"
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                onClear={() => setSearch('')}
              />
            </S.BodyColumnFill>
          </S.BodyRow>
          <S.BodyRow>
            <div
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
                margin: 0px;
              `}
            >
              {filteredStatus !== CampaignStatus.CLEARED && (
                <Select
                  css={css`
                    margin-right: 12px;
                    flex: 1;
                    background-color: white;
                  `}
                  placeholder="State"
                  options={statusOptions}
                  onChange={(update: any) => {
                    if (update) {
                      const { value }: { value: CampaignStatus } = update;
                      setStatus(value);
                    } else {
                      setStatus(null);
                    }
                    setPage(1);
                  }}
                  value={
                    statusOptions.find((option) => option.value === status) ||
                    ''
                  }
                />
              )}
              {rollingStockEnabled || sidewalkScreenEnabled ? (
                <ScreenTypeFilter
                  onChange={(targetTypes) => {
                    setTargets(targetTypes);
                    setPage(1);
                  }}
                  value={targets as TargetType[]}
                />
              ) : (
                <Select
                  css={css`
                    flex: 1;
                    margin-right: 12px;
                    background-color: white;
                  `}
                  placeholder="Screen Type"
                  options={STATION_SCREEN_OPTIONS}
                  onChange={(update: any) => {
                    const { value }: { value: string } = update;
                    setTargets([value]);
                    setPage(1);
                  }}
                  value={
                    STATION_SCREEN_OPTIONS.find(
                      (option) => option.value === targets[0],
                    ) || ''
                  }
                />
              )}

              {feedId === FeedId.NYCTBus ? (
                <BusRouteSelector {...routeSelectorProps} />
              ) : (
                <RouteSelector {...routeSelectorProps} />
              )}
              <S.SelectContainer css={S.clearButtonContainer}>
                <Button
                  size="small"
                  aria-label="Clear Filters"
                  css={S.clearButton}
                  type="button"
                  disabled={!filtersApplied}
                  onClick={clearFilters}
                >
                  Clear
                </Button>
              </S.SelectContainer>
            </div>
          </S.BodyRow>
          {singleScreenCampaignsEnabled && (
            <S.BodyRow
              css={css`
                margin-top: -12px;
                margin-bottom: -8px;
                justify-content: flex-start;
                position: relative;
              `}
            >
              <Toggle
                checked={showSingleScreenCampaigns}
                onChange={() => {
                  setType(
                    showSingleScreenCampaigns
                      ? CampaignType.REGULAR
                      : CampaignType.SINGLE_SCREEN,
                  );
                }}
                css={css`
                  :is(input) {
                    display: flex;
                    justify-content: center;
                    margin-right: 8px;
                    width: 47px;
                    height: 23px;
                  }
                  :is(div) {
                    width: 17px;
                    height: 17px;
                    left: 3px;
                    top: 13px;
                    transform: ${showSingleScreenCampaigns
                      ? 'translate(24px)'
                      : ''};
                    z-index: 1;
                  }
                  svg {
                    width: 10px;
                    height: 10px;
                  }
                `}
              />
              <p
                css={css`
                  font-weight: 800;
                  font-size: 14px;
                  line-height: 16px;
                `}
              >
                SINGLE SCREEN CAMPAIGNS
              </p>
            </S.BodyRow>
          )}
        </React.Fragment>
        <S.BodyRow>
          <CampaignTable
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            onSortClick={setOrderBy}
            page={page}
            perPage={MESSAGES_PER_PAGE}
            search={debouncedSearch}
            status={statuses as CampaignStatus[]}
            orderBy={orderBy}
            routes={routes}
            filtersApplied={filtersApplied}
            targetTypes={targets}
            type={type}
          />
        </S.BodyRow>
      </S.BodyContainer>
    </S.PageContainer>
  );
};

export default CampaignPage;
