/** @jsxImportSource jsx */
import { css } from '@emotion/react';
import { StylesConfig } from 'react-select';
import theme from 'theme';

export const mentionCss = css`
  .remirror-floating-popover {
    z-index: 10;
    min-width: 220px;
    max-width: 540px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 0.5);
    overflow: hidden;
  }
`;

export const baseWrapperCss = css`
  position: relative;
  display: block;
  width: 100%;
`;

export const editorWrapperCss = css`
  position: relative;
  white-space: pre-wrap;

  #dlg-link {
    position: absolute;
    z-index: 1;
    top: 0;
    border: none;
    padding: 0;
  }

  & * {
    box-sizing: border-box;
  }

  .remirror-editor {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 12px 20px;
    border-radius: 4px;
    background: white;
    border: 1px solid rgb(170, 170, 170);
    box-shadow: rgb(170, 170, 170) 0 0 4px inset;

    &:focus-within {
      border: 1px solid rgb(0, 24, 64);
    }

    h2 {
      margin-block-end: 0;
      margin-block-start: 0.415em;
    }
    h3 {
      margin-block-end: 0;
      margin-block-start: 0.5em;
    }
    ul {
      padding-inline-start: 20px;
    }
    a {
      color: ${theme.colors.accent1};
    }
  }

  .remirror-editor:focus {
    outline: none;
  }

  p {
    margin: 0px;
  }

  span[data-bullet-type='ROUTE'],
  span[data-bullet-type='TRIP'],
  span[data-bullet-type='STOP'] {
    /*
      Prevent Chrome combining spans on delete and other cursor issues.
      @see https://github.com/ProseMirror/prosemirror/issues/514
    */
    display: inline-block;
  }

  .rm-editor-container.optional-editor .ProseMirror.remirror-editor {
    min-height: 92px;
  }

  .rm-editor-container.email-editor .ProseMirror.remirror-editor {
    min-height: 150px;
  }

  .rm-editor-container.optional-editor {
    padding-top: 16px;
  }

  .rm-editor-container .ProseMirror.remirror-editor {
    min-height: 64px;
    margin-top: -3px;
    overflow-y: unset;
  }

  .alt-station-editor {
    &.rm-editor-container .ProseMirror.remirror-editor {
      margin-top: 0;
      min-height: 92px;
      border-radius: 0 0 4px 0;
    }

    .rm-rich-menu {
      display: none;
    }
  }
`;

export const richEditingMenuCss = css`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #eaeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px #aaa solid;
  border-bottom: none;
  padding-bottom: 3px;
`;

export const richEditingMenuButtonCss = css`
  height: 32px;
  width: 32px;
  border-radius: 5px;
  padding-top: 3px;
  margin-left: 2px;
  margin-right: 2px;
  :hover {
    background-color: #f8f8f8 !important;
  }

  outline: none;
  &:focus {
    box-shadow: 0 0 0 1px ${theme.colors.accent3};
  }
`;

export const separatorCss = css`
  background-color: #cccccc;
  margin-left: 10px;
  margin-right: 10px;
  height: 66%;
  width: 1px;
`;

export const customSelectStyles: StylesConfig = {
  container: (provided, props) => {
    const focusedStyles = props.selectProps.menuIsOpen
      ? {
          backgroundColor: 'white',
          border: `1px solid ${theme.colors['border-dark']}`,
          borderBottom: '0',
          borderLeft: '0',
          marginTop: '-2px',
          paddingTop: '1px',
          borderRadius: '6px 6px 0px 0px',
        }
      : {};
    return {
      ...provided,
      width: '140px',
      ...focusedStyles,
    };
  },
  valueContainer(base) {
    return {
      ...base,
      display: 'flex',
      padding: '2px 8px 2px 16px',
    };
  },
  singleValue: (provided) => ({
    ...provided,
    overflow: 'visible',
    fontWeight: 'normal',
  }),
  menu: (provided: {}) => {
    return {
      ...provided,
      overflow: 'hidden',
      width: '139px',
      paddingTop: '10px',
      marginTop: '1px',
      borderRadius: '0 0 6px 6px',
      boxShadow: 'none',
      border: `1px solid ${theme.colors.accent3}`,
      borderTop: 'unset',
    };
  },
};
