/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { formatISO } from 'date-fns';
import ms from 'ms.macro';

import {
  WEB_NO_MESSAGES_MESSAGE,
  WEB_NO_MESSAGES_HEADING,
  WEB_NO_MESSAGES_CTA_LABEL,
  WEB_NO_MESSAGES_MESSAGE_FILTERS,
  WEB_NO_MESSAGES_HEADING_FILTERS,
  WEB_NO_MESSAGES_CTA_LABEL_FILTERS,
} from 'constants/empty-states';
import ConfirmModal from 'components/common/confirm-modal';
import useLiveQuery from '../../../hooks/use-live-query';

import AppLink, { ApplinkStyles } from '../../common/app-link';
import DashboardMessageCard from './dashboard-message-card';
import NoMessages from '../../common/NoMessages';
import Button from '../../common/Button';
import Loader from '../../common/skeletons/PageWithCards';
import { PublishStatus, MessageType } from '../../../generated/global-types';
import {
  ClearMessage,
  ClearMessageVariables,
} from '../../../generated/ClearMessage';
import {
  UpdateMessageEndAt,
  UpdateMessageEndAtVariables,
} from '../../../generated/UpdateMessageEndAt';
import {
  RenewAlertsTimestamps,
  RenewAlertsTimestampsVariables,
} from '../../../generated/RenewAlertsTimestamps';
import {
  DashboardMessages,
  DashboardMessagesVariables,
  DashboardMessages_messages_MessagesConnection_nodes_Message as DashboardMessages_messages_nodes,
} from '../../../generated/DashboardMessages';

import * as S from './index.styled';
import { ThemeType } from '../../../theme';
import { useFeedId, FeedId } from '../../../contexts/FeedId';
import PageMeta from '../../common/PageMeta';
import PageHeading, { ButtonList } from '../../scaffolding/PageHeading';
import StatusPill, { TStatusPillTypes } from '../../common/status-pill';
import ClearAlertModal from '../../common/clear-alert-modal';
import { DashboardMessageType } from './DashboardMessageTypes';

import useQueryParams from '../../../hooks/useQueryParams';
import { useRoutes } from '../../../contexts/Routes';
import RouteSelector, {
  RouteSelectorProps,
} from '../../common/form-elements/route-selector';
import BusRouteSelector from '../../common/form-elements/route-selector/bus-route-selector';
import { RouteMention } from '../../../types';
import { routeToRouteMention } from '../../../utils/route-mentions';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as TRoute } from '../../../generated/RoutesByFeed';
import { getClearedMessageTypeDescriptionsByFeed } from '../../../utils/message-type-display';
import Select from '../../common/form-elements/Select';

const DashboardMessagesQuery = loader('../../../graphql/DashboardMessages.gql');
const ClearMessageMutation = loader('../../../graphql/ClearMessage.gql');
const UpdateMessageEndAtMutation = loader(
  '../../../graphql/UpdateMessageEndAt.gql',
);
const RenewAlertsTimestampsMutation = loader(
  '../../../graphql/RenewAlertsTimestamps.gql',
);

const MessageList: React.FC<
  {
    messages: DashboardMessageType[];
    onClear: (id: number) => void;
  } & { children?: React.ReactNode }
> = ({ messages, onClear }) => (
  <div
    css={(theme: ThemeType) => css`
      margin-bottom: ${theme.spacing.medium};
    `}
  >
    {messages.map((message) => (
      <DashboardMessageCard
        key={`message-${message.id}`}
        onClear={onClear}
        {...message}
      />
    ))}
  </div>
);

const UpdateStatus: React.FC<{ connected: boolean }> = ({ connected }) => (
  <S.StatusContainer>
    <StatusPill
      status={connected ? TStatusPillTypes.LIVE : TStatusPillTypes.CLEARED}
      textOverride={connected ? 'Updating Live' : 'Connection Lost'}
      size="small"
    />
  </S.StatusContainer>
);

const ActiveMessages: React.FC<
  unknown & { children?: React.ReactNode }
> = () => {
  const feedId = useFeedId();
  const [refreshTimestampsModalIsOpen, setRefreshTimestampsModalIsOpen] =
    useState(false);

  // Filters.
  const queryParams = useQueryParams();
  const allRoutes = useRoutes();
  const history = useHistory();

  const QUERY_PARAM_KEYS = {
    lines: 'lines',
    routes: 'lines',
    category: 'category',
    status: 'status',
    allRoutes: 'all_routes',
  };
  const CATEGORY_OPTIONS = getClearedMessageTypeDescriptionsByFeed(feedId);

  const STATUS_OPTIONS = [
    { label: 'Set to Expire', value: 'set_to_expire' },
    { label: 'No Expiration Set', value: 'no_expiration' },
  ];

  const updateQueryParam = (name: string, value: string) => {
    queryParams.set(name, encodeURIComponent(value));
    history.push(`/${feedId}?${queryParams.toString()}`);
  };

  const routes: string[] = decodeURIComponent(
    queryParams.get(QUERY_PARAM_KEYS.routes) || '',
  )
    .split(',')
    .filter(Boolean);
  const routeMentions: RouteMention[] = (
    routes
      .map((gtfsId: string) => allRoutes.find((r) => r.gtfsId === gtfsId))
      .filter(Boolean) as TRoute[]
  ).map((r) => routeToRouteMention({ route: r }));
  const category = queryParams.get(QUERY_PARAM_KEYS.category);
  const status = queryParams.get(QUERY_PARAM_KEYS.status) || '';

  const filtersApplied = routes.length || category || status;

  const noMessagesProps = {
    icon: false,
    message: WEB_NO_MESSAGES_MESSAGE,
    heading: WEB_NO_MESSAGES_HEADING,
    ctaLabel: WEB_NO_MESSAGES_CTA_LABEL,
  };

  if (filtersApplied) {
    noMessagesProps.icon = true;
    noMessagesProps.message = WEB_NO_MESSAGES_MESSAGE_FILTERS;
    noMessagesProps.heading = WEB_NO_MESSAGES_HEADING_FILTERS;
    noMessagesProps.ctaLabel = WEB_NO_MESSAGES_CTA_LABEL_FILTERS;
  }

  // Clear filters.
  const clearAlertsFilters = () => {
    updateQueryParam(QUERY_PARAM_KEYS.routes, '');
    updateQueryParam(QUERY_PARAM_KEYS.category, '');
    updateQueryParam(QUERY_PARAM_KEYS.status, '');
    updateQueryParam(QUERY_PARAM_KEYS.allRoutes, '');
  };

  const [clearModalOptions, setClearModalOptions] = useState<{
    isOpen: boolean;
    targetMessage?: DashboardMessageType;
  }>({
    isOpen: false,
  });

  const [clearMessage] = useMutation<ClearMessage, ClearMessageVariables>(
    ClearMessageMutation,
  );

  const [updateMessageEndAt] = useMutation<
    UpdateMessageEndAt,
    UpdateMessageEndAtVariables
  >(UpdateMessageEndAtMutation);

  const [renewAlertsTimestamps, { loading: renewTimestampsLoading }] =
    useMutation<RenewAlertsTimestamps, RenewAlertsTimestampsVariables>(
      RenewAlertsTimestampsMutation,
    );

  /*
    Instead of using isActive, we filter on startAt and endAt directly.
    Postgres will use seq scans for computed column filtering, but
    can use an index if we're comparing columns directly.

    We use a 30s "now" rather than the 10s one from useNow to avoid
    requerying that frequently.

    Note that this means the query is live—it will get updates about
    existing messages—but will not include messages created after
    `pollingNow`: that's why we're polling on a live query.
   */
  const [pollingNow, setPollingNow] = useState(formatISO(new Date()));
  useEffect(() => {
    const pid = window.setInterval(() => {
      setPollingNow(formatISO(new Date()));
    }, ms('30 seconds'));
    return () => window.clearInterval(pid);
  }, [setPollingNow]);

  const variables = {
    input: {
      feedId,
      pollingAt: pollingNow,
      isSetToExpire: status ? status === 'set_to_expire' : null,
      messageType: category ? (category as MessageType) : null,
      routes: routes.length > 0 ? routes : null,
      allRoutes: Boolean(queryParams.get('all_routes')),
    },
  };

  const [cachedMessages, setMessages] = useState<
    DashboardMessages_messages_nodes[] | null
  >(null);

  const {
    loading,
    error,
    data = {
      messages: {
        nodes: [],
      },
    },
  } = useLiveQuery<DashboardMessages, DashboardMessagesVariables>(
    DashboardMessagesQuery,
    {
      fetchPolicy: 'cache-first',
      variables,
    },
  );

  const liveQueryMessages = data?.messages?.nodes;

  useEffect(() => {
    if (liveQueryMessages) {
      setMessages(liveQueryMessages);
    }
  }, [setMessages, liveQueryMessages]);

  const messages = cachedMessages ?? [];

  const openClearModal = (id: number) => {
    const targetMessage = messages?.find((message) => message.id === id);
    if (targetMessage) {
      setClearModalOptions({
        targetMessage,
        isOpen: true,
      });
    }
  };

  const closeClearModal = () => {
    setClearModalOptions({
      targetMessage: undefined,
      isOpen: false,
    });
  };

  const onClearConfirm = () => {
    if (clearModalOptions.targetMessage?.id) {
      clearMessage({
        variables: {
          messageId: clearModalOptions.targetMessage.id,
        },
        optimisticResponse: {
          clearMessage: {
            __typename: 'ClearMessagePayload',
            message: {
              __typename: 'Message',
              id: clearModalOptions.targetMessage.id,
              isActive: false,
            },
          },
        },
      });
    }

    closeClearModal();
  };

  const onClearLater = (newEndAt: Date | null) => {
    if (clearModalOptions.targetMessage?.id) {
      updateMessageEndAt({
        variables: {
          id: clearModalOptions.targetMessage.id,
          endAt: newEndAt,
        },
      });
    }

    closeClearModal();
  };

  const routeSelectorProps: RouteSelectorProps = {
    css: S.select,
    isMulti: true,
    routes: routeMentions,
    placeholder: 'Line(s)',
    includeAllRoutes: !!queryParams.get('all_routes'),
    onRadioChange: () => {
      if (queryParams.get('all_routes')) {
        updateQueryParam('all_routes', '');
      } else {
        updateQueryParam('all_routes', 'true');
      }
    },

    onChange: (selRoutes: RouteMention[]) => {
      updateQueryParam(
        QUERY_PARAM_KEYS.routes,
        selRoutes.map((r) => r.routeId).toString(),
      );
    },
  };

  const updateTimeStamps = async () => {
    const messagesToUpdate = messages.map((m) => m.id);

    await renewAlertsTimestamps({
      variables: {
        ids: messagesToUpdate,
      },
    });

    setRefreshTimestampsModalIsOpen(false);
  };

  return (
    <S.Container>
      <PageMeta title="Active Alerts" />
      <PageHeading
        breadcrumbs={['Messages']}
        title="Alerts"
        titleAdjacent={<UpdateStatus connected={!error} />}
      >
        <ButtonList>
          <AppLink
            to={`/${feedId}/compose`}
            styleVariations={[
              ApplinkStyles.PRIMARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
          >
            Compose
          </AppLink>
        </ButtonList>
      </PageHeading>
      <S.Container>
        <S.AlertsFilters>
          <S.SelectContainer>
            {feedId === FeedId.NYCTBus ? (
              <BusRouteSelector {...routeSelectorProps} />
            ) : (
              <RouteSelector {...routeSelectorProps} />
            )}
          </S.SelectContainer>
          <S.SelectContainer css={S.leftSpace}>
            <Select
              css={S.select}
              options={CATEGORY_OPTIONS}
              value={
                CATEGORY_OPTIONS.find((option) => option.value === category) ||
                ''
              }
              placeholder="Alert Status"
              isSearchable={false}
              onChange={(update: any) => {
                if (update) {
                  const { value }: { value: TStatusPillTypes } = update;
                  updateQueryParam(QUERY_PARAM_KEYS.category, value);
                } else {
                  updateQueryParam(QUERY_PARAM_KEYS.category, '');
                }
              }}
            />
          </S.SelectContainer>
          <S.SelectContainer css={S.leftSpace}>
            <Select
              css={S.select}
              options={STATUS_OPTIONS}
              value={
                STATUS_OPTIONS.find((option) => option.value === status) || ''
              }
              placeholder="Expiration"
              isSearchable={false}
              onChange={(update: any) => {
                if (update) {
                  const { value }: { value: TStatusPillTypes } = update;
                  updateQueryParam(QUERY_PARAM_KEYS.status, value);
                } else {
                  updateQueryParam(QUERY_PARAM_KEYS.status, '');
                }
              }}
            />
          </S.SelectContainer>
          <S.SelectContainer css={S.clearButtonContainer}>
            <Button
              size="small"
              aria-label="Clear Filters"
              css={S.clearButton}
              type="button"
              disabled={!filtersApplied}
              onClick={clearAlertsFilters}
            >
              Clear
            </Button>
          </S.SelectContainer>
          <S.SelectContainer css={S.updateTimeStampContainer}>
            <S.ActiveAlertsMessage>
              {messages?.length ?? 0} active alerts
            </S.ActiveAlertsMessage>
            <Button
              plain
              type="button"
              onClick={() => setRefreshTimestampsModalIsOpen(true)}
              disabled={!messages?.length || renewTimestampsLoading}
            >
              Update {filtersApplied ? 'Filtered' : 'All'}
            </Button>
          </S.SelectContainer>
        </S.AlertsFilters>
      </S.Container>
      <S.MessageListContainer hasMessages={Boolean(messages?.length)}>
        <Loader loading={loading}>
          {messages?.length ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              <MessageList onClear={openClearModal} messages={messages} />
            </div>
          ) : (
            <NoMessages
              icon={noMessagesProps.icon}
              heading={noMessagesProps.heading}
              message={noMessagesProps.message}
              ctaLabel={noMessagesProps.ctaLabel}
              ctaLink={`/${feedId}/compose`}
            />
          )}
        </Loader>
        <ClearAlertModal
          key={`${clearModalOptions.targetMessage?.id}-${clearModalOptions?.targetMessage?.endAt}`}
          hasTargetedApps={
            clearModalOptions.targetMessage?.webStatus !==
            PublishStatus.INACTIVE
          }
          hasTargetedScreens={
            clearModalOptions.targetMessage?.screensStatus !==
            PublishStatus.INACTIVE
          }
          initialEndAt={
            clearModalOptions.targetMessage?.endAt &&
            new Date(clearModalOptions.targetMessage.endAt)
          }
          isOpen={clearModalOptions.isOpen}
          onDismiss={closeClearModal}
          onClear={onClearConfirm}
          onClearLater={onClearLater}
        />
      </S.MessageListContainer>
      <ConfirmModal
        isOpen={refreshTimestampsModalIsOpen}
        onDismiss={() => setRefreshTimestampsModalIsOpen(false)}
        title="Update Timestamps"
        dismissText="Cancel"
        onConfirm={() => updateTimeStamps()}
      >
        Are you sure you want to refresh the currently displayed alerts
        timestamp?
      </ConfirmModal>
    </S.Container>
  );
};

export default ActiveMessages;
