/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import Heading from '../Heading';
import Bullet, { BulletSize } from '../Bullet';
import TripMention from '../Trip';
import * as S from './impacts-display.styled';
import { ThemeType } from '../../../theme';
import getRouteEntities from '../../../utils/get-route-entities';
import { useFeedId } from '../../../contexts/FeedId';
import { useRoutes } from '../../../contexts/Routes';
import { isSubway as getIsSubway } from '../../../utils/feed-switches';
import {
  MessageType,
  GtfsEntitySelectorInput,
} from '../../../generated/global-types';
import AllStationsTag from '../all-stations-tag';

export interface IRawImpact {
  messageType: MessageType;
  entitySelectors: GtfsEntitySelectorInput[];
}

const MAX_BULLET_LENGTH = 6;
const MAX_TRIPS_LENGTH = 3;

const ImpactsDisplay: React.FC<{
  impacts?: IRawImpact[];
  trips?: string[];
  limit?: number;
  children?: React.ReactNode;
}> = ({ impacts, trips, limit }) => {
  const feedId = useFeedId();
  const isSubway = getIsSubway(feedId);
  const isMultipleImpacts = !!impacts && impacts.length > 1;
  const allRoutes = useRoutes();

  const tripEls = (size: BulletSize = BulletSize.xlarge) => {
    const tripsCount = trips?.length ?? 0;

    return (
      <div
        css={css`
          margin-top: 5px;
        `}
      >
        <div
          css={css`
            margin-top: 2px;
            display: flex;
            justify-content: center;
          `}
        >
          {trips &&
            trips.slice(0, MAX_TRIPS_LENGTH).map((tripName, idx) => {
              const tripMentionKey = `trip-${tripName}-${idx}`;
              return (
                <TripMention
                  key={tripMentionKey}
                  tripName={tripName}
                  size={size}
                />
              );
            })}
        </div>
        {tripsCount > MAX_TRIPS_LENGTH && (
          <S.AdditionalRoutesText>
            + {tripsCount - MAX_TRIPS_LENGTH} more
          </S.AdditionalRoutesText>
        )}
      </div>
    );
  };

  const impactEls =
    impacts &&
    impacts
      .slice(0, limit || impacts.length)
      .map(({ messageType, entitySelectors }, idx) => {
        const allRoutesSelected = allRoutes.every(({ gtfsId }) =>
          entitySelectors.some(({ routeId }) => gtfsId === routeId),
        );

        if (allRoutesSelected) {
          return (
            <div>
              <S.RouteDisplayBulletList>
                <AllStationsTag />
              </S.RouteDisplayBulletList>
              {tripEls()}
              <Heading
                level={3}
                size="large"
                css={css`
                  margin-top: 10px;
                  text-align: center;
                `}
              >
                {messageType && MESSAGE_TYPE_DESCRIPTIONS[messageType]}
              </Heading>
            </div>
          );
        }

        const numBullets = entitySelectors
          ? getRouteEntities(entitySelectors).length
          : 0;

        let bulletSize = isSubway ? BulletSize.xxlarge : BulletSize.small;

        if (isSubway && isMultipleImpacts) {
          bulletSize = BulletSize.xlarge;
        } else if (!isSubway && numBullets === 1 && !isMultipleImpacts) {
          bulletSize = BulletSize.xlarge;
        } else if (isSubway && numBullets > 4) {
          bulletSize = BulletSize.xlarge;
        }

        return (
          <div
            key={idx} // eslint-disable-line react/no-array-index-key
            css={css`
              margin-top: ${idx !== 0 ? '16px' : '0px'};
            `}
          >
            <S.RouteDisplayBulletList>
              {entitySelectors &&
                entitySelectors
                  .slice(0, MAX_BULLET_LENGTH)
                  .map((entitySelector) => {
                    const { routeId } = entitySelector;
                    if (!routeId) {
                      return null;
                    }

                    return (
                      <Bullet
                        key={routeId}
                        routeId={routeId}
                        size={bulletSize}
                        style={{ margin: '2px' }}
                      />
                    );
                  })}
            </S.RouteDisplayBulletList>
            {numBullets > MAX_BULLET_LENGTH && (
              <S.AdditionalRoutesText>
                + {numBullets - MAX_BULLET_LENGTH} more
              </S.AdditionalRoutesText>
            )}

            {tripEls(bulletSize)}

            <Heading
              level={3}
              size="large"
              css={(theme: ThemeType) => css`
                margin-top: ${isMultipleImpacts ? '0px' : theme.spacing.xsmall};
                text-align: center;
              `}
            >
              {messageType && MESSAGE_TYPE_DESCRIPTIONS[messageType]}
            </Heading>
          </div>
        );
      });

  const statusText = impacts && impacts.length > 1 ? 'statuses' : 'status';
  const plusImpactsText = impacts && limit && impacts.length > limit && (
    <S.AdditionalImpactsText>
      + {impacts.length - limit} more {statusText}
    </S.AdditionalImpactsText>
  );

  return (
    <S.ImpactsDisplayContainer isMultipleImpacts={isMultipleImpacts}>
      {impactEls}
      {plusImpactsText}
    </S.ImpactsDisplayContainer>
  );
};

export default ImpactsDisplay;
